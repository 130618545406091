<div class="menu" fxLayout="row" fxLayoutAlign="space-between center">
    <div class="category-menu-wrapper" fxLayout="row" fxLayoutGap="32px" fxLayoutAlign="start center">
        <a routerLink="/">
            <div class="category">home</div>
        </a>
        <a *ngFor="let category of data?.slice(0,6); let first= first" [routerLink]="['',gameTrx, category.name]">
            <div class="category" [ngClass]="{'first':  first}" [innerHTML]="category.displayName | removehtml"></div>
        </a>
    </div>
    <div class="search" fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="end center" [topDrawerRouterLink]="[searchGameTrx]">
        <div>
            <img src="assets/icons/icon-search.svg" alt="icon-search" />
        </div>
        <div i18n>search</div>
    </div>
</div>
