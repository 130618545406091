import { gql } from 'apollo-angular';

export const PROMOTION_QUERY = gql`query CardiWidgetPageQuery($pageName: String!,$userCountry: [Countries!], $locale: Locale!) {
    cardWidgetPages(where: {AND:{name_starts_with: $pageName ,  OR:[{country_contains_some: $userCountry}, {country_contains_some: default}]}}, locales: [$locale,en]) {
      name
      country
      seo {
          metaTitle
          metaDescription
          relCanonical
        }
      cardWidgetElements {
        name
        title
        isLargeCard
        isDepositButton
        thumbnail {
          url(transformation: {
            image: { resize: { width: 404, height: 176, fit: crop } }
            document: { output: { format: webp } }
          })
        }
        thumbnailMobile {
          url
        }
        shortDescription
        page {
          name
          category
        }
        labels
      }
      expansionPanel{
        item{
          title
          content{
            html
            }
          }
      }
    }
  }
  `