import { Component, Inject, LOCALE_ID } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { take } from 'rxjs';
import { GAME_FIELDS_MOBILE } from 'src/app/apollo/front-page-games/front-page-games';
import { ExpansionPanelItem, GameCategories, GameCategory, InAppPageParams } from 'src/app/apollo/models/base-models';
import { LICENCE } from 'src/app/app-routing.module';
import { ClonerService } from 'src/app/services/utils/clone-object.service';
import { FilterGamesService } from 'src/app/services/utils/filter-games.service';
import { countryFromLocale } from 'src/app/utils/country-from-locale';
import { DetectDeviceService } from '../../services/utils/detect-device.service';
import { BasePageComponent } from '../base-page/base-page.component';
import { DomSanitizer } from '@angular/platform-browser';
import { ToggleMenuBackService } from 'src/app/services/utils/toggle-menu-back.service';

export const FRONT_OPERATORS_REGION = 'front_operators';
export const LIVE_CASINO_OPERATOR_REGION = 'live_casino_operators';

export const LIVE_CASINO_PAGE_GAMES_QUERY = gql`query LiveCasinoPageGames($locale: Locale!,$licenseName: Licence!, $countryName: [Countries!], $operatorsRegion: FrontPageRegion){
  pagesCategories(where: {AND: {regionName: live_casino, country_contains_some:$countryName }},locales: [$locale, en]) {
    seo{
      metaTitle
      metaDescription
    }
    categories {
      name,
      id,
      displayName,
      numberGamesFrontPageMobile,
      icon{
        url
      },
      thumbnail{
        url
      },
      game(where: {  OR: [{ licence: $licenseName }, { licence: all }] }, first: 50) {
        ${GAME_FIELDS_MOBILE}
      }
    }
  }
  inAppPagesParams(where: {AND: {siteName:"live-casino", OR: [{licence_contains_some: [all,$licenseName]}] }}){
    seo(locales: [$locale, en]) {
          metaTitle
          metaDescription
          relCanonical
    }
    expansionPanel(locales: [$locale, en]){
        item{
          title
          content{
            html
            }
          }
    }
  }
  operators: pagesCategories(locales: [$locale, en],where: {AND: {regionName:$operatorsRegion, country_contains_some:$countryName }}) {
    categories{
      name,
      displayName,
      gameType,
      thumbnailDesktop{
        url(transformation: {
          image: { resize: { width: 235, height: 153, fit: clip } }
          document: { output: { format: webp } }
        })
      },
      thumbnailMobile{
        url(transformation: {
          image: { resize: { width: 300, height: 414, fit: clip } }
            document: { output: { format: webp } }
        })
      }
    }
  }
  }
  `;

@Component({
  selector: 'app-live-casino-category-page',
  templateUrl: './live-casino-category-page.component.html',
  styleUrls: ['./live-casino-category-page.component.scss']
})
export class LiveCasinoCategoryPageComponent extends BasePageComponent {

  constructor(
    private apollo: Apollo,
    private cloneService: ClonerService,
    private filterGamesService: FilterGamesService,
    public detectDeviceService: DetectDeviceService,
    private sanitizer: DomSanitizer,
    protected toggleBackService: ToggleMenuBackService,
    @Inject(LOCALE_ID) public locale: string,
    @Inject(LICENCE) public licence: string,
  ) {
    super()
  }

  operators: GameCategory[];
  categories: GameCategory[];
  expansionPanel: ExpansionPanelItem[];


  ngOnInit(): void {
    const query = LIVE_CASINO_PAGE_GAMES_QUERY;
    this.toggleBackService.showBack();
    this.apollo
      .watchQuery<{
        pagesCategories: GameCategories[],
        inAppPagesParams: InAppPageParams[],
        operators?: GameCategories[] & {
          thumbnailDesktop?: {
            url: string
          },
          thumbnailMobile?: {
            url: string
          }
        }

      }>({
        query,
        variables: {
          licenseName: this.licence,
          locale: this.locale,
          countryName: [countryFromLocale(this.locale)],
          operatorsRegion: LIVE_CASINO_OPERATOR_REGION
        }
      })
      .valueChanges.pipe(
        take(1),
      ).subscribe((response) => {
        const categories = this.cloneService.deepClone<GameCategory[]>(response.data.pagesCategories[0].categories);
        this.operators = response.data.operators[0]?.categories;
        this.categories = this.filterGamesService.filterCategories(categories);
        const inAppparams = response.data?.inAppPagesParams?.filter(item => !!item.licence?.find(licence => licence === this.licence))[0] || response.data?.inAppPagesParams[0]

        this.setSEOTags(inAppparams?.seo?.metaTitle, inAppparams?.seo?.metaDescription, inAppparams?.seo?.relCanonical)
        const expansionItems: ExpansionPanelItem[] = this.cloneService.deepClone(inAppparams?.expansionPanel?.item);

        this.expansionPanel = expansionItems?.map(item => {
          if (!!item && !!item.content) {
            item.content.safehtml = this.sanitizer.bypassSecurityTrustHtml(item.content.html);

            return item;
          }
        });
      });
  }
}
