import { Inject, InjectionToken, NgModule, Optional, PLATFORM_ID } from '@angular/core';
import { Router, RouterModule, Routes } from '@angular/router';
import { FrontPageComponent } from './components/front-page/front-page.component';
import { StaticPageComponent } from './components/static-page/static-page.component';
import { LoginStandaloneComponent } from './components/auth/login-dialog/login-dialog.component';
import {
  changePasswordTrx,
  clientAreaTrx,
  editProfileTrx,
  gameHistoryTrx,
  gameInfoTrx,
  gamesCategoryTrx,
  gameTrx,
  inboxTrx,
  lastPlayedFullTrx,
  myGamesTrx,
  launchGameTrx,
  liveCasinoTrx,
  loginTrx,
  lostPasswordTrx,
  paymentHistoryTrx,
  paymentTrx,
  pendingWithdrawalTrx,
  promotionsTrx,
  providerTrx,
  responsibleGaming,
  resumeGameTrx,
  searchGameTrx,
  signUpTrx,
  streamerTournamentTrx,
  tournamentsTrx,
  missionsTrx,
  uploadDocumentsTrx,
  christmasCalendarTrx,
  pickBetTournamentTrx,
  reactivationTrx
} from './router-translation.labels';
import { AuthGuard } from './auth.guard';
import { PendingWithdrawalListComponent } from './components/payment/pending-withdrawal-list/pending-withdrawal-list.component';
import { PaymentHistoryComponent } from './components/payment/payment-history/payment-history.component';
import { LastPlayedFullComponent } from './components/games/last-played-full/last-played-full.component';
import { GameDialogRouteComponent } from './components/dialog/game-dialog/game-dialog.component';
import { TranslationConfig } from './utils/translate-config';
import { OpenDesktopDrawerService } from './services/utils/open-desktop-drawer.service';
import { MinimizePageResolver } from './resolvers/minimize-page.resolver';
import { MaximumPageResolver } from './resolvers/restore-max-page.resolver';
import { DeviceDetectorService } from 'ngx-device-detector';
import { isPlatformServer, KeyValue } from '@angular/common';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { ResponsibleGamingComponent } from './components/profile/responsible-gaming/responsible-gaming.component';
import { LostPasswordDialogRouteComponent } from './components/auth/lost-password-dialog/lost-password-dialog.component';
import { GameCategoryDialogComponent } from './components/front-page/game-category-dialog/game-category-dialog.component';
import { GameOperatorCategoryComponent } from './components/front-page/operators-category/operator.component';
import { AppModule } from './app.module';
import { DEVICE_TYPE_MODEL } from './apollo/models/base-models';
import { routerMapping } from './router-mapping';
import { MobileNotFoundComponent } from './components/mobile-not-found/mobile-not-found.component';
import { ResumeGameDialogRouteComponent } from './components/dialog/resume-game-dialog/resume-game-dialog.component';
import { MyGamesComponent } from "./components/games/my-games/my-games.component";

export const loginRoute = {
  path: loginTrx,
  component: LoginStandaloneComponent
};

export const signupRoute = {
  path: signUpTrx,
  loadChildren: () => import('./modules/signup/signup-standalone.module').then(m => m.SignupStandaloneModule)
};

const lostPasswordRoute = {
  path: lostPasswordTrx, component: LostPasswordDialogRouteComponent
};

const tournamentsRoute = {
  path: tournamentsTrx,
  canActivate: [],
  loadChildren: () => import('./components/tournaments/tournaments.module').then(m => m.TournamentsModule)
};

const streamerRoute = {
  path: streamerTournamentTrx,
  canActivate: [],
  loadChildren: () => import('./components/streamer-tournament/streamer-tournament.module').then((m) => { return m.StreamerTournamentModule })
};

const pickBetRoute = {
  path: pickBetTournamentTrx,
  canActivate: [],
  loadChildren: () => import('./components/pick-bet-tournament/pick-bet-tournament.module').then((m) => { return m.PickBetTournamentModule })
};

const missionsRoute = {
  path: missionsTrx,
  canActivate: [],
  loadChildren: () => import('./components/missions/missions.module').then(m => m.MissionsModule)
};
const reactivationRoute = {
  path: reactivationTrx,
  canActivate: [AuthGuard],
  loadChildren: () => import('./components/reactivation/reactivation.module').then(m => m.ReactivationModule)
};
const lauchGameRoute = {
  path: launchGameTrx,
  canActivate: [],
  resolve: { minimize: MinimizePageResolver },
  loadChildren: () => import('./components/games/game-launcher/game-launcher.module').then(m => m.GameLauncherModule)
};
export const clientAreaRoute = {
  path: clientAreaTrx,
  canActivate: [],  /* AuthGuard */
  loadChildren: () => import('./components/client-area/client-area.module').then(m => m.ClientAreaModule),
  runGuardsAndResolvers: 'always'
};

const mobilePaymentsRoutes = {
  path: paymentTrx,
  canLoad: [AuthGuard],
  loadChildren: () => import('./modules/payments/payments.module').then(m => {
    const translateConfig = AppModule.mInjector.get(TranslationConfig);
    import('./modules/payments/payments-routes').then(r =>
      r.PAYMENT_MODULE_ROUTES_NOT_TRANSLATED
        .forEach(route => {
          route.path = translateConfig.translatePathElement(route);
          (mobileRoutes as Array<any>).push(route);
        }));
    return m.PaymentsModule;
  })
};

const mobileSlotsRoutes = {
  path: `${gameTrx}/:category`,
  loadChildren: () => import('./modules/mobile-categories/mobile-categories.module').then(m => {
    const translateConfig = AppModule.mInjector.get(TranslationConfig);
    import('./modules/mobile-categories/mobile-categories-routes').then(r =>
      r.MOBILE_CATEGORIES_MODULE_NOT_TRANSLATED.forEach(route => {
        route.path = translateConfig.translatePathElement(route);
        (mobileRoutes as Array<any>).push(route);
      })
    );
    return m.MobileCategoriesModule;
  })
};

const mobileProviderRoutes = {
  path: `${providerTrx}/:category`,
  loadChildren: () => import('./modules/mobile-categories/mobile-categories.module').then(m => {
    const translateConfig = AppModule.mInjector.get(TranslationConfig);
    import('./modules/mobile-categories/mobile-categories-routes').then(r =>
      r.MOBILE_CATEGORIES_MODULE_NOT_TRANSLATED
        .forEach(route => {
          route.path = translateConfig.translatePathElement(route);
          (mobileRoutes as Array<any>).push(route);
        })
    );
    return m.MobileCategoriesModule;
  })
};

const mobileCategoriesMappingRoutes = Array.from(routerMapping).map(([trx, cat]) => {
  return {
    path: trx, loadChildren: () => import('./modules/mobile-categories/mobile-categories.module')
      .then(m => m.MobileCategoriesModule), data: { category: cat }
  };
});

const mobileLiveCasinoRoutes = [
  {
    path: liveCasinoTrx,
    loadChildren: () => import('./modules/mobile-live-casino/mobile-live-casino.module').then(m => {
      const translateConfig = AppModule.mInjector.get(TranslationConfig);
      import('./modules/mobile-live-casino/mobile-live-casino-routes').then(r =>
        r.MOBILE_LIVE_CASINO_MODULE_NOT_TRANSLATED
          .forEach(route => {
            route.path = translateConfig.translatePathElement(route);
            (mobileRoutes as Array<any>).push(route);
          }));
      return m.MobileLiveCasinoModule;
    })
  }

];

const mobileRoutes: Routes = [
  {
    path: '', component: FrontPageComponent, resolve: { maximize: MaximumPageResolver }, children: [
      lostPasswordRoute,


    ]
  },
  signupRoute,
  loginRoute,
  {
    path: gameInfoTrx + '/:game', component: GameDialogRouteComponent, children: []
  },
  {
    path: resumeGameTrx + '/:game', component: ResumeGameDialogRouteComponent, children: []
  },
  // desktopRoute,
  // {
  //   path: christmasCalendarTrx,
  //   loadChildren: () => import('./components/calendar/calendar.module').then(m => m.CalendarModule),
  // },
  lauchGameRoute,
  {
    path: tournamentsTrx, loadChildren: () => import('./components/tournaments/tournaments.module').then(m => m.TournamentsModule)
  },
  {
    path: missionsTrx, loadChildren: () => import('./components/missions/missions.module').then(m => m.MissionsModule)
  },
  tournamentsRoute,
  streamerRoute,
  missionsRoute,
  reactivationRoute,
  mobilePaymentsRoutes,
  mobileSlotsRoutes,
  mobileProviderRoutes,
  pickBetRoute,
  ...mobileLiveCasinoRoutes,
  ...mobileCategoriesMappingRoutes,
  {
    path: changePasswordTrx,
    loadChildren: () => import('./modules/change-password/change-password.module').then(m => m.ChangePasswordModule),
  },
  {
    path: pendingWithdrawalTrx, component: PendingWithdrawalListComponent, children: []
  },
  {
    path: searchGameTrx, loadChildren: () => import('./modules/search/search.module').then(m => m.SearchModule),
  },
  {
    path: gameHistoryTrx,
    loadChildren: () => import('./modules/game-history/game-history.module').then(m => m.GameHistoryModule),
  },
  {
    path: inboxTrx,
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/user-messages/user-messages.module').then(m => m.UserMessagesModule),
  },
  {
    path: myGamesTrx, component: MyGamesComponent, children: []
  },

  {
    path: paymentHistoryTrx, canActivate: [AuthGuard], component: PaymentHistoryComponent, children: []
  },
  {
    path: paymentTrx,
    canLoad: [AuthGuard],
    loadChildren: () => import('./modules/payments/payments.module').then(m => m.PaymentsModule),
  },
  {
    path: editProfileTrx, loadChildren: () => import('./modules/edit-profile/edit-profile.module').then((m) => {
      console.log('edit profile module', m);
      return m.EditProfileModule;
    }),
  },
  {
    path: responsibleGaming, component: ResponsibleGamingComponent, children: []
  },
  {
    path: uploadDocumentsTrx, canActivate: [AuthGuard], loadChildren: () => import('./components/profile/upload-documents/upload-documents.module').then(m => m.UploadDocumentsModule),
  },
  clientAreaRoute,
  { path: providerTrx, component: GameOperatorCategoryComponent, data: { isAll: true } },
  {
    path: promotionsTrx,
    loadChildren: () => import('./modules/mobile-promotions/mobile-promotions.module').then(m => m.MobilePromotionsModule)
  },
  { path: gamesCategoryTrx, component: GameCategoryDialogComponent },
  {
    path: ':slug/:name', component: StaticPageComponent, children: []
  },
  { path: '**', pathMatch: 'full', component: MobileNotFoundComponent },
];

const routes: Routes = [
  ...mobileRoutes,
];


interface RouteTranslation {
  [key: string]: string;
}

interface AllRouteTranslation {
  [key: string]: RouteTranslation;
}

export const TRANSLATED_ROUTES = new InjectionToken<RouteTranslation>('translatedRoutes');
export const ALL_TRANSLATED_ROUTES = new InjectionToken<AllRouteTranslation>('allTranslatedRoutes');
export const DESKTOP_ROUTE_TOKEN = new InjectionToken<Routes>(null);
export const HOST_NAME = new InjectionToken<string>('hostName');
export const licences = {
  'lemoncasino.io': 'gambia',
  'cache.lemoncasino.io': 'gambia',
  'lemoncasino1.io': 'gambia',
  'lemoncasino2.io': 'gambia',
  'lemoncasino4.io': 'gambia',
  'lemoncasino5.io': 'gambia',
  'lemoncasino6.io': 'gambia',
  'lemolemo.io': 'gambia',
  'cache.lemoncasino1.io': 'gambia',
  'cache.lemoncasino2.io': 'gambia',
  'cache.lemoncasino4.io': 'gambia',
  'orangecasino.io': 'orange_curacao',
  'india.lemoncasino1.io': 'india',
};

export const HIDDEN_DOMAIN_MAPPING = {
  "india.lemoncasino1.io": "lemon.casino"
}

export const LICENCE = new InjectionToken<string>('license');
export const LICENCE_CONFIG = new InjectionToken<KeyValue<string, KeyValue<string, string>>>('license-config');
export const COUNTRY_ID = new InjectionToken<string>('en');
export const USER_REGION = new InjectionToken<string>('');
export const DEVICE_TYPE = new InjectionToken<DEVICE_TYPE_MODEL>(DEVICE_TYPE_MODEL.MOBILE);

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledNonBlocking',
    scrollPositionRestoration: 'enabled',
    // relativeLinkResolution: 'legacy',
    // enableTracing: true
  }),],
  providers: [
    TranslationConfig,
    // { provide: APP_INITIALIZER, useFactory: (config: TranslationConfig) => () => config.load(mobileRoutes,desktopRoutes), deps: [TranslationConfig,DetectDeviceService], multi: true },
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {

  public constructor(private router: Router,
    private deviceService: DeviceDetectorService,
    public translationConfig: TranslationConfig,
    public openDrawerService: OpenDesktopDrawerService,
    @Inject(PLATFORM_ID) private platformId,
    @Inject(DEVICE_TYPE) private deviceType,
    @Inject(DESKTOP_ROUTE_TOKEN) private desktopRoutesNotTranslated,
    @Optional() @Inject(REQUEST) protected request: Request,
  ) {
    if (isPlatformServer(this.platformId)) {
      this.deviceService.setDeviceInfo(request.headers['user-agent']);
    }
    if (deviceType === DEVICE_TYPE_MODEL.DESKTOP) {
      const desktopRoutes: Routes = [];
      desktopRoutesNotTranslated.forEach(route => {
        route.path = translationConfig.translatePathElement(route);
        desktopRoutes.push(route);
      });
      this.router.resetConfig(desktopRoutesNotTranslated);
    } else if (deviceType === DEVICE_TYPE_MODEL.MOBILE) {
      this.translationConfig.load();
    }
  }
}
