import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removehtml'
})
export class RemoveHtmlPipe implements PipeTransform {

  transform(value: string): any {
    if (!value) return value;
    let resp = value.replace(/<[^>]*>/g, '');
    return resp;
  }

}
