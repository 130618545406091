<div class="search-swiper-wrapper">
    <ng-container *ngIf="!isServer else ssrBaner">
        <ng-container *ngIf="mobileConfig">
            <swiper class="search-swiper" [config]="mobileConfig">
                <ng-template swiperSlide *ngFor="let item of swiperCategories">
                    <ng-container *ngIf="item.icon && item.displayName">
                        <div class="swiper-slide-content" (click)="onFilterSelect(item)">  <!--  (click)="onFilterSelect(item)" -->
                            <img class="item-icon" src="{{item.icon?.url}}" alt="{{item.name}}" />
                            <p>{{item.displayName}}</p>
                        </div>
                    </ng-container>
                </ng-template>
            </swiper>
        </ng-container>
    </ng-container>
</div>

<ng-template #ssrBaner>
    <div class="search-static-wrapper search-swiper">
        <ng-container *ngFor="let item of swiperCategories?.slice(0,4)"> <!--  (click)="onFilterSelect(item)" -->
            <div class="swiper-slide-content" (click)="onFilterSelect(item)">
                <img class="item-icon" src="{{item.thumbnail?.url}}" alt="{{item.name}}" />
                <p>{{item.displayName}}</p>
            </div>
        </ng-container>
    </div>
</ng-template>
