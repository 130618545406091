import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';


export const DEFAULT_GAME_BACKGROUND:string = "/assets/background/default.jpg"

export interface BackgroundImageDescription  {
  urlMain?: string
  urlLeft?: string
  urlRight?: string
} 

@Injectable({
  providedIn: 'root'
})
export class SetMainBackgroundService {

  constructor() { }

  private _backgroundImage: BehaviorSubject<BackgroundImageDescription> = new BehaviorSubject<BackgroundImageDescription>({urlMain:null,urlLeft:null,urlRight:null});

  public setBackGroundImage(url1: string, url2:string=null,url3:string=null){
    if (url2 === null){
      this._backgroundImage.next({urlMain:url1,urlLeft:null,urlRight:null})
    }
    else{
      this._backgroundImage.next({urlMain:null,urlLeft:url2,urlRight:url3})
    }
  }

  public attach(){
    return this._backgroundImage.asObservable();
  }
}
