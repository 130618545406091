import { Component } from '@angular/core';
import { BaseComponent } from '../../base/base.component';
import { UserMessagesService } from '../../../services/user-messages/user-messages.service';
import { take, takeUntil } from 'rxjs/operators';
import { inboxTrx } from '../../../router-translation.labels';
import { TranslationConfig } from '../../../utils/translate-config';
import { Router } from '@angular/router';

@Component({
  selector: 'app-notifications-email',
  templateUrl: './notifications-email.component.html',
  styleUrls: ['./notifications-email.component.scss']
})
export class NotificationsEmailComponent extends BaseComponent {

  messageCount = 0;
  isClosed = false;

  inboxUrl: string = this.translationConfig.getTranslation(inboxTrx);

  constructor(
    protected translationConfig: TranslationConfig,
    public userMessagesService: UserMessagesService,
    private router: Router,
  ) {
    super();
  }

  override ngOnInit(): void {
    // this.userMessagesService.getUserMessages(true).pipe(take(1)).subscribe((resp) => {
    //   this.messageCount = resp.unread_count;
    // });
  }

  accessInbox() {
    this.router.navigate(['/' + this.inboxUrl]);
    this.isClosed = true;
  }

  closeNotification() {
    this.userMessagesService.closeNewMessageInfoBox();
    this.isClosed = true;
  }
}
