import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { BalanceInAppInfo } from './wallet.models';


@Injectable({
  providedIn: 'root'
})
export class BalanceStatusService  {

  private balanceInfo = new BehaviorSubject<BalanceInAppInfo>({});

  public getBalanceInfoStatus():Observable<BalanceInAppInfo>{
    return this.balanceInfo.asObservable();
  }

  constructor() {

   }

  public updateBalanceInfo(params:any){
      let current = this.balanceInfo.getValue();
      this.balanceInfo.next({...current,...params});
  }
}
