import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { UntypedFormGroup } from '@angular/forms';
import { environment } from '../../../environments/environment';
import { BaseHttpService } from './base-http.service';
import { CookieService } from 'ngx-cookie-service';
import { HOST_NAME } from 'src/app/app-routing.module';
import { CustomEncoder } from 'src/app/interceptors/encode-http.interceptor';

@Injectable({
    providedIn: 'root'
})
export class BaseWalletService extends BaseHttpService {

    constructor(http: HttpClient, protected cookie: CookieService, @Inject(LOCALE_ID) public locale: string, @Inject(HOST_NAME) public hostName) {
        super(http, cookie, locale)
        environment.production ? super.setApiUrl(environment.walletUrl + hostName) : super.setApiUrl(environment.walletUrl);
    }

    getText<T>(url: string) {
        const httpOptionsDefault = {
            withCredentials: true,
            responseType: 'text' as 'json'
        };
        return this.http.get<T>(this.apiUrl + url, httpOptionsDefault)
    }

    get<T>(url: string) {
        const httpOptionsDefault = {
            headers: this.createHeaders(),
            withCredentials: true,
            params: new HttpParams().set('t', new Date().getTime().toString())
        };
        return this.http.get<T>(this.apiUrl + url, { ...httpOptionsDefault })

    }

    protected postRequest<T>(url: string, formGroup: UntypedFormGroup) {
        const httpOptionsDefault = {
            headers: this.createHeaders(),
            withCredentials: true
        };

        const params = new HttpParams({
            encoder: new CustomEncoder(),
            fromObject: formGroup.value
        });


        return this.http.post<T>(this.apiUrl + url, params, { ...httpOptionsDefault });
    }

    protected postRequestJson<T>(url: string, json: any) {
      const httpOptionsDefault = {
          headers: this.createHeaders(),
          withCredentials: true
      };
      const params = new HttpParams({
          encoder: new CustomEncoder(),
          fromObject: json
      });

      return this.http.post<T>(this.apiUrl + url, params, { ...httpOptionsDefault });
  }

}
