import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class OpenDesktopDrawerService {

  private _isOpen = new BehaviorSubject<boolean>(false);
  private _isOpenInitial = new BehaviorSubject<boolean>(false);

  constructor() { }

  public open() {
    this._isOpen.next(true);
  }

  public close() {
    this._isOpen.next(false);
    this._isOpenInitial.next(false);
  }

  public getState(): Observable<boolean> {
    return this._isOpen.asObservable();
  }

  public openInitial() {
    this._isOpenInitial.next(true);
  }

  public closeInitial() {
    this._isOpenInitial.next(false);
    this._isOpen.next(false);
  }

  public getStateInitial(): Observable<boolean> {
    return this._isOpenInitial.asObservable();
  }


}
