import { Injectable } from '@angular/core';
import { LoginStatusService } from '../auth/login/login-status.service';
import { BonusListService } from '../payment/bonus/bonus-list.service';
import { PaymentListService } from '../payment/methods/payment-list.service';

@Injectable({
    providedIn: 'root'
})
export class PreloadService {

    constructor(
        private bonusListService: BonusListService,
        private paymentListService: PaymentListService,
        private loginStatusService: LoginStatusService,
        // private chargeBackService: ChargebackService
    ) {
    }

    updateStore(username: string) {
        // this.bonusListService.updateStore();
        // this.loginStatusService.getLoginStatus().pipe(take(1)).subscribe(resp => {
        //     this.paymentListService.updateStore(resp.fullInfo.country.toLowerCase());
        // })

        // this.chargeBackService.updateStore(username);
    }
}
