import { Inject, Injectable, LOCALE_ID, NgZone, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of, Subject, switchMap, timer } from 'rxjs';
import { USER_MESSAGES } from '../http/base-http.service';
import { CookieService } from 'ngx-cookie-service';
import { HOST_NAME } from '../../app-routing.module';
import { filter, map, take, takeUntil } from 'rxjs/operators';
import { LoginStatusService } from '../auth/login/login-status.service';
import { LoggedStatus } from '../auth/login/login.models';
import { BaseUsermgrService } from '../http/base-usermgr.service';
import { UserMesasgeEntry, UserMesasgesResponse } from './user-message.models';
import { environment } from 'src/environments/environment';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root'
})
export class UserMessagesService extends BaseUsermgrService implements OnDestroy {

  public lastUserMessagesResponse = new BehaviorSubject<UserMesasgesResponse>(null);
  public currentPage = new BehaviorSubject<number>(1);
  public unreadMessagesCount = new BehaviorSubject<number>(0);
  public hasNewMessages = new BehaviorSubject<boolean>(false);
  private unsubscribe = new Subject<void>();
  private pollingStarted = false;
  private _isOpenNewMessageInfoBox = new BehaviorSubject<boolean>(false);

  constructor(http: HttpClient,
    cookie: CookieService,
    private loginStatus: LoginStatusService,
    // private afs: AngularFirestore,
    @Inject(LOCALE_ID) public locale: string,
    @Inject(HOST_NAME) public hostName,
    private ngZone: NgZone) {
    super(http, cookie, locale, hostName);
    // this.startCheckingForUnreadMessages();
  }


  public isOpendNewMessageInfoBox() {
    return this._isOpenNewMessageInfoBox.asObservable();
  }

  public closeNewMessageInfoBox() {
    this._isOpenNewMessageInfoBox.next(false);
  }



  private requestUserMessages(page: number): Observable<UserMesasgesResponse> {
    return this.loginStatus.getIfUserLogged().pipe(
      take(1),
      switchMap((status) => {
        return this.get<UserMesasgesResponse>(USER_MESSAGES + '?full=1&page=' + page).pipe(
          map(response => {
            this.setUnreadMessagesCount(response.unread_count);
            this.lastUserMessagesResponse.next(response);
            return response;
          })
        );
      })
    )
  }

  public getUserMessages(useCache: boolean = true): Observable<UserMesasgesResponse> {
    return this.lastUserMessagesResponse.pipe(
      switchMap(cachedResponse => {
        if (cachedResponse && useCache) {
          return of(cachedResponse);
        } else {
          return this.requestUserMessages(this.currentPage.value);
        }
      })
    );
  }

  public requestUserMessagesInfo(): Observable<UserMesasgesResponse> {
    return this.get<UserMesasgesResponse>(USER_MESSAGES).pipe(
      map(response => {
        this.setUnreadMessagesCount(response.unread_count);
        return response;
      })
    );
  }

  private requestUserMessage(id: number): void {
    this.get<UserMesasgesResponse>(USER_MESSAGES + id + '/?full=1').subscribe();
  }



  public getUserMessage(id: number): Observable<UserMesasgeEntry> {
    return this.lastUserMessagesResponse.pipe(
      switchMap(cachedResponse => {
        if (cachedResponse) {
          const foundMessage = cachedResponse.results.find(msg => msg.id === id);
          if (foundMessage) {
            if (foundMessage.is_unread) {
              this.requestUserMessage(id);
              this.setUnreadMessagesCount(this.unreadMessagesCount.value - 1);
              foundMessage.is_unread = false;
            }
            return of(foundMessage)
          }
        }
        return of(null);
      })
    );
  }

  public markMessageAsRead(id: number): Observable<UserMesasgeEntry> {
    return this.loginStatus.getLoginStatus().pipe(
      filter(status => status.isLogged !== LoggedStatus.voidState),
      switchMap((status) => {
        if (status.isLogged === LoggedStatus.logged) {
          return this.postRequestJson<UserMesasgeEntry>(USER_MESSAGES + id + '/', null).pipe(
            map(resp => {
              return resp;
            })
          )
        } else {
          return of(null);
        }
      })
    )
  }

  public deleteMessage(id: number): Observable<boolean> {
    return this.loginStatus.getLoginStatus().pipe(
      filter(status => status.isLogged !== LoggedStatus.voidState),
      switchMap((status) => {
        if (status.isLogged === LoggedStatus.logged) {
          return this.delete<UserMesasgesResponse>(USER_MESSAGES + id + '/').pipe(
            map(() => {
              return this.removeMessageFromCache(id);
            })
          );
        } else {
          return of(false);
        }
      })
    );
  }

  public removeMessageFromCache(id: number): boolean {
    if (this.lastUserMessagesResponse.value) {
      const filteredResults = this.lastUserMessagesResponse.value.results.filter(msg => msg.id !== id);
      const newCount = this.lastUserMessagesResponse.value.count - 1;
      const shouldSwitchToPreviousPage = filteredResults.length === 0 && this.currentPage.value > 1;
      this.lastUserMessagesResponse.next({
        ...this.lastUserMessagesResponse.value,
        count: newCount,
        results: filteredResults,
      });
      return shouldSwitchToPreviousPage;
    }
    return false;
  }

  public goToNextPage(): void {
    if (this.lastUserMessagesResponse.value && this.lastUserMessagesResponse.value.next) {
      this.currentPage.next(this.currentPage.value + 1);
      this.requestUserMessages(this.currentPage.value).subscribe();
    }
  }

  public goToPreviousPage(): void {
    if (this.currentPage.value > 1) {
      this.currentPage.next(this.currentPage.value - 1);
      this.requestUserMessages(this.currentPage.value).subscribe();
    }
  }

  public resetState(): void {
    this.currentPage.next(1);
    this.lastUserMessagesResponse.next(null);
  }

  private setUnreadMessagesCount(count: number): void {
    this.unreadMessagesCount.next(count);
    this.hasNewMessages.next(count > 0);
  }

  public checkNewMessages(): Observable<boolean> {
    return this.hasNewMessages.asObservable();
  }

  // public setCloseUserMessages(username) {
  //   this.afs.doc(`prize-users/${username}`).set({ isInfoBoxNotoficationClosed: true }, { merge: true });
  // }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
