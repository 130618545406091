import { Apollo, gql } from 'apollo-angular';
import { Component } from '@angular/core';
import { BasePageComponent } from 'src/app/components/base-page/base-page.component';


import { takeUntil } from 'rxjs/operators';
import { MatDialogRef } from '@angular/material/dialog';
import { AvatarService } from '../../../../services/profile/avatar.service';

export type Avatar = {
  name: string,
  thumbnail: {
    url: string
  }
}

export const avatarQuery = gql`query GameSearchList{
  avatars {
    name
    thumbnail {
      url
    }
  }
  }
  `
@Component({
  selector: 'app-select-avatar',
  templateUrl: './select-avatar.component.html',
  styleUrls: ['./select-avatar.component.scss']
})
export class SelectAvatarComponent extends BasePageComponent {

  constructor(
    public dialogRef: MatDialogRef<SelectAvatarComponent>,
    private apollo: Apollo,
    private avatarService: AvatarService,
  ) { super() }

  avatars: Avatar[]


  ngOnInit(): void {
    this.apollo
      .watchQuery<{ avatars: Avatar[] }>({
        query: avatarQuery,
      })
      .valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(resp => {
        this.avatars = resp.data.avatars;
      });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  selectAvatar(avatar: Avatar) {
    this.avatarService.setAvatar(avatar.thumbnail.url)
    this.dialogRef.close(avatar);
  }

}
