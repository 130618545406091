import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { BasePaymentService } from '../../http/base-payment.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { PAYMENT_HISTORY } from '../../http/base-http.service';
import { PaymentHistory } from './history.models';
import { HOST_NAME } from 'src/app/app-routing.module';

@Injectable({
  providedIn: 'root'
})
export class PaymentHistoryService extends BasePaymentService {

  constructor(http: HttpClient, cookie: CookieService, @Inject(LOCALE_ID) public locale: string, @Inject(HOST_NAME) public hostName) {
    super(http, cookie, locale, hostName);
  }

  public requestHistory(page: number, historyUrl: string, isDeposit: boolean): Observable<PaymentHistory> {
    const url = page === 1 ? PAYMENT_HISTORY : historyUrl;
    const tranType = isDeposit ? 'D' : 'W';
    const tranTypeParam = url.includes('transaction_type') ?  new HttpParams() : new HttpParams().set('transaction_type', tranType);

    if (page === 1) {
      return this.getWithParams(url, tranTypeParam);
    }
    else {
      return this.getOriginalUrlWithParams(url, tranTypeParam);
    }
  }
}
