import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, EMPTY, Observable } from 'rxjs';
import { BaseFirebaseService } from '../http/base-firebase.service';
import { LoginStatusService } from '../auth/login/login-status.service';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { PrizeUser, SavedDepositState } from './edit-profile.models';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { LoggedStatus } from '../auth/login/login.models';
import { HOST_NAME } from 'src/app/app-routing.module';

@Injectable({
  providedIn: 'root'
})
export class DepositStateService extends BaseFirebaseService {

  constructor(
    http: HttpClient,
    afAuth: AngularFireAuth,
    afs: AngularFirestore,
    private loginStatus: LoginStatusService,
    @Inject(HOST_NAME) public hostName
  ) {
    super(http, afAuth, afs, hostName);
  }

  // tslint:disable-next-line:variable-name
  private _store = new BehaviorSubject<SavedDepositState>(null);
  public isDataLodaed = new BehaviorSubject<boolean>(false);

  public getLastDepositState(): Observable<SavedDepositState> {
    return this._store.asObservable();
  }

  public refreshDepositState() {
    return new Promise<void>((resolve, reject) => {
    this.loginStatus.getIfUserLogged().pipe(
      switchMap(
        (status) => {
          return this.getDocumentDB<PrizeUser>(`prize-users/${status.username}`).pipe(
            filter(resp => !!resp),
            map((resp: PrizeUser) => {

              if (!resp.depositState) {
                this.isDataLodaed.next(true);
                resolve();
              }
              return resp.depositState;
            })
          );
        }
      ),
      filter(resp => !!resp),
      catchError(error => {
        console.log(error);
        return EMPTY;
      }),
      take(1),
    ).subscribe((resp: SavedDepositState) => {
      this._store.next(resp);
      this.isDataLodaed.next(true);
      resolve();
    });
  });
  }

  public addDepositState(state: SavedDepositState) {
    this.loginStatus.getLoginStatus().pipe(
      filter((status) => status.isLogged === LoggedStatus.logged),
      take(1),
      map((userInfo) => userInfo.username),
      tap((username) => {
        this.setDocument<{ depositState: SavedDepositState }>({depositState: state}, `prize-users/${username}`, true);
      })
    ).subscribe((username) => {
      this.refreshDepositState();
    });
  }
}
