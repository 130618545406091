import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment } from '@angular/router';
import { UserInfoService } from 'src/app/services/auth/check-session/user-info.service';
import { Observable, of } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';
import { LoginStatusService } from './services/auth/login/login-status.service';
import { LoggedStatus } from './services/auth/login/login.models';
import { loginTrx } from 'src/app/router-translation.labels';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanLoad {
  constructor(
    private router: Router,
    private loginStatusService: LoginStatusService,
    private userInfoLoginService: UserInfoService,

  ) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.loginStatusService.getLoginPartialStatus().pipe(
      switchMap(status => {
        return of(status)
      }),
      tap(isLoggedIn => {
        if (!isLoggedIn) {
          this.router.navigate(['', loginTrx], { queryParams: { redirectTo: state.url } });
        }
      })
    );
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return this.loginStatusService.getLoginPartialStatus().pipe(
      switchMap(status => {
        return of(status)
      }),
      tap(isLoggedIn => {
        if (!isLoggedIn) {
          const url = segments.map(s => s.path).join('/');
          this.router.navigate(['', loginTrx], { queryParams: { redirectTo: url } });
        }
      })
    );
  }
}
