import { Observable } from 'rxjs';
import { PrizeBox } from '../client-area/client-area.models';

export enum ChargeBackStatus {
    ACTIVE = 1,
    CLAIM = 3
}

export type UserChargebackResponse = {
    active: UserChargeback | Observable<UserChargeback>,
    toClaim?: UserChargeback
}

export type UserChargeback = {
    win: number,
    lost: number,
    ggr: number,
    value: number
    name: string,
    payoutDate: string,
    chargebackUID: string,
    convertedDate?: Date,
    currency: string,
    status: ChargeBackStatus,
    isToClaim?: boolean
}

export type ActiveChargeback = {
    name: string,
    payoutDate: string,
    convertedDate?: Date
}

export interface UserGamificationLevel {
    gamificationReference: string
    levelNumber: number
    turnover: number
    levelEndsTurnover: number
}

export interface UserBonusLottery {
    isActive: boolean
    uid: string
    leftToOpen: number,
    prizeBoxes: PrizeBox[]
}

export enum LimitType {
    deposit = "deposit",
    wager = "wager",
    lost = "lost",
    game_session_time = "game_session_time"
}

export interface LimitEntry {
    sum: number,
    type: LimitType,
    period: string,
    value: number,
    created?: string
}

export interface LimitsResponse {
    has_parallel_limits: boolean
    limits: LimitEntry[]
    country: string,
    currency: string,
}

export interface TopGames {
  maxWin: string[],
  totalRounds: string[]
}

export interface MostPlayedGameStatistic {
  externalId: string,
  uniquePlayers: number
}
