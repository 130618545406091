import { Component, Inject } from '@angular/core';
import { LogoutService } from 'src/app/services/auth/logout/logout.service';
import { BasePageComponent } from '../../base-page/base-page.component';
import { UserInfo } from 'src/app/services/auth/login/login.models';
import { takeUntil } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { OpenDesktopDrawerService } from 'src/app/services/utils/open-desktop-drawer.service';
import { DetectDeviceService } from 'src/app/services/utils/detect-device.service';
import { LOCATION } from '@ng-web-apis/common';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent extends BasePageComponent {

  constructor(private logoutService: LogoutService,
    private router: Router,
    public openDrawerService: OpenDesktopDrawerService,
    public detectDeviceService: DetectDeviceService,
    private route: ActivatedRoute,
    @Inject(LOCATION) readonly location,
  ) { super() }

  userInfo: UserInfo;

  ngOnInit(): void {
  }

  logout() {
    this.logoutService.requestLogoutUser().pipe(takeUntil(this.unsubscribe)).subscribe((resp: UserInfo) => {
      this.openDrawerService.closeInitial()
      this.openDrawerService.close();
      this.location.reload();
    }, err => {
      console.log(err);
    });
  }

}
