<!--
<swiper  *ngIf="data" fxFlex="auto" [config]="config">
  <div *ngFor="let item of data" class="swiper-slide cursor-pointer">
      <div fxLayout="column" fxLayoutAlign="center center" fxFlexFill >
        <div class="game-card">
          <img (click)="openDialog(item.name,item.thumbnail.url,item.gameid,item.url,item.id)" src="{{ item.thumbnail.url }}" />
          <div>
            <p>
              {{item.name}}
            </p>
          </div>
        </div>
      </div>
  </div>
</swiper> -->

<!-- <mat-grid-list #gamegrid cols="3" gutterSize="3px" rowHeight="139px" >
  <mat-grid-tile class="game-tile" *ngFor="let item of data ">
    <app-game-card  (clickEvent)="openDialog($event)" [data]="item"></app-game-card>
  </mat-grid-tile>
</mat-grid-list> -->

<div class="game-card-wrapper">
  <ng-container *ngIf="isServer else gamesWithSwiper">
    <div [fxFlex]="flexValue" [fxFlex.gt-xs]="flexWidth" class="game-card" *ngFor="let item of data | slice:0:iteration; let i=index">
      <app-desktop-game-card (clickEvent)="openDialog($event)" [size]="size" [data]="item"></app-desktop-game-card>
    </div>
  </ng-container>
</div>

<ng-template #gamesWithSwiper>
  <div class="game-card-wrapper swiper-grid-cards desktop">
    <swiper [style]="{'max-height':(260*numberOfRows)+'px', 'height':(260*numberOfRows)+'px'}" [config]="gamesSwiperConfig" #swipercomponent>
      <ng-template swiperSlide *ngFor="let item of data">
        <div *ngIf="isLoggedIn || (!item.hideGame && !isLoggedIn)" class="game-card">
          <app-desktop-game-card (clickEvent)="openDialog($event)" [size]="size" [data]="item"></app-desktop-game-card>
        </div>
      </ng-template>
    </swiper>
  </div>
</ng-template>

<!-- <div fxLayout="row wrap" fxLayoutAlign="space-between" fxLayoutGap="10px" [ngClass]="size">
  <div [fxFlex]="flexValue" [fxFlex.gt-xs]="flexWidth" class="game-card" *ngFor="let item of data | slice:0:iteration; let i=index">
    <app-desktop-game-card (clickEvent)="openDialog($event)" [size]="size" [data]="item"></app-desktop-game-card>
  </div>
</div> -->