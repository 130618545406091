import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ALL_TRANSLATED_ROUTES, TRANSLATED_ROUTES } from '../app-routing.module';
import { DetectDeviceService } from '../services/utils/detect-device.service';

@Injectable()
export class TranslationConfig {

    public config: Object = null;
    isDesktop = false;

    constructor(private router: Router,
        public detectDeviceService: DetectDeviceService,
        @Inject(TRANSLATED_ROUTES) public tranlatedRoutes: string,
        @Inject(ALL_TRANSLATED_ROUTES) public allTranlatedRoutes: string,
    ) {
        this.isDesktop = this.detectDeviceService.isDesktop();
    }

    /**
     * Use to get the data found in the second file (config file)
     */
    public getTranslation(key: string) {
        // if (this.isDesktop) return key;

        const trans = !!this.tranlatedRoutes ? this.tranlatedRoutes[key] : null;
        if (!!trans) return trans
        else return key
    }

    public getTranslationForLang(lang: string, key: string) {
        const trans = this.allTranlatedRoutes[lang][key];
        if (trans) return trans
        else return key
    }

    public translateToOriginal(phrase: string) {
        if (!!phrase && !!this.tranlatedRoutes) {
            const entries = phrase.split('/');
            const translationEntries = Object.entries(this.tranlatedRoutes);


            const result = entries.map(elem => {
                const originalEntry = translationEntries.filter(([key, value]) => {
                    return value === elem
                })[0]?.[0];
                return originalEntry || elem;
            }).join("/");
            return result;
        }
    }

    public translateUrlEntry(toTranslate: string): string {
        if (!!toTranslate) {
            const entries = toTranslate.split('/');
            const result = entries.map(elem => this.getTranslation(elem)).join("/");
            return result;
        }
        else {
            return toTranslate;
        }
    }

    public translateUrlEntryLang(lang: string, toTranslate: string): string {
        if (!!toTranslate) {
            const entries = toTranslate.split('/');
            const result = entries.map(elem => this.getTranslationForLang(lang, elem)).join("/");
            return result;
        }
        else {
            return toTranslate;
        }
    }

    public translatePathElement(pathElement) {
        if (pathElement.children && pathElement.children.length > 0) {
            pathElement.children.forEach(child => {
                child.path = this.translatePathElement(child);
            });
        }
        return this.translateUrlEntry(pathElement.path)
    }

    public translateUrlToAllLanguages(currentUrl) {
        let translatedUrlMap: Map<string, string> = new Map();
        Object.entries(this.allTranlatedRoutes).forEach(([lang, value]) => {
            const translatedUrl = this.translateUrlEntryLang(lang, currentUrl);
            translatedUrlMap.set(lang, translatedUrl)
        })

        return translatedUrlMap;
    }
    /**
     * This method:
     *   a) Loads "env.json" to get the current working environment (e.g.: 'production', 'development')
     *   b) Loads "config.[env].json" to get all env's variables (e.g.: 'config.development.json')
     */
    public load() {
        this.config = this.tranlatedRoutes;
        let currentRoutes = this.router.config
        // if (mobileRoutes || desktopRoutes) {
        //     currentRoutes = this.detectDeviceService.isDesktop() ? desktopRoutes : mobileRoutes;
        // }
        // console.log("translation callsed", currentRoutes)
        currentRoutes.forEach(entry => {
            entry.path = this.translatePathElement(entry);
            // entry.path = this.translateUrlEntry(entry.path);

            // if (entry.children && entry.children.length > 0){
            //     entry.children.forEach(child => {
            //         child.path = this.translateUrlEntry(child.path);
            //     });
            // }
        })
        this.router.resetConfig(currentRoutes);
    }
}
