import { Apollo, gql } from 'apollo-angular';
import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EMPTY, Observable } from 'rxjs';
import { BaseFirebaseService } from '../http/base-firebase.service';
import { LoginStatusService } from '../auth/login/login-status.service';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { GameItem } from '../../apollo/models/base-models';
import { LoggedStatus } from '../auth/login/login.models';
import { BEST_GAMES } from '../http/base-http.service';
import { GameRankEntry, GameRanking } from './client-area.models';
import { HOST_NAME } from 'src/app/app-routing.module';
import { FilterGamesService } from '../utils/filter-games.service';

const query = gql`query GameSearchList($searchIds: [String!]){
    games(where: {gameid_in: $searchIds}) {
      id
      name,
      gameid,
      systemName,
      tag,
      thumbnail {
        url
      }
      thumbnailDesktopSquare{
        url(transformation: {
          image: { resize: { width: 194, height: 194, fit: clip } }
          document: { output: { format: webp } }
        })
      }
      thumbnailDesktopRectangular{
        url(transformation: {
          image: { resize: { width: 236, height: 177, fit: clip } }
          document: { output: { format: webp } }
        })
      }
      gameProvider{
        name
        providerExclusionTerritories{
          countryCode
          state
        }
      }
      animation{
        url
        fileName
      }
    }
  }
`

@Injectable({
    providedIn: 'root'
})
export class BestGamesService extends BaseFirebaseService {

    constructor(
        http: HttpClient,
        afAuth: AngularFireAuth,
        afs: AngularFirestore,
        private apollo: Apollo,
        private loginStatus: LoginStatusService,
        private filterGamesService: FilterGamesService,
        @Inject(HOST_NAME) public hostName
    ) {
        super(http, afAuth, afs, hostName);
    }

    // private _store = new BehaviorSubject<GameItem[]>([]);

    // public getBestGamesStore(): Observable<GameItem[]> {
    //     return this._store.asObservable();
    // }

    public getBestPlayed(): Observable<GameItem[]> {
        return this.loginStatus.getLoginStatus().pipe(
            filter((status) => status.isLogged === LoggedStatus.logged),
            mergeMap(
                (status) => {
                    return this.get<GameRanking>(BEST_GAMES);
                }
            ),
            filter(resp => !!resp),
            map(item => item.games),
            mergeMap(
                (gameRankEntries: GameRankEntry[]) => {
                    const gameNames = gameRankEntries.map(item => item.uid.trim())
                    if (gameNames && gameNames.length > 0) {
                        return this.apollo
                            .watchQuery<{ games: GameItem[] }>({
                                query: query,
                                variables: {
                                    searchIds: gameNames
                                }
                            })
                            .valueChanges.pipe(
                                map(resp => {
                                    return [...resp.data.games].sort(function (a, b) {
                                        return gameNames.indexOf(a.gameid) - gameNames.indexOf(b.gameid);
                                    });
                                })
                            )
                    }
                    else return EMPTY
                }
            ),
            catchError(error => {
                console.log(error)
                return EMPTY;
            })
        )
    }





}
