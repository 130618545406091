<div class="step-body desktop-panel desktop-verify" fxLayoutAdivgn="center center" fxLayout="column" [ngClass]="isDesktop ? 'desktop': 'mobile'">
    <ng-container *ngIf="!isChangePhone else changephone">
        <div class="sms-form-wrapper form-custom-design" fxLayoutAlign="center center" fxLayout="column">
            <h3 i18n>Please enter the 5-digit verification code we sent via SMS:</h3>
            <!-- <span class="additional-info" *ngIf="!detectDesktopService.isDesktop()" i18n>(we want to make sure it's really you)</span> -->
            <form name="smsForm" [formGroup]="smsForm" (ngSubmit)="onSubmit(smsForm)">
                <div class="sms-verification-wrapper" fxLayoutGap="5px" fxLayoutAlign="start center" fxLayout="row">
                    <input autocomplete="one-time-code" class="sms-input" #smsinput inputmode="numeric" type="text" pattern="[0-9]{5}" formControlName="code" maxlength="5" autofocus>
                </div>
                <div class="no-code">
                    <span class="additional-info" i18n>We want to make sure it's really you</span>
                    <span i18n class="code-question">Didn't receive the code?</span><br />
                    <span class="link" (click)="resendCode()"><ng-container i18n>Send code again</ng-container>&nbsp;<ng-container *ngIf="timerValue>0">{{timerValue}}s</ng-container></span><br />
                    <span class="link" (click)="isChangePhone=true" i18n>Change phone number</span>
                    <img src="{{licenceService.getKey('signup-step-three')}}">
                </div>
                <div class="buttons">
                    <div><button round-button filled="filled" [class.spinner]="formSubmited" color="primary" type="submit"><span *ngIf="!formSubmited" i18n>next</span><span *ngIf="formSubmited">&nbsp;</span></button></div>
                </div>
            </form>
        </div>
    </ng-container>
</div>

<ng-template #changephone>
    <div class="step-body" fxLayoutAdivgn="center center" fxLayout="column">
        <div class="info" i18n>
            Please enter your phone number for SMS verification.
        </div>
        <div class="change-telephone-form-wrapper">
            <form [formGroup]="changePhoneForm" (ngSubmit)="addPhoneVerification(changePhoneForm)">
                <div class="telephoneContainer" fxLayout="row" fxLayoutAlign="center center">
                    <mat-form-field class="ccode" appearance="outline">
                        <mat-label i18n>country prefix</mat-label>
                        <select matNativeControl name="car" [(ngModel)]="prefixCountry" [ngModelOptions]="{standalone: true}" (change)="triggerTelephoneChange()">
                            <option *ngFor="let code of countryCodes" [value]="code.dial_code">{{ code.dial_code }} {{ code.name }}
                            </option>
                        </select>
                    </mat-form-field>
                    <mat-form-field class="telephone" appearance="outline">
                        <mat-label i18n>telephone number</mat-label>
                        <input matInput [(ngModel)]="telephoneSuffix" [ngModelOptions]="{standalone: true}" name="telephoneSuffix" (change)="triggerTelephoneChange()">
                    </mat-form-field>
                    <div class="errorMsgTelephone">
                        <span class="error" *ngIf="!isTelephoneValid" i18n>Please enter the correct phone number.</span>
                    </div>
                    <input type="hidden" formControlName="telephone" />
                </div>
                <div class="buttons">
                    <div><button mat-flat-button [disabled]="!changePhoneForm.valid" [class.spinner]="formSubmited" color="primary" type="submit"><span *ngIf="!formSubmited" i18n>submit</span><span *ngIf="formSubmited">&nbsp;</span></button></div>
                </div>
            </form>
        </div>
        <div class="no-code">
            <img src="{{licenceService.getKey('signup-step-three')}}">
            <span class="link" (click)="isChangePhone=false" i18n>cancel</span>
        </div>
    </div>
</ng-template>