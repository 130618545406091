import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BasePageComponent } from 'src/app/components/base-page/base-page.component';
import { SearchConfigFilterItem, SearchDataItem } from 'src/app/services/games/search-game.service';
import { SwiperOptions } from 'swiper';

@Component({
  selector: 'app-search-game-swiper',
  templateUrl: './search-game-swiper.component.html',
  styleUrls: ['./search-game-swiper.component.scss']
})
export class SearchGameSwiperComponent extends BasePageComponent implements OnInit {

  config: SwiperOptions = {
    slidesPerView: 5,
    spaceBetween: 10,
    pagination: { clickable: true },
    navigation: true,
    loop: true
  };

  public searchSwiper: SearchConfigFilterItem;
  @Input() _searchSwiper: SearchConfigFilterItem;

  @Output() filterSelectEvent = new EventEmitter<SearchDataItem>();

  constructor() {
    super()
  }

  ngOnInit() {
    this.searchSwiper = this._searchSwiper;
  }

  onFilterSelect(item: SearchDataItem) {
    this.filterSelectEvent.next(item)
  }

}
