import { Component, Input } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { takeUntil } from 'rxjs';
import { FirebaseAuthService } from 'src/app/services/auth/firebase/firebase-auth.service';
import { LoginStatusService } from 'src/app/services/auth/login/login-status.service';
import { DetectDeviceService } from 'src/app/services/utils/detect-device.service';
import { nicknameValidator } from 'src/app/utils/nickname-validator';
import { BasePageComponentWithDialogs } from '../../base-page/base-page.component';
import { UserInfoService } from 'src/app/services/auth/check-session/user-info.service';

@Component({
  selector: 'app-edit-nickname',
  templateUrl: './edit-nickname.component.html',
  styleUrls: ['./edit-nickname.component.scss']
})
export class EditNicknameComponent extends BasePageComponentWithDialogs {
  isDesktop:boolean;
  constructor(
    private loginStatusService: LoginStatusService,
    private firebaseService: FirebaseAuthService,
    private formBuilder: UntypedFormBuilder,
    private infoService:UserInfoService,
    errorDialog: MatDialog,
    private detectDesktopService: DetectDeviceService,

  ) {
    super(errorDialog, detectDesktopService);
    this.isDesktop = this.detectDesktopService.isDesktop();
  }

  ngOnInit(): void {
    this.updateNicknameValue();
  }

  @Input() isDarkBackground = false;

  nickname: string;
  isEditNickname: boolean = false;

  nickNameExists: string = $localize`:@@nickNameExists:Nickname already exists`;


  editNicknameForm = this.formBuilder.group({
    nickname: ['', [Validators.required, nicknameValidator]]
  });


  updateNicknameValue() {
    this.loginStatusService.getUserState().pipe(takeUntil(this.unsubscribe)).subscribe(
      data => this.nickname = data.dbUser?.nickname
    )
  }

  submitNickname() {
    if (this.editNicknameForm.disabled) return;
    this.editNicknameForm.disable();
    this.firebaseService.setNickname(this.editNicknameForm.get('nickname').value).pipe(takeUntil(this.unsubscribe)).subscribe((resp) => {
      this.isEditNickname = false;
      this.editNicknameForm.enable();
      this.nickname = this.editNicknameForm.get('nickname').value;
      this.infoService.requestCheckUserInfo().pipe(takeUntil(this.unsubscribe)).subscribe({
        next: (res) => {},
        error: () => {},
        complete: () => {},
      });
    }, err => {
      this.editNicknameForm.enable();
      if (err.status === 406) {
        const formControl = this.editNicknameForm.get('nickname');
        if (formControl) {
          // activate the error message
          formControl.setErrors({
            serverError: this.nickNameExists
          });
        }
      }
      else {
        this.openErrorDialog();
      }
    });
  }

  enableEditNickname(isEnable: boolean) {
    this.isEditNickname = isEnable;
  }

}
