<div class="not-found">
    <div class="not-found-container">
        <h1 i18n>OOPS!</h1>
        <div class="sub-text-1" i18n>You have squeezed the lemon<br> to hard and it caused <span>404 Error :/</span></div>
        <p i18n class="sub-text-2">You can still use the lemon as intended by clicking the button below</p>
        <div class="button-wrapper" [routerLink]="['']">
            <button mat-stroked-button round-button class="lemon-default-btn not-found-btn" i18n>Squezze less juice!</button>
        </div>
        <div>
            <img src="assets/desktop/404.svg">
        </div>
    </div>
</div>