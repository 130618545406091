import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { take, takeUntil } from 'rxjs';
import { LoginStatusService } from 'src/app/services/auth/login/login-status.service';
import { DesktopService } from 'src/app/services/desktop/desktop.service';
import { LicenceService } from 'src/app/services/utils/licence.service';
import { FilterGamesService } from 'src/app/services/utils/filter-games.service';
import { BaseGamePage } from '../../games/ase-game-page.component';

@Component({
  selector: 'app-desktop-search',
  templateUrl: './desktop-search.component.html',
  styleUrls: ['./desktop-search.component.scss']
})
export class DesktopSearchComponent extends BaseGamePage implements OnInit {
  categories: any;
  flexValue = 33;
  flexWidth = 15.52;
  result: any;
  size: string = 'square';
  gameCategory: any;
  categoryTitle: string = $localize`:@@search-page:search`;
  isLoggedIn = false
  constructor(
    private desktopService: DesktopService,
    private loginStatusService: LoginStatusService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    protected licenceService: LicenceService,
    private filterGameService: FilterGamesService,
  ) {
    super(dialog);
  }
  searchQuery = this.route.snapshot.paramMap.get('query');
  ngOnInit(): void {
    this.getGamesCategory();
    this.loginStatusService.getIfUserLogged().pipe(take(1)).subscribe((isLogged) => {
      this.isLoggedIn = true;
    })
    if (this.searchQuery)
      this.searchInput(this.searchQuery);
    this.route.paramMap.pipe(
      takeUntil(this.unsubscribe)
    ).
      subscribe((params: ParamMap) => {
        // this.afterID = null;
        this.gameCategory = params.get('category');
        if (this.gameCategory) {
          this.getGameList(this.gameCategory);
        } else {

        }
      })
  }

  searchInput(data) {
    this.categoryTitle = $localize`:@@search-page-results:Search results` + ' '+data;
    this.desktopService.searchGame(data).valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(res => {
      // this.result = res.data['games'];
      this.result = this.filterGameService.filterGames(res.data.games);
    })
  }

  getGameList(category: string) {
    this.desktopService.gameListCategory(category).valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(res => {
      this.categoryTitle = res.data.gameCategories[0].displayName;
      this.result = res.data.gameCategories[0].game;
    })
  }

  getGamesCategory() {
    this.desktopService.gameCategory().valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(res => {
      this.categories = res.data.pagesCategories[0]?.categories;
    })
  }
}
