<div class="game-card-wrapper swiper-grid-cards" *ngIf="!isServer && !isDesktop else server" inViewport
     [inViewportOptions]="{ threshold: [0] }"
     (inViewportAction)="onIntersection($event)">
  <swiper #swiper [config]="config" fxFlex="auto" class="dynamic-category-swiper">
    <ng-template *ngFor="let item of games" swiperSlide>
      <div class="game-card" *ngIf="isInViewport">
        <app-game-card [isOutsideCategory]="isOutsideCategory" (clickEvent)="openDialog($event)"
                       [data]="item"></app-game-card>
      </div>
    </ng-template>

    <ng-template *ngFor="let item of emptyElements" swiperSlide>
      <div class="game-card-placeholder" [ngClass]="isLoading ? 'skeleton' : ''" *ngIf="isInViewport">
        <img src="assets/images/game-card-placeholder.svg" />
      </div>
    </ng-template>
  </swiper>
</div>

<ng-template #server>
  <div class="game-card-wrapper" inViewport
       [inViewportOptions]="{ threshold: [0] }"
       (inViewportAction)="onIntersection($event)">
    <ng-container *ngFor="let item of games">
      <div class="game-card" *ngIf="isInViewport">
        <app-game-card [isOutsideCategory]="isOutsideCategory" (clickEvent)="openDialog($event)"
                       [data]="item"></app-game-card>
      </div>
    </ng-container>

    <ng-container *ngFor="let item of emptyElements">
      <div class="game-card-placeholder" [ngClass]="isLoading? 'skeleton' : ''" *ngIf="isInViewport">
        <img src="assets/images/game-card-placeholder.svg"/>
      </div>
    </ng-container>
  </div>
</ng-template>
