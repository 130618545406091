
declare let LiveAgent;
export function loadChatScript(chatType, email, firstName) {
  let buttonId = '';

  if (chatType.includes('vip')) {
    switch (chatType) {
      case 'vip-en':
        buttonId = 'i4de43vj';
        break;
      case 'vip-de':
        buttonId = '36m1ik4q';
        break;
      case 'vip-pl':
        buttonId = 'ggpasjg7';
        break;
      case 'vip-de-AT':
        buttonId = '36m1ik4q';
        break;
      case 'vip-hu':
        buttonId = 'Rgafzc5i';
        break;
      case 'vip-no':
        buttonId = 'udnp7duq';
        break;
      case 'vip-en-IN':
        buttonId = 'i4de43vj';
        break;
      default:
        buttonId = 'i4de43vj';
        break;
    }
  } else {
    switch (chatType) {
      case 'en':
        buttonId = '7m7j2opi';
        break;
      case 'de':
        buttonId = '0fuea1xd';
        break;
      case 'pl':
        buttonId = 'rkdvi8zg';
        break;
      case 'de-AT':
        buttonId = '0fuea1xd';
        break;
      case 'hu':
        buttonId = 'Mk269xg6';
        break;
      case 'no':
        buttonId = '8nhq48t8';
        break;
      case 'en-IN':
        buttonId = '7m7j2opi';
        break;
      default:
        buttonId = '7m7j2opi';
        break;
    }
  }

  const body = document.body as HTMLDivElement;
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.innerHTML = `
    (function(d, src, c) {
      var t = d.scripts[d.scripts.length - 1],
        s = d.createElement('script');
      s.id = 'la_x2s6df8d';
      s.defer = true;
      s.src = src;
      s.onload = s.onreadystatechange = function() {
        var rs = this.readyState;
        if (rs && (rs != 'complete') && (rs != 'loaded')) {
          return;
        }
        c(this);
      };
      t.parentElement.insertBefore(s, t.nextSibling);
    })(document, 'https://lemon2.ladesk.com/scripts/track.js', function(e) {
      LiveAgent.createButton('${buttonId}', e);
      LiveAgent.clearAllUserDetails();
      LiveAgent.setUserDetails('${email}', '${firstName}');
    });
  `;
  try {
    LiveAgent
    showChat();
  } catch (e) {
    body.appendChild(script);
  }
}

export function hideChat() {
  try {
    (document.getElementsByClassName('circleRollButton')[0] as any).style['display'] = 'none'
  } catch (e) {

  }
}

function showChat() {
  try {
    (document.getElementsByClassName('circleRollButton')[0] as any).style['display'] = 'block'
  } catch (e) {

  }

}
