<div class="seaech-swiper-wrapper">
    <swiper [config]="config" *ngIf="searchSwiper">
        <ng-template swiperSlide *ngFor="let item of searchSwiper.data">
          <ng-container *ngIf="item.thumbnail">
            <div class="swiper-slide-content" (click)="onFilterSelect(item)">
              <img class="item-icon" src="{{item.thumbnail?.url}}" alt="{{item.name}}" />
              <p [innerHTML]="item.displayName | safeHtml"></p>
            </div>
          </ng-container>
        </ng-template>
    </swiper>
</div>
