<div class="auth-content" [ngClass]="{'desktop': isDesktop, 'mobile': !isDesktop}" *ngIf="!isSubmited">
    <!-- <mat-divider class="email-divider"></mat-divider> -->

    <div class="lost-pass-form" style="width: 100%;">
        <form style="display: flex; justify-content: center; width: 100%;" id="lost-form" name="lostPasswordForm" [formGroup]="lostPasswordForm" (ngSubmit)="onSubmit(lostPasswordForm)">
            <div class="auth-body mat-componets">
                <div class="image-box">
                    <img src="assets/images/img-lemon-email-2.png">
                </div>

                <div class="form-field">
                    <p i18n class="para-txt email">Enter the email address that you have registered with Lemon Casino.</p>
    
                    <mat-form-field appearance="outline">
                        <mat-label i18n>E-mail</mat-label>
                        <input matInput placeholder="   " type="text" formControlName="mail" cdkFocusInitial> <!-- placeholder="{{enterEmail}}" -->
                        <img class="img-suffix" src="assets/desktop/mail-icon.svg" matSuffix>
                        <mat-hint *ngIf="lostPasswordForm.get('mail').errors && lostPasswordForm.get('mail').touched" class="error-hint" i18n><img class="img-hint" src="assets/icons/warning_hint_icon.png">Enter a valid emal address</mat-hint>
                    </mat-form-field>
                    <mat-error *ngIf="hasError">{{errorMsg}}</mat-error>
                </div>

            </div>

            <div class="auth-footer">
                <button class="confirm-btn" [class.spinner]="formSubmited" style="text-transform: uppercase;" round-button size="large" filled="filled" color="primary" type="submit">
                    <span *ngIf="!formSubmited" i18n>submit</span><span *ngIf="formSubmited">&nbsp;</span></button>

                <p style="margin-top: -8px;" i18n>Follow the instructions in the email :)</p>
            </div>
        </form>
    </div>

    <!-- <ng-template #lostCompleted>
        <div class="auth-body">
            <div class="image-box">
                <img src="assets/images/lemon-envelope-sent-2.png">
            </div>

            <h2 i18n>Recovery email has been sent.</h2>

            <p style="width: 100%;" i18n>Now check your email to reset your password. See you soon!</p>
        </div>

        <div class="auth-footer">
            <button style="text-transform: uppercase;" round-button size="large" filled="filled" color="primary" (click)="closeDialog()" i18n>OK</button>
        </div>
    </ng-template> -->
</div>


<div class="auth-content" [ngClass]="{'desktop': isDesktop, 'mobile': !isDesktop}" *ngIf="verificationCode">
    <!-- <mat-divider></mat-divider> -->

    <div class="lost-pass-form" style="width: 100%;">
        <form style="display: flex; justify-content: center; width: 100%;" id="lost-form" name="lostPasswordFormCode" [formGroup]="lostPasswordFormCode" (ngSubmit)="onSubmitCode(lostPasswordFormCode)">
            <div class="auth-body mat-componets">
                <div class="image-box">
                    <img src="assets/images/lemon-envelope-sent-2.png">
                </div>

                <div class="form-field">
                    <p class="para-txt" i18n>Please enter the verification code we sent to your <strong>registered email address.</strong></p>

                    <mat-form-field appearance="outline">
                        <mat-label i18n>Code</mat-label>
                        <input matInput class="code" placeholder="   " type="tel" maxlength="6" formControlName="code" cdkFocusInitial>
                        <mat-hint *ngIf="lostPasswordFormCode.get('code').errors && lostPasswordFormCode.get('code').touched" class="error-hint" i18n><img class="img-hint" src="assets/icons/warning_hint_icon_2.svg">Enter a valid Code</mat-hint>
                    </mat-form-field>
                    <mat-error *ngIf="hasError">{{errorMsg}}</mat-error>

                </div>

            </div>

            <div class="auth-footer">
                <button class="confirm-btn" [class.spinner]="formSubmited" style="text-transform: uppercase;" round-button size="large" filled="filled" color="primary" type="submit" [disabled]="lostPasswordFormCode.invalid">
                    <span *ngIf="!formSubmited" i18n>submit</span><span *ngIf="formSubmited">&nbsp;</span>
                </button>
            </div>
        </form>
    </div>
</div>

<div class="auth-content" [ngClass]="{'desktop': isDesktop, 'mobile': !isDesktop}" *ngIf="newPassword">
    <!-- <mat-divider></mat-divider> -->

    <div class="lost-pass-form" style="width: 100%;">
        <form style="display: flex; justify-content: center; width: 100%;" id="lost-form" name="lostNewPasswordForm" [formGroup]="lostNewPasswordForm" (ngSubmit)="onSubmitPassword(lostNewPasswordForm)">
            <div class="auth-body mat-componets">
                <div class="image-box">
                    <img src="assets/images/image-pass-code-modal 1.svg">
                </div>

                <div class="form-field">
                    <p class="para-txt" i18n>Please enter your <strong>new password</strong> to get access to Lemon Casino.</p>

                    <mat-form-field appearance="outline">
                        <mat-label i18n>New password</mat-label>
                        <input matInput placeholder="   " [type]="hidePassword ? 'password' : 'text'" formControlName="new_password" cdkFocusInitial>
                        <img class="img-suffix" src="assets/desktop/{{hidePassword ? 'eye-off' : 'eye-on'}}.svg" matSuffix (click)="hidePassword = !hidePassword">
                        <mat-hint *ngIf="lostNewPasswordForm.get('new_password').errors && lostNewPasswordForm.get('new_password').touched" class="error-hint" i18n><img class="img-hint" src="assets/icons/warning_hint_icon_2.svg">Enter a valid Password</mat-hint>
                    </mat-form-field>

                </div>

            </div>

            <div class="auth-footer">
                <button class="confirm-btn" [class.spinner]="formSubmited" style="text-transform: uppercase;" round-button size="large" filled="filled" color="primary" type="submit">
                    <span *ngIf="!formSubmited" i18n>submit</span><span *ngIf="formSubmited">&nbsp;</span>
                </button>
            </div>
        </form>
    </div>
</div>

<div class="success-popup" [ngClass]="{'desktop': isDesktop, 'mobile': !isDesktop}" *ngIf="success">
    <div class="success-content">
        <div class="success-header">
            <h2 i18n>Success!</h2>
            <app-close-dialog-button [customClass]="'medium'" (closeEvent)="closeDialog()" [position]="true"></app-close-dialog-button>
        </div>
        <div class="success-body">
            <mat-divider></mat-divider>
            <div class="image-box">
                <img src="assets/images/user_success_popup.svg">
            </div>
            <div class="success-txt">
                <p i18n>Your password has been changed!</p>
            </div>
            <div class="success-button">
                <button (click)="closeDialog()" i18n>ok</button>
            </div>
        </div>
    </div>
</div>