<ng-container *ngIf="!isServer else ssrBaner">
    <div class="banner-wrapper">
        <swiper *ngIf="topBanner" fxFlex="auto" [config]="config" (indexChange)="onIndexChange($event)" (slideChangeTransitionEnd)="checkForVideoInNextSlide($event)" (init)="checkForVideoInNextSlide($event)" #swiper>
            <ng-template swiperSlide *ngFor="let item of topBanner; let index = index; trackBy: trackByBannerItems">
                <div class="cursor-pointer">
                    <div fxLayout="column" fxLayoutAlign="center center" fxFlexFill>
                        <div class="picsum-img-wrapper mobile">
                            <img *ngIf="!item.mobileVideo" fetchpriority="high" alt="{{item.name}}" class="mobile-banner swiper-lazy" [attr.data-src]="item.forceOriginalImage ? item.originalMobileNewImage.url : item.mobileNewImage?.url" loading="lazy" appLazyLoad>
                            <video style="min-width: 100%;" *ngIf="item.mobileVideo" id="welcome-{{index}}" poster="{{item.forceOriginalImage ? item.originalMobileNewImage.url : item.mobileNewImage?.url}}" playsinline [muted]="'muted'" preload="none" loading="lazy">
                                <source [src]="getVideoLink(item.mobileVideo.url)" type="video/mp4">
                            </video>
                            <div *ngIf="item.buttonLink" class="carousel-caption mobile">
                                <div class="banner-text" fxLayoutAlign="center start" fxLayout="column">
                                    <div class="spacer"></div>
                                    <span class="caption-title" [innerHTML]="item.titleText"></span>
                                    <span class="caption-sub-title" [innerHTML]="item.description"></span>
                                </div>
                                <button round-button filled="empty" color="accent" (click)="openLink(item)" class="banner-btn">{{ this.isAuthenticated ? item.loggedButtonLabel : item.buttonLabel}}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </swiper>
    </div>
</ng-container>

<ng-template #ssrBaner>
    <ng-container *ngIf="!!topBanner">
        <div class="banner-wrapper">
            <div class="cursor-pointer">
                <div fxLayout="column" fxLayoutAlign="center center" fxFlexFill>
                    <div class="picsum-img-wrapper mobile">
                        <img fetchpriority="high" alt="{{topBanner[0].name}}" class="mobile-banner" [src]="topBanner[0].mobileNewImage?.url" loading="lazy">
                        <div *ngIf="topBanner[0].buttonLink" class="carousel-caption mobile">
                            <div class="banner-text" fxLayoutAlign="center start" fxLayout="column">
                                <div class="spacer"></div>
                                <span class="caption-title" [innerHTML]="topBanner[0].titleText"></span>
                            </div>
                            <button mat-stroked-button (click)="openLink(topBanner[0])" class="banner-btn" color="primary">{{ this.isAuthenticated ? topBanner[0].loggedButtonLabel : topBanner[0].buttonLabel}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</ng-template>
