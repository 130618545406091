import { Component } from '@angular/core';
import { gameTrx, searchGameTrx } from 'src/app/router-translation.labels';
import { TranslationConfig } from 'src/app/utils/translate-config';
import { BasePageComponent } from '../../base-page/base-page.component';

@Component({
  selector: 'app-desktop-category-menu',
  templateUrl: './desktop-category-menu.component.html',
  styleUrls: ['./desktop-category-menu.component.scss']
})
export class DesktopCategoryMenuComponent extends BasePageComponent {

  constructor( protected translationConfig: TranslationConfig) { super() }

  public gameTrx: string = this.translationConfig.getTranslation(gameTrx);
  public searchGameTrx = this.translationConfig.getTranslation(searchGameTrx); // public??

  ngOnInit(): void {
  }

}
