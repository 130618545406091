import { gql } from 'apollo-angular';

export const SEARCH_QUERY = gql`query GameSearchList($searchString: String!){
    games(first:30,where: {name_contains: $searchString}) {
      id
      name,
      gameid,
      hideGame,
      systemName,
      tag,
      thumbnail {
        url
      }
      thumbnailDesktop {
        url
      }
      thumbnailDesktopSquare {
        url
      }
      thumbnailDesktopRectangular {
        url
      }
      gameProvider{
          name
          providerExclusionTerritories{
            countryCode
            state
        }
      }
      animation{
        url
        fileName
      }
    }
    }
  `
