import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { BaseUsermgrService } from '../../http/base-usermgr.service';
import { CookieService } from 'ngx-cookie-service';
import { UserInfo } from './login.models';
import { LoginStatusService } from './login-status.service';
import { UserInfoService } from '../check-session/user-info.service';
import { FirebaseAuthService } from '../firebase/firebase-auth.service';
import { HOST_NAME } from 'src/app/app-routing.module';

@Injectable({
  providedIn: 'root'
})
export class LoginSmsVerificationService extends BaseUsermgrService {


  constructor(http: HttpClient, cookie: CookieService, private loginStatusService: LoginStatusService,
    private userInfoLoginService: UserInfoService,
    @Inject(LOCALE_ID) public locale: string,
    private faService: FirebaseAuthService,
    @Inject(HOST_NAME) public hostName
  ) {
    super(http, cookie, locale, hostName);
  }


  loginWithToken(token: string): Observable<UserInfo> {

    return this.faService.signWithToken(token)
      .pipe(
        switchMap((user) => {
          return this.userInfoLoginService.requestCheckUserInfo()
        })
      );
  }
}
