import { gql } from 'apollo-angular';

export const SEARCH_GAME_QUERY = gql`
query GameCategory($locale: Locale!, $searchString: String!, $categoriesIn: [String]!,  $skipNumber: String){
  gameCategories(locales: [$locale, en], where: {name_in: $categoriesIn}) {
    displayName
    displayType
    gameType
    game(where: { name_contains: $searchString},first: 100, after: $skipNumber) {
      name
      id
      gameid
      systemName
      thumbnail {
        url(transformation: {
          image: { resize: { width: 206, height: 206, fit: clip } }
        })
      }
      gameProvider {
        id
        name
      }
      gameCategory {
        id
        name
        displayName
      }
    }
  }
}
`

export const FILTERS_GAMES_QUERY = gql`query FrontPageGames($locale: Locale!, $countryName: [Countries!]){
pagesCategories(where: {AND: {regionName: front, country_contains_some:$countryName }},locales: [$locale, en]) {
    seo{
    metaTitle
    metaDescription
    }
    categories {
    name,
    id,
    displayName,
    url,
    icon{
        url
    },
    thumbnail{
        url
    },
    }
}
operators: pagesCategories(locales: [$locale, en],where: {AND: {regionName: front_operators, country_contains_some: $countryName }} ) {
    categories {
    name,
    displayName,
    gameType,
    thumbnailMobile {
        url(transformation: {
        image: { resize: { width: 300, height: 414, fit: clip } }
            document: { output: { format: webp } }
        })
      }
    }
  }
}
`

const STEP_SIZE = 80;

export const GAME_CATEGORY_PAGE_QUERY = gql`query GameCategory($locale: Locale!, $categoriesIn: [String]!, $skipNumber: String){
  gameCategories(locales: [$locale, en], where: {name_in: $categoriesIn}) {
    displayName
    displayType
    gameType
    game(first: 100, after: $skipNumber) {
      name
      id
      gameid
      licence
      systemName
      thumbnail {
        url(transformation: {
          image: { resize: { width: 206, height: 206, fit: clip } }
        })
      }
      gameProvider {
        id
        name
      }
      gameCategory {
        id
        name
        displayName
      }
    }
  }
}
`;