<ng-container *ngIf="!detectDeviceService.isDesktop() else desktop">
    <div *ngIf="notificationInfo?.numberNotSeen > 0"  matBadgeColor="accent"  matBadgeSize="small" [matBadge]="notificationInfo?.numberNotSeen" class="notification-indicator">
        <img (click)="openNotificationSheet()"  src="assets/icons/icon-notification.svg" />
    </div>
</ng-container>
<ng-template #desktop>
    <div [style.visibility]="notificationInfo?.numberNotSeen > 0 ? 'visible' : 'hidden'"  matBadgeColor="accent"  matBadgeSize="small" [matBadge]="notificationInfo?.numberNotSeen" class="desktop-notification-indicator">
        <div class="label" (click)="openNotificationSheet()" i18n>inbox</div>
    </div>
</ng-template>
