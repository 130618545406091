import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { LoginStatusService } from '../auth/login/login-status.service';
import { concatMap, delay, filter, map, take } from 'rxjs/operators';
import { UserChargeback, UserChargebackResponse } from './profile.models';
import { BaseFirebaseService } from '../http/base-firebase.service';
import { HttpClient } from '@angular/common/http';
import { CLAIM_USER_CHARGEBACK, USER_CHARGEBACK } from '../http/base-http.service';
import { LoggedStatus } from '../auth/login/login.models';
import { HOST_NAME } from 'src/app/app-routing.module';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireAuth } from '@angular/fire/compat/auth';

@Injectable({
  providedIn: 'root'
})
export class ChargebackService extends BaseFirebaseService {

  wallet: Observable<any>;
  constructor(
    http: HttpClient,
    afAuth: AngularFireAuth,
    afs: AngularFirestore,
    private loginStatusService: LoginStatusService,
    @Inject(HOST_NAME) public hostName
  ) { super(http, afAuth, afs, hostName); }

  private store = new BehaviorSubject<UserChargeback>(null);
  private storeClaim = new BehaviorSubject<UserChargeback>(null);

  public getChargeback(withDBSubscription: boolean = false, forceChargebackRefresh: boolean = false): Observable<UserChargebackResponse> {
    return this.loginStatusService.getLoginStatus().pipe(
      filter(user => user.isLogged === LoggedStatus.logged),
      concatMap(user => {
        if (forceChargebackRefresh && (this.store.getValue() !== null || this.storeClaim.getValue() !== null)) {
          return of({
            active: this.store.value,
            toClaim: this.storeClaim.value
          });
        } else {
          const backendCaller$ = this.get<UserChargeback[]>(USER_CHARGEBACK);
          // return dbCaller$.pipe(
          //   switchMap((resp) => {
          //     if (resp) {
          //       return of(resp);
          //     }
          //     else {
          //       return backendCaller$.pipe(
          //         delay(300),
          //         switchMap((resp) => {
          //           return dbCaller$.pipe(
          //           )
          //         }),
          //       )
          //     }
          //   }),
          return backendCaller$.pipe(
            delay(200),
            map(resp => {
              for (let item of resp) {
                if (typeof item.payoutDate === "string") {
                  item.convertedDate = new Date(item.payoutDate)
                }
                else {
                  // resp.convertedDate = resp.payoutDate.toDate()
                }
              }
              return resp
            }),
            map((resp) => {
              this.store.next(resp.find(item => !item.isToClaim));
              this.storeClaim.next(resp.find(item => item.isToClaim === true) || null);
              if (withDBSubscription) return {
                active: this.getDocumentDB<UserChargeback>(`backend-data-users/${user.username}/chargeback-users/${resp[0].chargebackUID}`),
                toClaim: resp.find(item => item.isToClaim === true)
              }
              else return {
                active: resp.find(item => item.isToClaim === false),
                toClaim: resp.find(item => item.isToClaim === true)
              }
            })
          )
        }
      }
    ))
  }

  claimChargeback(chargeback: UserChargeback) {
    return this.post<{ chargebackUID: string }, undefined>(CLAIM_USER_CHARGEBACK, { chargebackUID: chargeback.chargebackUID }).pipe(take(1))
  }

  updateStore() {
    this.getChargeback().subscribe(
      resp => {
        this.store.next(resp.active as UserChargeback);
        this.storeClaim.next(resp.toClaim as UserChargeback);
      },
      (error) => {
        console.log("catched error")
      }
    )
  }

  public getFromStore() {
    return this.store.asObservable();
  }

  public isHasCashbackToClaim() {
    return this.storeClaim.asObservable();
  }

}
