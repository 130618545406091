import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { BehaviorSubject, Observable, combineLatest, of } from 'rxjs';
import { LoginStatusService } from '../auth/login/login-status.service';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { BaseFirebaseService } from '../http/base-firebase.service';
import { HttpClient } from '@angular/common/http';
import { HOST_NAME } from 'src/app/app-routing.module';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { USER_CAMPAIGNS, USER_CAMPAIGNS_BONUS_CHECK, USER_CAMPAIGNS_MISSIONS_CHECK } from '../http/base-http.service';
import { CMSCampaignResponse, CampaignPrizeInfo, UserCampaign, UserCampaignInfo } from './campaigns.models';
import { Apollo, gql } from 'apollo-angular';

export const zip = (a, b) => a.map((k, i) => [k, b[i]]);


const query = gql`
  query ActiveCampaign($locale: Locale!, $name: String!) {
    campaign(where: { name: $name }, locales: [$locale, en]) {
      name
      campaignPrizes {
        cta
        ctaLink
        description
        displayName
        page {
          content {
            html 
          }
        }
        prizeEntry {
          ... on PrizeEntry {
            id
            games {
              gameid
              thumbnail {
                url
              }
            }
          }
        }
        desktopThumbnail {
          url
        }
        mobileThumbnail {
          url
        }
      }
    }
}`
@Injectable({
  providedIn: 'root'
})
export class UserCampaignService extends BaseFirebaseService {
  constructor(
    http: HttpClient,
    afAuth: AngularFireAuth,
    afs: AngularFirestore,
    private apollo: Apollo,
    private loginStatusService: LoginStatusService,
    @Inject(HOST_NAME) public hostName,
    @Inject(LOCALE_ID) public locale
  ) { super(http, afAuth, afs, hostName); }

  private _userCampaignInfo = new BehaviorSubject<UserCampaignInfo>(null);
  private isStoreInitialized = false;
  private _campaign = new BehaviorSubject<any>(undefined);


  public updateStoreUserCampaigns(): Observable<UserCampaign> {
    return this.get<UserCampaignInfo[]>(USER_CAMPAIGNS).pipe(
      filter(resp => {
        if (resp.length > 0) {
          return true
        }
        else {
          this._campaign.next(null);
          return false
        }
      }),
      switchMap(resp => {
        return combineLatest({
          campaignInfo: of(resp[0]),
          prizes: this.apollo
            .watchQuery<CMSCampaignResponse>({
              query: query,
              variables: {
                locale: this.locale,
                name: resp[0]?.campaign?.name,
              }
            })
            .valueChanges.pipe()
        }
        )
      }),
      map(resp => {
        this.isStoreInitialized = true;
        this._campaign.next({ ...resp.campaignInfo, ...resp.prizes.data.campaign?.campaignPrizes })
        return { ...resp.campaignInfo, ...resp.prizes.data.campaign?.campaignPrizes }
      })
    )
  }

  public getActiveCampaign() {
    if (!this.isStoreInitialized) {
      this.isStoreInitialized = true;
      return this.updateStoreUserCampaigns();
    }
    else {
      return this._campaign.asObservable().pipe()
    }
  }

  public isActiveCampaign() {
    return this._campaign.asObservable().pipe(
      filter(item => item !== undefined),
      map(item => !!item)
    )

  }


  public isUserEligibleCampaignBonuses(bonusList: string[]) {
    return this.post<{ bonus_refs: string[] }, boolean[]>(USER_CAMPAIGNS_BONUS_CHECK, { bonus_refs: bonusList }).pipe(
      take(1),
      map(item => {
        return zip(bonusList, item)
      })
    )
  }
  public isUserEligibleCampaignMissions(missionList: string[]) {
    return this.post<{ mission_refs: string[] }, boolean[]>(USER_CAMPAIGNS_MISSIONS_CHECK, { mission_refs: missionList }).pipe(
      take(1),
      map(item => {
        return zip(missionList, item)
      })
    )
  }
  public getActiveInfo() {
    return this.isStoreInitialized ? this._campaign.asObservable() : this.updateStoreUserCampaigns();
  }
}