import { Apollo } from 'apollo-angular';
import { Component, Inject, LOCALE_ID, PLATFORM_ID } from '@angular/core';
import { BasePageComponent } from '../../base-page/base-page.component';
import { GameCategory } from 'src/app/apollo/models/base-models';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { TranslationConfig } from 'src/app/utils/translate-config';
import { LICENCE } from 'src/app/app-routing.module';
import { DetectDeviceService } from 'src/app/services/utils/detect-device.service';
import { FrontPageGamesService } from 'src/app/services/page/front-games.service';
import { getMappedCategoryLink } from '../../../router-mapping';
import { ClonerService } from 'src/app/services/utils/clone-object.service';
import { isPlatformServer } from '@angular/common';
import { SearchGameService } from 'src/app/services/games/search-game.service';
import { searchGameTrx } from 'src/app/router-translation.labels';

@Component({
  selector: 'app-game-category-dialog',
  templateUrl: './game-category-dialog.component.html',
  styleUrls: ['./game-category-dialog.component.scss']
})
export class GameCategoryDialogComponent extends BasePageComponent {

  constructor(private apollo: Apollo,
    private router: Router,
    public detectDeviceService: DetectDeviceService,
    protected translationConfig: TranslationConfig,
    private frontPageGamesService: FrontPageGamesService,
    private cloneService: ClonerService,
    private searchGamesService: SearchGameService,

    @Inject(LICENCE) public licence: string,
    @Inject(LOCALE_ID) public locale: string,
    @Inject(PLATFORM_ID) private platformId,
  ) { super() }

  categories: GameCategory[];
  swiperCategories: GameCategory[];
  isServer: boolean = false;

  ngOnInit(): void {
    this.frontPageGamesService.fetchData().pipe(takeUntil(this.unsubscribe)).subscribe((response) => {
      this.categories = response.pagesCategories[0]?.categories;

      this.swiperCategories = this.categories.filter((item) => {
        const hasDisplayName = item.displayName && item.displayName.trim() !== '';
        if (!hasDisplayName) {
          //console.log('Removing item without displayName:', item);
        }
        return hasDisplayName;
      });
    });

    if (isPlatformServer(this.platformId)) {
      this.isServer = true;
    }

  }

  public redirectUrl: string = null;

  closeDialog(): void {

  }

  openCategory(category: GameCategory) {
    this.router.navigate([getMappedCategoryLink(category, this.translationConfig)]);
  }

  onFilterSelect(gameCategory: GameCategory) {
    this.searchGamesService.saveSearchHistory('');
    this.searchGamesService.setSearchSwiper(gameCategory.name);

    this.router.navigate(['/' + searchGameTrx]);
  }

}
