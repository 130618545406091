import { Inject, Injectable } from '@angular/core';
import { GameCategory, GameItem, GameWithPrividerExclusionTerritories } from 'src/app/apollo/models/base-models';
import { COUNTRY_ID, USER_REGION } from 'src/app/app-routing.module';
import { ClonerService } from './clone-object.service';

@Injectable({ providedIn: 'root' })
export class FilterGamesService {

    constructor(private cloneService: ClonerService,
        @Inject(COUNTRY_ID) public countryId: string,
        @Inject(USER_REGION) public userRegion: string) { }

    filterCategories(value: GameCategory[]) {
        let clonmedCategories = this.cloneService.deepClone<GameCategory[]>(value);
        let categories = []
        for (let category of clonmedCategories) {
            let clonedCategory = this.filterCategory(category);
            if (clonedCategory.game.length >= 3) categories.push(clonedCategory);
        };
        return categories;
    }

    filterCategory(category: GameCategory, gameType: string = null) {
        if (!!gameType && category.gameType !== gameType) return;
        let clonedCategory = this.cloneService.deepClone<GameCategory>(category);
        clonedCategory.game = []
        let games = this.filterGames(category.game);
        clonedCategory.game = games as GameItem[];
        return clonedCategory;
    }


    filterGames(games: { gameProvider?: GameWithPrividerExclusionTerritories }[]) {
        let filteredGames = games.filter(game => {
            const filterResult = !!game.gameProvider
                && (game.gameProvider.providerExclusionTerritories.length === 0
                    || (game.gameProvider.providerExclusionTerritories.length > 0
                        && game.gameProvider.providerExclusionTerritories.filter(exclusion => exclusion.countryCode.toLowerCase() === this.countryId.toLowerCase()).length == 0
                        && game.gameProvider.providerExclusionTerritories.filter(exclusion => {
                            if (!!exclusion.state && this.userRegion) {
                                if (exclusion.state.toLowerCase() === this.userRegion.toLowerCase()) return true;
                                else return false;
                            }
                            else {
                                return false;
                            }
                        }).length == 0
                    )
                )
            return filterResult;
        })
        return filteredGames;
    }



}
