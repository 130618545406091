import { Inject, Injectable, Optional } from '@angular/core';
import { RESPONSE } from '@nguniversal/express-engine/tokens';
import { Response } from 'express';

@Injectable({
    providedIn: 'root'
})
export class ServerSideResponseService {
    private response: Response

    constructor(@Optional() @Inject(RESPONSE) response: any) {
        this.response = response
    }

    setNotFound(message = 'not found'): this {
        if (this.response) {
            this.response.statusCode = 404
            this.response.statusMessage = message
        }
        return this
    }
}
