<div class="title-wrap">
  <app-common-page-title icon="assets/icons/icon-profile-header.svg"><ng-container i18n>Profile</ng-container></app-common-page-title>
</div>
<div class="profile-info">
  <!-- <div fxLayoutAlign="start center" fxLayout="row" class="title-wrap">
    <app-header-page-compoanent iconName="icon-profile-header.svg">Profile </app-header-page-compoanent>

  </div> -->
  <div class="content">
  <div class="profile-wrapper desktop" fxLayout="row" fxLayoutAlign="start center">
    <div fxFlex class="top-container" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
      <ng-container *ngIf="avatar">
        <img src="{{avatar}}" />
      </ng-container>
      <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="6px">
        <div style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis;" i18n>{{email}}</div>
        <div *ngIf="verified" class="verified" i18n>Verified</div>
      </div>
    </div>
    <div fxFlex="none" class="level-container" fxLayout="row" fxLayoutAlign="center center">
      <img src="assets/icons/lemons-levels/icon-lemon-level-{{lemonIconNameIndex}}.svg" alt="icon-gamification" />
      <div *ngIf="playerLoyalityName" class="label">{{playerLoyalityName}}</div>
    </div>
  </div>
  <div class="items">
    <div class="item-wrapper light">
      <div class="item">
        <div class="spinner-container" >
          <div class="spinner-background">
            <img *ngIf="isPrizeBoxActive && showGamification" src="assets/client-area/icon-box-on-80x80.png" alt="icon-gamification"/>
            <img *ngIf="!isPrizeBoxActive && showGamification" src="assets/client-area/icon-box-off-80x80.png" alt="icon-gamification" />
          </div>
          <mat-progress-spinner #spinnerlevel color="accent" mode="determinate" [value]="gamificationPercentFill" [diameter]="72" style="stroke-linecap: round;">
          </mat-progress-spinner>
        </div>
        <div class="text-container">
          <div class="title" i18n>Level {{gamificationLevel}}</div>
          <div *ngIf="isPrizeBoxActive" i18n>Collect your reward!</div>
          <div *ngIf="!isPrizeBoxActive" i18n>Level {{gamificationLevel}} reached in {{gamificationPercentFill.toFixed(2)}}%</div>
        </div>

          <div class="info">
            <a [routerLink]="treasureBoxInfoUrl" rel="noreferrer" routerLinkActive="active">
              <img src="assets/client-area/icon-info.svg" />
            </a>
          </div>
        </div>
        <button round-button filled="filled" color="yellow" (click)="openPrizeBox()" [disabled]="!isPrizeBoxActive" i18n>Collect!</button>
      </div>

      <div class="item-wrapper light">
        <div class="item">
          <div class="spinner-container">
            <div class="spinner-background">
              <img *ngIf="!userChargebackToClaim && showGamification" src="assets/client-area/icon-cashback-off-80x80.png" alt="icon-gamification" />
              <img *ngIf="!!userChargebackToClaim && showGamification" src="assets/client-area/icon-cashback-on-80x80.png" alt="icon-gamification" />
            </div>
            <mat-progress-spinner *ngIf="!userChargebackToClaim" #spinnerlevel color="accent" mode="determinate" [value]="chargebackPercentFill" [diameter]="74" style="stroke-linecap: round;">
            </mat-progress-spinner>
            <mat-progress-spinner *ngIf="!!userChargebackToClaim" #spinnerlevel color="accent" mode="determinate" [value]="100" [diameter]="74" style="stroke-linecap: round;">
            </mat-progress-spinner>
          </div>
          <div class="text-container">
            <div i18n>Cashback earned:</div>
            <div class="title" i18n>{{(!!userChargebackToClaim? userChargebackToClaim : userChargeback)?.value.toFixed(2)}} {{(!!userChargebackToClaim? userChargebackToClaim : userChargeback)?.currency}}</div>
            <div *ngIf="!userChargebackToClaim"><span i18n>Next payout in:</span>&nbsp;{{chargebackFormatted}}</div>
          </div>
          <div class="info">
            <a [routerLink]="cashbackInfoUrl" rel="noreferrer" routerLinkActive="active">
              <img src="assets/client-area/icon-info.svg">
            </a>
          </div>
        </div>
        <button round-button filled="filled" [disabled]="!userChargebackToClaim" color="warn" (click)="claimCashback()" i18n>Claim!</button>
      </div>

      <div class="item-wrapper light">
        <div class="item">
          <div class="icon-container">
            <img *ngIf="userBonusLotteryResponse" src="assets/client-area/icon-wheel-on.png" />
            <img *ngIf="!userBonusLotteryResponse" src="assets/client-area/icon-wheel-ff.png" />
          </div>
          <div class="text-container">
            <div class="title" i18n>Lemon Lottery</div>
            <div *ngIf="userBonusLotteryResponse" i18n>Claim your free ticket!</div>
            <div *ngIf="!userBonusLotteryResponse" i18n>No active lottery</div>
          </div>
          <div class="info">
            <a [routerLink]="lotteryInfoUrl" rel="noreferrer" routerLinkActive="active">
              <img src="assets/client-area/icon-info.svg">
            </a>
          </div>
        </div>
        <button round-button filled="filled" [disabled]="!userBonusLotteryResponse" color="yellow" (click)="openLotteryWheel()" i18n>Spin!</button>
      </div>


      <div class="item-wrapper dark">
        <div class="item">
          <div class="icon-container">
            <img src="assets/client-area/icon-mybonuses-box.png" />
          </div>
          <div class="text-container">
            <div class="title" i18n>My Bonuses</div>
            <div i18n>Manage your bonuses</div>
          </div>
        </div>
        <button round-button filled="empty" color="main" i18n [routerLink]="bonusesUrl" routerLinkActive="active">Proceed</button>
      </div>
    </div>
    <div class="divider">
      <mat-divider style="border-top-color: var(#2D5C59) !important; border-top-width: 2px;"></mat-divider>
    </div>

    <div class="main-wrap">
      <div fxLayout="row" fxLayoutAlign="space-between center" class="my-games-wrapper wrapper">
        <div>
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8">
            <img src="assets/icons/categories/icon-my-games.svg">
            <div class="title" i18n>My games</div>
          </div>
          <div class="description" i18n>Your games collection</div>
        </div>
        <button round-button size="small" class="capitalize-button button" filled="empty" color="main" [routerLink]="myGamesUrl" routerLinkActive="active" i18n>Proceed</button>
      </div>


      <div fxLayout="row" fxLayoutAlign="space-between center" class="edit-profile-wrapper wrapper">
        <div>
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8">
            <img src="assets/client-area/icon-menu-edit-profile.svg">
            <div class="title" i18n>Edit profile</div>
          </div>
          <div class="description" i18n>Edit your profile picture and other data</div>
        </div>
        <button round-button size="small" class="capitalize-button button" filled="empty" color="main" [routerLink]="editProfileUrl" routerLinkActive="active" i18n>Proceed</button>
      </div>

      <div fxLayout="row" fxLayoutAlign="space-between center" class="edit-profile-wrapper wrapper">
        <div>
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8">
            <img src="assets/client-area/icon-menu-change%20password.svg">
            <div class="title" i18n>Change password</div>
          </div>
          <div class="description" i18n>Manage security of your account</div>
        </div>
        <button round-button size="small" class="capitalize-button button" filled="empty" color="main" [routerLink]="changePasswordUrl" routerLinkActive="active" i18n>Proceed</button>
      </div>
    </div>
  </div>
</div>