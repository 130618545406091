import { Component } from '@angular/core';
import { BasePageComponent } from '../../base-page/base-page.component';

@Component({
  selector: 'app-desktop-expansion-panel',
  templateUrl: './desktop-expansion-panel.component.html',
  styleUrls: ['./desktop-expansion-panel.component.scss']
})
export class DesktopExpansionPanelComponent extends BasePageComponent {

  constructor() { super() }

  isReadMore: boolean = true;
  height: any = '145px';
  showMoreTrx = $localize`:@@button-show-more:Read more`;
  showLessTrx = $localize`:@@button-show-less:Read less`;

  ngOnInit(): void {
  }

  toggleData() {
    this.isReadMore = !this.isReadMore
    if (!this.isReadMore) {
      this.height = '100%'
    } else {
      this.height = '145px'
    }
  }

}
