<div>
  <div class="title">
    <img src="assets/icons/icon_categories_32_32.svg"/>
    <div i18n>
      Categories
    </div>
  </div>
  <div class="cards">
    <div class="card" (click)="openCategory(item)" *ngFor="let item of categories ">
        <img src="{{item.thumbnail?.url}}" alt="{{item.name}}" />
        <div class="name" [innerHTML]="item.displayName | safeHtml"></div>
    </div>
  </div>
</div>
