<div class="footer">
    <div class="top-footer" fxLayout="column" fxLayoutAlign="start center">
        <div class="footer-link-grid" fxLayout="row wrap" fxLayoutGap="20px" fxLayoutAlign="center center">
            <div *ngFor="let item of childData?.footer?.footerLinks">
                <div *ngIf="deviceDetectService.isDesktop()"><a [topDrawerRouterLink]="[item.category, item.localizedName]">{{ item.displayName }}</a></div>
                <div *ngIf="!deviceDetectService.isDesktop()"><a [routerLink]="[item.category, item.localizedName]">{{ item.displayName }}</a></div>
            </div>
            <div class="exteranl-links" *ngFor="let item of childData?.footer?.footerExternalLinks">
                <div><a target="_blank" rel="noreferrer" href="{{item?.url}}">{{ item.name }}</a></div>
            </div>
        </div>
        <div class="language-wrap">
            <mat-form-field *ngIf="selectedLanguage" appearance="outline" class="select-language">
                <mat-select panelClass="m-language-overlay-footer language-overlay" [value]="selectedLanguage?.name" (selectionChange)="setCookie($event)">
                    <mat-select-trigger class="selected-container">
                        <img [src]="selectedLanguage?.flag?.url">
                        <span>{{selectedLanguage.fullName}}</span>
                        <mat-icon matSuffix>arrow_forward_ios</mat-icon>
                    </mat-select-trigger>
                    <mat-option *ngFor="let item of childData?.languages" [value]="item?.name">
                        <a href="/{{item?.name}}/">
                            <img class="select-img" [src]="item?.flag.url">
                            <span>{{item.fullName}}</span>
                        </a>
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div class="payment-options logo-wrap">
        <h5 i18n>Our Payment Options</h5>
        <div class="logo-list" fxLayout="row" fxLayoutAlign="center center">
            <img src="assets/india/icon-payments/icon-payment-in-inb.svg" />
            <img src="assets/india/icon-payments/icon-payments-bank-in.svg" />
            <img src="assets/india/icon-payments/icon-payments-imps.svg" />
            <img src="assets/india/icon-payments/icon-payments-paytm.svg" />
            <img src="assets/india/icon-payments/icon-payments-phonepe.svg" />
            <img src="assets/india/icon-payments/icon-payments-upi.svg" />
            <img src="assets/india/icon-payments/icon-payments-upi-vpa.svg" />
        </div>
    </div>
    <div class="middle-footer" fxLayout="column" fxLayoutAlign="center center">
        <div>{{childData?.footer?.licenceDescription}}</div>
        <div class="exteranl-links" *ngIf="affiliateUrl">
            <div><a target="_blank" rel="noreferrer" href="{{affiliateUrl}}" i18n>Affiliate Program</a></div>
        </div>
        <div class="licence-logo" fxLayoutAlign="center center" fxLayout="row">
            <img *ngFor="let item of childData?.footer?.licenceLogo" alt="{{item?.name}}" src="{{item?.url}}" />
            <a *ngFor="let item of childData?.footer?.imageWithLinks" target="_blank" rel="nofollow" href="{{item?.url}}"><img alt="{{item?.name}}" src="{{item?.image.url}}" /></a>
        </div>
    </div>

</div>