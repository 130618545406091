import { ChangeDetectionStrategy, Component, Inject, PLATFORM_ID, QueryList, ViewChildren } from '@angular/core';
import { BasePageComponent } from '../../base-page/base-page.component';
import { TranslationConfig } from 'src/app/utils/translate-config';
import { isPlatformServer } from '@angular/common';
import { DesktopFrontPageGamesComponent } from '../front-page-games/front-page-games.component';
import { DESKTOP_RECTANGULAR_DISPLAY, GameCategory } from 'src/app/apollo/models/base-models';
import { gameTrx, liveCasinoTrx } from 'src/app/router-translation.labels';
import { getMappedCategoryLink } from '../../../router-mapping';
import { Router } from '@angular/router';
import { LicenceService } from 'src/app/services/utils/licence.service';

type GameCategoryWithRows = GameCategory | { rows: number };

@Component({
  selector: 'app-desktop-game-category-short',
  templateUrl: './game-category-short.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./game-category-short.component.scss']
})
export class DesktopGameCategoryShortComponent extends BasePageComponent {

  constructor(
    @Inject(PLATFORM_ID) private platformId,
    protected translationConfig: TranslationConfig,
    private router: Router,
    protected licenceService: LicenceService
  ) { super(); }

  @ViewChildren('frontPageGames') frontPageGames: QueryList<DesktopFrontPageGamesComponent>;

  public readonly gameTrx: string = this.translationConfig.getTranslation(gameTrx);

  isServer: boolean;
  categories: GameCategoryWithRows[];

  // @Input() set inputCategories(data: GameCategory[]) {
  //   this._data = data;
  //   this.assignNumberOfRows();
  // }

  get inputCategories() {
    return this._data;
  }

  assignNumberOfRows() {
    this.categories = this.inputCategories?.map((category) => {
      const gamesPerRow = category.displayType === DESKTOP_RECTANGULAR_DISPLAY ? 6 : 5;
      const numberOfRows = Math.max(1, Math.floor(category.numberGamesFrontPageDesktop / gamesPerRow));
      return { ...category, rows: numberOfRows };
    })
    console.log(this.inputCategories)
  }

  ngOnInit(): void {
    this.isServer = isPlatformServer(this.platformId);
  }

  prev(index) {
    this.frontPageGames.get(index).swipeLeft();
  }

  next(index) {
    this.frontPageGames.get(index).swipeRight();
  }

  onCategoryClick(category: GameCategory) {
    if (this.router.url.includes(this.translationConfig.getTranslation(liveCasinoTrx)) ||
      category.name === this.translationConfig.getTranslation(this.translationConfig.getTranslation(liveCasinoTrx))) {
      return [getMappedCategoryLink(category, this.translationConfig, false)];
    }

    return [getMappedCategoryLink(category, this.translationConfig)];
  }
}
