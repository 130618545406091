<ng-container *ngIf="!detectDeviceService.isDesktop() else desktop">
    <app-login-button [isLoading]="isLoading" *ngIf="!isLoading && !isLogged"></app-login-button>
    <div *ngIf="!isLoading && isLogged" class="balance-user-wrapper">
        <app-balance-user></app-balance-user>
    </div>
    <!-- <button *ngIf="isLoading" mat-button class="mock-button spinner"><span i18n>log in</span></button> -->
</ng-container>

<ng-template #desktop>
    <app-login-desktop *ngIf="!isLoading && !isLogged"></app-login-desktop>
    <app-balance-user *ngIf="!isLoading && isLogged"></app-balance-user>
</ng-template>