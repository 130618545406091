<div class="top-footer" fxLayout="column" fxLayoutAlign="center center" *ngIf="data">
    <div class="fixed-width limitTextHeight expansion-panel" [style.height]="height">
        <ng-container *ngFor="let item of data">
            <h4 *ngIf="item?.title">{{item.title}}</h4>
            <div class="info panel-content" i18n [innerHtml]="item?.content.safehtml"></div>
        </ng-container>
    </div>
    <div class="read-more">
        <a class="round-btn" (click)="toggleData()">
            <span>{{ isReadMore ? showMoreTrx: showLessTrx }}</span>
            <mat-icon [class]="isReadMore?'rotate-90':'rotate-270'">arrow_forward_ios</mat-icon>
        </a>
    </div>
</div>