<div id="breadcrumbs-desktop" class="breadbrumbs-wrapper" fxLayoutAlign="start center" fxLayout="row">
  <ul class="breadcrumb">
    <li *ngFor="let breadcrumb of breadcrumbs;let last=last ">
      <ng-container *ngIf="!last else nonactivelink">
        <a *ngIf="breadcrumb.url else nonactivelink" [routerLink]="breadcrumb.url">{{ breadcrumb.label }}</a>
      </ng-container>
      <ng-template #nonactivelink>
        <span>{{ breadcrumb.label }}</span>
      </ng-template>
    </li>
  </ul>
</div>