
export interface GameRankEntry {
    uid: string
    totalRounds: number
}

export interface GameRanking {
    games: GameRankEntry[]
}

export interface Prize {
    prizeType: string
    systemLabel: string
    displayName: string
}

export interface PrizeBox {
    isOpen: boolean
    prize?: Prize
    index: number
}



export interface PrizeBoxesLevelLottery {
    prizeBoxes?: PrizeBox[]
    userLevelRef: string
    isActive: boolean
    leftToOpen?: number
    isSelectionPrizeVolatilityType?: boolean
    selectionPrizeBoxVolatilityNames?: string[]
    uid: string
}

export interface WheelLotteryinfo extends PrizeBoxesLevelLottery {
}

export interface LotteryLevelPrize {
    name: string;
    assetName: string;
    systemLabel: string;
}

export interface RedeemCodeResponse {
    status: string
    bonus?: {
        amount: number,
        currency: string,
        bonus_ref: string
    }
    freespin?: {
        code: string
    }
}

export const REDEEM_CODE_STATUS_VERIFY = "requires_sms_verification";
