<div class="top-menu-wrapper lato">
    <div *ngIf="!isLogin else logged" class="desktop-top-menu" fxLayout="row" fxLayoutAlign="space-evenly center">
        <a [routerLink]="slots" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}" i18n>Slots</a>
        <!-- <a [routerLink]="arcadeUrl" routerLinkActive="active" i18n>Arcade</a> -->
        <a [routerLink]="aviatorUrl" routerLinkActive="active" i18n>Aviator</a>
        <a [routerLink]="liveCasinoUrl" routerLinkActive="active" i18n>Live Casino</a>
        <!-- <a [routerLink]="promotionsTrx" routerLinkActive="active" i18n>Promotions</a> -->
        <!-- <a [topDrawerRouterLink]="[lostPasswordTrx]" i18n>lost password</a> -->
    </div>
</div>
<ng-template #logged>
    <div class="desktop-top-menu lato" fxLayout="row" fxLayoutGap="30px" fxLayoutAlign="space-evenly center">
        <a *ngIf="isInGame" [routerLink]="['',slots]" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}" i18n>Slots</a>
        <a *ngIf="!isInGame" [routerLink]="['']"><img class="inline-logo" alt="inline-logo" src="assets/pages/promobb/logo-casino-white.svg" /></a>
        <!-- <a [routerLink]="arcadeUrl" routerLinkActive="active" i18n>Arcade</a> -->
        <a [routerLink]="aviatorUrl" routerLinkActive="active" i18n>Aviator</a>
        <a [routerLink]="liveCasinoUrl" routerLinkActive="active" i18n>Live Casino</a>
        <!-- <a [routerLink]="prifileTrx" i18n>profile</a> -->
    </div>
</ng-template>