import { Component, Input } from '@angular/core';
import { BaseComponent } from '../base/base.component';
import { DESKTOP_SMALL, DialogConfig, TEST_SUCCESS_DIALOG_CONFIG } from '../dialog/dialog.config';
import { MatDialog } from '@angular/material/dialog';
import { ErrorDialogComponent } from '../dialog/error-dialog/error-dialog.component';
import { SuccessDialogComponent } from '../dialog/success-dialog/success-dialog.component';
import { takeUntil } from 'rxjs/operators';
import { ConfirmDialogComponent } from '../dialog/confirm-dialog/confirm-dialog.component';
import { DetectDeviceService } from 'src/app/services/utils/detect-device.service';

@Component({
  selector: 'app-base-page',
  templateUrl: './base-page.component.html',
  styleUrls: ['./base-page.component.scss']
})
export class BasePageComponent extends BaseComponent {

  protected _data: any;
  protected currentPage: number = 1;

  @Input()
  set data(dataInput: any) {
    this._data = dataInput;
  }

  get data(): any { return this._data; }

  constructor() { super() }

  ngOnInit(): void {}

  protected setDefault
  isDesktop: boolean = false;
}

export type ConfirmationResponse = {
  isConfirmed: boolean;
  data: any;
}

@Component({
  template: '',
})
export class BasePageComponentWithDialogs extends BasePageComponent {

  constructor(public errorDialog: MatDialog,
    private deviceDetectService: DetectDeviceService
  ) { super() }


  ngOnInit(): void {
  }

  openErrorDialog(msg?: string, title?: string, callback?: Function): void {
    //const size: DialogConfig = DESKTOP_SMALL;
    const size: DialogConfig = TEST_SUCCESS_DIALOG_CONFIG;
    const dialogRef = this.errorDialog.open(ErrorDialogComponent, {
      ...size,
      backdropClass: 'blur-backdrop',
      //panelClass: ["transparent", "alert-panel"],
      panelClass: ["transparent", "dialog-card-wrapper"],
      data: { msg: msg, title: title }
    });
    if(callback) {
      dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe)).subscribe(
        () => {
          callback();
        }
      );
    }
  }

  openSuccessDialog(msg?: string, title?: string, callback?: Function, additionalButtonLink?: string): void {
    //const size: DialogConfig = DESKTOP_SMALL;
    const size: DialogConfig = TEST_SUCCESS_DIALOG_CONFIG;
    const dialogRef = this.errorDialog.open(SuccessDialogComponent, {
      ...size,
      backdropClass: 'blur-backdrop',
      //panelClass: ["transparent", "alert-panel"],
      panelClass: ["transparent", "dialog-card-wrapper"],
      data: { msg: msg, title: title, additionalButtonLink: additionalButtonLink }
    });
    if(callback) {
      dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe)).subscribe(
        () => {
          callback();
        }
      );
    }
  }

  openConfirmDialog(msg: string, dataToConfrim: any, callback: Function): void {
    //const size: DialogConfig = DESKTOP_SMALL;
    const size: DialogConfig = TEST_SUCCESS_DIALOG_CONFIG;
    const dialogRef = this.errorDialog.open(ConfirmDialogComponent, {
      ...size,
      backdropClass: 'blur-backdrop',
      //panelClass: ["transparent", "alert-panel"],
      panelClass: ["transparent", "dialog-card-wrapper"],
      data: { msg: msg, dataRefToConfirm: dataToConfrim }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe)).subscribe(
      (resp: ConfirmationResponse) => {
        if (resp && resp.isConfirmed) {
          this.confirm(resp.data);
          callback();
        }
      }
    );
  }

  confirm(data: any) { }
}



