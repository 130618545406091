import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { SwiperModule } from 'swiper/angular';
import { CommonModule } from '@angular/common';
import { SharedServicesModule } from '../shared-services/shared-services.module';
import { SharedComponentsModule } from '../shared-components/shared-components.module';
import { DesktopMenuModule } from '../desktop-menu/desktop-menu.module';
import { MOBILE_OPERATORS_MODULE_NOT_TRANSLATED } from './mobile-operators-routes';
import { GameOperatorCategoryComponent } from 'src/app/components/front-page/operators-category/operator.component';

@NgModule({
  imports: [
    RouterModule.forChild(MOBILE_OPERATORS_MODULE_NOT_TRANSLATED),
    SwiperModule,
    CommonModule,
    SharedServicesModule,
    SharedComponentsModule,
    DesktopMenuModule
  ],
  declarations: [
    GameOperatorCategoryComponent,
  ],
  exports: [
    GameOperatorCategoryComponent,
  ],
})
export class MobileOperatorsModule {
}
