<div *ngIf="hasPrizes else noprizes">
    <img class="box" src="assets/icons/icon-treasure-box-closed.png" />
</div>

<ng-template #noprizes>
    <div class="item" fxLayout="column" fxLayoutAlign="start center">
        <div class="thumbnail">
            <img class="avatar" src="{{avatar}}" />
        </div>
        <div class="name" i18n>profile</div>
    </div>
</ng-template>