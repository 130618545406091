<ng-container *ngIf="teaser">
    <ng-container *ngIf="isShown && !isClosedByUser">
        <div class="teaser-container" fxLayout="row" fxLayoutGap="9px" fxLayoutAlign="start center">
            <!-- <div (click)="openUrl()" fxLayoutAlign="center center" class="rectangle">
                <div *ngIf="teaserName === 'christmascalendar'" class="teser-christmas">
                    <img src="assets/icons/{{teaser.iconName}}" />
                </div>
                <div *ngIf="!isIconFromAssets" class="teser-image"></div>
            </div> -->
            <ng-container [ngSwitch]="teaserName">
                <ng-container *ngSwitchCase="'bonusFirstDepositWithDate'">
                    <ng-container *ngTemplateOutlet="firstdepositbonus"></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'activeCampaign'">
                    <ng-container *ngTemplateOutlet="activeCampaign"></ng-container>
                </ng-container>
                <div *ngSwitchDefault (click)="openUrl()" class="default rectangle">
                    <div class="teser-image"></div>
                    <p>{{teaser.msg}}</p>
                </div>
            </ng-container>
            <!-- <mat-icon fxFlex="6" (click)="openUrl()" class="open">arrow_forward_ios</mat-icon> -->
            <mat-icon *ngIf="isClosable" (click)="close()">close</mat-icon>
        </div>
    </ng-container>
</ng-container>

<ng-template #firstdepositbonus>
    <p (click)="openUrl()">{{teaser.msg}} <span class="counter">{{countDown?.minutes}}:{{countDown?.seconds}}</span></p>

</ng-template>

<ng-template #christmasteser>
    <div class="teaser-container slider-content">
        <div class="rectangle">
            <img src="assets/icons/{{teaser.iconName}}">
            <p>{{teaser.msg}}</p>
        </div>
        <mat-icon role="img" routerLink="{{teaser.url}}">arrow_forward_ios</mat-icon>
    </div>
</ng-template>

<ng-template #activeCampaign>
    <div (click)="scrollToCampaigns()" class="active-campaign">
        <div class="rectangle">
            <img src="assets/reactivation/lemon-switch-on-64.svg">
            <p>{{teaser.msg}}</p>
        </div>
        <img src="assets/reactivation/down-arrow.png" class="arrow-down">
    </div>
</ng-template>

<swiper [config]="config" class="teaser-swiper" *ngIf="false">
    <ng-template swiperSlide *ngFor="let teaser of multiTeaser.teaser">
        <div class="teaser-container slider-content">
            <div class="rectangle">
                <img src="assets/icons/{{teaser.iconName}}">
                <p>{{teaser.msg}}</p>
            </div>
            <mat-icon role="img" routerLink="{{teaser.url}}">arrow_forward_ios</mat-icon>
        </div>
    </ng-template>
</swiper>