import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { loginTrx, signUpTrx } from 'src/app/router-translation.labels';
import { LoginEmailService } from 'src/app/services/auth/login/login-email.service';
import { DetectDeviceService } from 'src/app/services/utils/detect-device.service';
import { OpenDesktopDrawerService } from 'src/app/services/utils/open-desktop-drawer.service';
import { TranslationConfig } from 'src/app/utils/translate-config';

@Component({
  selector: 'app-login-desktop',
  templateUrl: './login-desktop.component.html',
  styleUrls: ['./login-desktop.component.scss']
})
export class LoginDesktopComponent implements OnInit {

  constructor(
    public dialog: MatDialog,
    public loginService: LoginEmailService,
    public formBuilder: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    public openDrawerService: OpenDesktopDrawerService,
    protected translationConfig: TranslationConfig,
    protected detectDesktopService: DetectDeviceService,
    public errorDialog: MatDialog,
  ) { }

  readonly loginLabel: string = $localize`:@@login-desktop-label:E-mail`;
  readonly passwordLabel: string = $localize`:@@password-desktop-label:Password`;
  readonly singupUrl = this.translationConfig.getTranslation(signUpTrx);


  ngOnInit(): void {
  }

  login() {
    this.router.navigate(['', loginTrx], { queryParams: { redirectTo: this.router.url } });
  }
  signup() {
    this.router.navigate(['', this.singupUrl]);
  }

}
