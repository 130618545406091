import { Directive, ElementRef, Renderer2, AfterViewInit } from '@angular/core';

@Directive({
  selector: '[appInsertBrBetweenWords]'
})
export class InsertBrBetweenWordsDirective implements AfterViewInit {

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  ngAfterViewInit() {
    const text = this.el.nativeElement.innerText;
    const words = text.split(' ');
    this.renderer.setProperty(this.el.nativeElement, 'innerHTML', words.join('<br>'));
  }
}
