<div *ngIf="!detectDeviceService.isDesktop() else desktop" class="login-button" fxLayout="row" fxLayoutAlign="center center" [routerLink]="paymentUrl">
  <div class="balance-container" fxLayout="row" fxLayoutGap="25px" fxLayoutAlign="start center">
    <div class="mobile-balance"><strong>{{balanceInfo?.balance}}</strong><ng-container *ngIf="balanceInfo?.bonus_balance > 0"> <br> + {{balanceInfo?.bonus_balance}}</ng-container> {{balanceInfo?.currency}}</div>
    <div class="add-icon mobile" fxLayoutAlign="center center" fxLayout="row">
      <img src="assets/icons/icon-add.svg" />
    </div>
  </div>
</div>

<ng-template #desktop>
  <div class="login-button desktop" fxLayout="row" fxLayoutAlign="center center" [routerLink]="paymentUrl">
    <div class="balance-container" fxLayout="row" fxLayoutGap="25px" fxLayoutAlign="start center">
      <div class="desktop-balance"><strong>{{balanceInfo?.balance}}</strong><ng-container *ngIf="balanceInfo?.bonus_balance > 0"> <br> + {{balanceInfo?.bonus_balance}}</ng-container> {{balanceInfo?.currency}}</div>
      <div class="add-icon" fxLayoutAlign="center center" fxLayout="row">
        <img src="assets/icons/icon-add.svg" />
      </div>
    </div>
  </div>
</ng-template>