import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SET_LIMIT } from '../http/base-http.service';
import { CookieService } from 'ngx-cookie-service';
import { BaseWalletService } from '../http/base-wallet.service';
import { HOST_NAME } from '../../app-routing.module';
import { LimitsResponse } from './profile.models';
import { UntypedFormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ResponsibleGamingService extends BaseWalletService {


  constructor(http: HttpClient, cookie: CookieService, @Inject(LOCALE_ID) public locale: string, @Inject(HOST_NAME) public hostName) {
    super(http, cookie, locale, hostName);
  }

  public getLimitsValues(): Observable<LimitsResponse> {
    return this.get<any>(SET_LIMIT)
  }

  public setLimits(form: UntypedFormGroup): Observable<any> {
    return this.postRequest(SET_LIMIT, form)
  }
}
