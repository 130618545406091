import { Inject, Injectable, signal } from '@angular/core';
import { BehaviorSubject, EMPTY, Observable, of } from 'rxjs';
import { LoginStatusService } from '../auth/login/login-status.service';
import { catchError, concatMap, delay, distinctUntilChanged, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { UserGamificationLevel } from './profile.models';
import { BaseFirebaseService } from '../http/base-firebase.service';
import { HttpClient } from '@angular/common/http';
import { USER_GAMIFICATION } from '../http/base-http.service';
import { LoggedStatus } from '../auth/login/login.models';
import { HOST_NAME } from 'src/app/app-routing.module';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { RandomUUIDOptions } from 'crypto';
import { ComponentComunicationService, GAME_ACTION_LAUNCH, GAME_ACTION_QUIT } from '../communication/component-comunication.service';
import { PrizeUser, GamificationState } from './edit-profile.models';

@Injectable({
  providedIn: 'any'
})
export class GamificationLevelService extends BaseFirebaseService {

  wallet: Observable<any>;
  constructor(
    http: HttpClient,
    afAuth: AngularFireAuth,
    afs: AngularFirestore,
    private loginStatusService: LoginStatusService,
    private componentComunicationService: ComponentComunicationService,
    private loginStatus: LoginStatusService,
    @Inject(HOST_NAME) public hostName
  ) {
    super(http, afAuth, afs, hostName);
    console.log('GamificationLevelService INIT', this.id);

    this.refreshGamificationState();

    this.componentComunicationService.getMessages().pipe(
      distinctUntilChanged(),
    ).subscribe(
      (resp) => {
        switch (resp) {
          case GAME_ACTION_QUIT:
            if (this.gamificationArchive.value) {
              this.getGamification().pipe(
                take(1)
              ).subscribe(
                () => {
                  this.animateGamificationProgress.set(true);
                }
              )
            }
            break;
          case GAME_ACTION_LAUNCH:
            if (!this.gamificationArchive.value) {
              this.saveGamificationBeforeGameRun();
            }
            break;
        }
      }
    )
  }

  private store = new BehaviorSubject<UserGamificationLevel>(null);
  private gamificationArchive = new BehaviorSubject<UserGamificationLevel>(null);
  animateGamificationProgress = signal(false);
  public id: number = Math.random();

  public getStore() {
    return this.store.asObservable().pipe(
      filter(item => item !== null),
    )
  }

  public getGamificationFromStore() {
    return this.store.asObservable();
  }

  public getGamification(withDBSubscription: boolean = false, forceGamificationLevelRefresh: boolean = false): Observable<UserGamificationLevel> {
    return this.loginStatusService.getLoginStatus().pipe(
      filter(user => user.isLogged === LoggedStatus.logged),
      concatMap(user => {
        if (forceGamificationLevelRefresh && this.store.getValue() !== null) {
          return this.store.asObservable();
        } else {
          const backendCaller$: Observable<UserGamificationLevel> = this.get<UserGamificationLevel>(USER_GAMIFICATION);
          return backendCaller$.pipe(
            delay(200),
            filter(resp => !!resp),
            switchMap((resp) => {
              if (withDBSubscription) {
                return this.getDocumentDB<UserGamificationLevel>(`backend-data-users/${user.username}/gamification-users/${resp.gamificationReference}`);
              }
              else {
                this.store.next(resp)
                return of(resp)
              }
            }),
          )
        }
      })
    )
  }

  public saveGamificationBeforeGameRun() {
    this.getGamification().pipe(
      filter(item => item !== null),
      take(1)
    ).subscribe(
      (resp) => {
        this.addGamificationState({
          levelNumber: resp.levelNumber,
          turnover: resp.turnover,
          levelEndsTurnover: resp.levelEndsTurnover
        })
        this.gamificationArchive.next(resp);
      }
    )
  }

  public getArchivedValue() {
    return this.gamificationArchive.value;
  }

  public getCurrentValue() {
    return this.store.value;
  }

  public cleanArchivedValue() {
    this.animateGamificationProgress.set(false);
    this.gamificationArchive.next(null);
    this.addGamificationState(null);
  }

  public refreshGamificationState() {
    return this.loginStatus.getIfUserLogged().pipe(
      switchMap(
        (status) => {
          return this.getDocumentDB<PrizeUser>(`prize-users/${status.username}`).pipe(
            filter(resp => !!resp),
            map((resp: PrizeUser) => resp.gamificationState)
          );
        }
      ),
      filter(resp => !!resp),
      catchError(error => {
        console.log(error);
        return EMPTY;
      }),
      take(1),
    ).subscribe((resp: GamificationState) => {
      if (!this.gamificationArchive.value) {
        this.gamificationArchive.next(
          {
            levelNumber: resp.levelNumber,
            turnover: resp.turnover,
            levelEndsTurnover: resp.levelEndsTurnover,
            gamificationReference: ""
          }
        )
        this.getGamification().pipe(
          take(1)
        ).subscribe(
          () => {
            this.animateGamificationProgress.set(true);
          }
        )

      }
    });
  }

  public addGamificationState(state: GamificationState) {
    this.loginStatus.getLoginStatus().pipe(
      filter((status) => status.isLogged === LoggedStatus.logged),
      take(1),
      map((userInfo) => userInfo.username),
    ).subscribe((username) => {
      this.setDocument<{ gamificationState: GamificationState }>({ gamificationState: state }, `prize-users/${username}`, true);
    });
  }
}
