import { Apollo } from 'apollo-angular';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, LOCALE_ID, PLATFORM_ID, ViewChild } from '@angular/core';
import { BasePageComponent } from '../base-page/base-page.component';
import { SetMainBackgroundService } from 'src/app/services/desktop/set-main-background.service';
import { filter, takeUntil } from 'rxjs/operators';

import { FRONT_PAGE_TOP_BANNER } from 'src/app/apollo/front-page-games/front-page-games';
import { Banner, BannerItem } from 'src/app/apollo/models/base-models';
import { DetectDeviceService } from 'src/app/services/utils/detect-device.service';
import { TranslationConfig } from 'src/app/utils/translate-config';
import { ActivatedRoute, Router } from '@angular/router';
import { isPlatformServer } from '@angular/common';
import { LoginStatusService } from 'src/app/services/auth/login/login-status.service';
import { ClonerService } from 'src/app/services/utils/clone-object.service';
import SwiperCore, { Autoplay, SwiperOptions } from 'swiper';

SwiperCore.use([Autoplay]);
interface TranslatedBannerItem extends BannerItem {

}

@Component({
  selector: 'app-top-banner',
  templateUrl: './top-banner.component.html',
  styleUrls: ['./top-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TopBannerComponent extends BasePageComponent {

  static IDENTIFIER: string = "top_banner";
  static DELAY_BEFORE_FIRST_VIDEO = 60000;

  showNavigationArrows = false;
  topBanner: BannerItem[];
  isAuthenticated: boolean;
  isServer: boolean;


  config: SwiperOptions = {
    direction: 'horizontal',
    scrollbar: false,
    watchSlidesProgress: true,
    navigation: false,
    keyboard: true,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
    centeredSlides: true,
    loop: true,
    roundLengths: true,
    spaceBetween: 0,
    on: {
      slideChangeTransitionEnd: this.onIndexChange,
    },
    lazy: true,
    preloadImages: false,
  };
  isFirstVideoShown = false;

  constructor(
    private setMainBackgroundService: SetMainBackgroundService,
    public detectDeviceService: DetectDeviceService,
    private cd: ChangeDetectorRef,
    private apollo: Apollo,
    private router: Router,
    private route: ActivatedRoute,
    private translationConfig: TranslationConfig,
    private loginStatusService: LoginStatusService,
    private cloneService: ClonerService,
    @Inject(PLATFORM_ID) private platformId,
    @Inject(LOCALE_ID) public locale: string,
  ) { super(); }

  displayBanner() {
    this.apollo
      .watchQuery<Banner>({
        query: FRONT_PAGE_TOP_BANNER,
        variables: {
          locale: this.locale,
          language: this.locale
        }
      })
      .valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((response) => {
        this.topBanner = this.cloneService.deepClone(response.data.banner?.bannerItems);
        if (this.isAuthenticated) {
          this.topBanner = this.topBanner?.filter(item => !item.hideForLogged);
        }
        else {
          this.topBanner = this.topBanner?.filter(item => !item.hideForNotLogged);
        }
        if (!isPlatformServer(this.platformId)) {
          this.isServer = false;
          this.topBanner.forEach(element => {

            element.buttonLink = this.translationConfig.getTranslation(element.buttonLink);
          });
        }
        else {
          this.isServer = true;
        }
        this.cd.detectChanges();
      });
  }

  ngOnInit(): void {
    this.loginStatusService.getLoginPartialStatus().pipe(takeUntil(this.unsubscribe),
    ).subscribe(resp => {
      this.isAuthenticated = resp;
      if (this.isAuthenticated) {
        this.displayBanner();
      }
    });
    this.displayBanner();
  }
  onIndexChange($event) {
    if (!this.isServer) {
      const welcomeVideo = document.getElementById(`welcome-${$event.realIndex}`) as HTMLVideoElement;
      if (welcomeVideo) {
        welcomeVideo.play();
      }
    }

    // const originalSlideCount = $event.slides.length - 2;
    // if ($event.activeIndex === originalSlideCount + 1) {
    //   $event.slideTo(1, 0, false);
    // }
    // if ($event.realIndex != 0) {
    //   this.player.pause();
    // }
    // else if (!!this.player) {
    //   this.player.play();
    // }
  }

  checkForVideoInNextSlide($event): void {
      const swiper = $event[0];
      // Check next slide for video
      const nextSlideIndex = (swiper.realIndex + 2) % swiper.slides.length;
      const nextSlide = swiper.slides[nextSlideIndex];
      const hasVideo = nextSlide.querySelector('video') !== null;

      if (hasVideo && !this.isFirstVideoShown) {
        swiper.autoplay.stop();
        setTimeout(() => {
          swiper.slideTo(nextSlideIndex);
          swiper.autoplay.start();
          this.isFirstVideoShown = true;
        }, TopBannerComponent.DELAY_BEFORE_FIRST_VIDEO);
      } else {
        swiper.autoplay.start();
      }
  }

  openLink(item: BannerItem) {
    this.router.navigate((this.isAuthenticated ? item.loggedButtonLink : item.buttonLink).split('/').map(item => this.translationConfig.getTranslation(item)), { relativeTo: this.route });
  }

  trackByBannerItems(index: number, item: BannerItem): string {
    return item.name;
  }
  getVideoLink(url: string) {
    const now = new Date();
    const time = now.getTime();
    return url + '?t=' + time;
  }
}
