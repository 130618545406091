import { Apollo, gql } from 'apollo-angular';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { combineLatest, Observable } from 'rxjs';
import { BaseFirebaseService } from '../http/base-firebase.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireAuth } from '@angular/fire/compat/auth';

import {
  CMSMissionAchievement,
  CMSMissionDescription,
  Mission,
  MissionAchievement,
  MissionDescription,
  MissionPrize,
  MissionPrizeEngineResponse,
} from './missions.models';
import { HOST_NAME } from 'src/app/app-routing.module';
import { map } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import { MISSION_ACHIEVEMENTS } from '../http/base-http.service';

const prizesQuery = gql`query MissionsList($locale: Locale!,$searchRef: ID!){
  mission(locales: [$locale, en],where: {id: $searchRef}) {
    name
    prize{
      name
    }
  }
  }
  `

const pointsQuery = gql`query MissionPoint($locale: Locale!,$searchRef: ID!){
  mission(locales: [$locale, en],where: {id: $searchRef}) {
    name
    tournamentPoints{
      name
      value
      points
      pointsType
    }
  }
  }
  `

const descriptionQuery = gql`query MissionDescription($locale: Locale!,$searchRef: ID!){
  mission(locales: [$locale, en],where: {id: $searchRef}) {
    description{
      html
    }
  }
  }
  `

const achievementQuery = gql`query MissionAchievement($locale: Locale!,$searchRef: ID!){
  mission(locales: [$locale, en],where: {id: $searchRef}) {
    missionsAchievements{
      achievementType
      value
    }
  }
  }
  `


@Injectable({
  providedIn: 'root'
})
export class MissionDetailsService extends BaseFirebaseService {

  constructor(
    http: HttpClient,
    afAuth: AngularFireAuth,
    afs: AngularFirestore,
    private apollo: Apollo,
    @Inject(HOST_NAME) public hostName,
    @Inject(LOCALE_ID) private locale,
    private sanitizer: DomSanitizer
  ) {
    super(http, afAuth, afs, hostName);
  }






  public getMissionPrizes(ref: string): Observable<MissionPrize> {
    return this.apollo
      .watchQuery<{ mission: { prize: MissionPrize } }>({
        query: prizesQuery,
        variables: {
          searchRef: ref,
          locale: this.locale
        }
      })
      .valueChanges.pipe(
        map(item => item.data?.mission?.prize)
      )
  }

  public getMissionAchievements(mission: Mission, cmsId: string, currency: string): Observable<{ isCompleted: boolean, achievements: MissionAchievement[] }> {
    return combineLatest({
      // cmsAchievements: this.apollo.watchQuery<{
      //   mission: {
      //     missionsAchievements: CMSMissionAchievement[]
      //   }
      // }>({
      //   query: achievementQuery,
      //   variables: {
      //     searchRef: cmsId,
      //     locale: this.locale
      //   }
      // })
      //   .valueChanges.pipe(
      //     // tap(item => console.log(item)),
      //     map(item => item.data.mission.missionsAchievements)
      //   ),
      prizeEngine: this.get<MissionPrizeEngineResponse>(`${MISSION_ACHIEVEMENTS}/${mission.ref}`)
    }).pipe(
      map(item => {
        const cmsAchievements = mission.missionsAchievements;
        const prizeEngine = item.prizeEngine;
        const missionAchievements: MissionAchievement[] = [];
        const fixedCurrency = mission.fixedCurrency[currency.toLowerCase()] || 1;
        const currencyFieldNames = ['stake', 'win_sum', 'win_value']
        for (const cmsAchievement of cmsAchievements) {
          const achievementValue = prizeEngine[cmsAchievement.achievementType];
          let valueToAchieve;
          if (currencyFieldNames.includes(cmsAchievement.achievementType)) {
            valueToAchieve = cmsAchievement.value * fixedCurrency
          }
          else {
            valueToAchieve = cmsAchievement.value
          }
          if (achievementValue) {
            missionAchievements.push({
              ...cmsAchievement,
              value: valueToAchieve,
              playerValue: achievementValue,
              isCompleted: valueToAchieve <= achievementValue
            })
          } else {
            missionAchievements.push({
              ...cmsAchievement,
              playerValue: 0,
              isCompleted: false,
              value: valueToAchieve
            })
          }
        }
        return { achievements: missionAchievements, isCompleted: prizeEngine.is_completed };
      })
    )
  }


  public getMissionDescription(ref: string): Observable<MissionDescription> {
    return this.apollo
      .watchQuery<{ mission: CMSMissionDescription }>({
        query: descriptionQuery,
        variables: {
          searchRef: ref,
          locale: this.locale
        }
      })
      .valueChanges.pipe(
        map(item => item.data.mission.description.html),
        map(item => {
          const description = this.sanitizer.bypassSecurityTrustHtml(item)
          return { description } as MissionDescription
        })
      )
  }
}
