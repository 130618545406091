import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { BasePaymentService } from '../../http/base-payment.service';
import { CANCEL_WITHDRAWAL } from '../../http/base-http.service';
import { HOST_NAME } from 'src/app/app-routing.module';

@Injectable({
  providedIn: 'root'
})
export class CancelWithdrawalService extends BasePaymentService {

  constructor(http: HttpClient, cookie: CookieService, @Inject(LOCALE_ID) public locale: string, @Inject(HOST_NAME) public hostName) {
    super(http, cookie, locale,hostName);
  }

  public cancelWithdrawal(cancelWithdrawalForm:UntypedFormGroup): Observable<string>{
    const url = CANCEL_WITHDRAWAL+cancelWithdrawalForm.get("payment_id").value+'/'
    return super.postRequest<string>(url,cancelWithdrawalForm);
  }
}
