<div class="auth-content" [ngClass]="{'desktop': isDesktop, 'mobile': !isDesktop}">
  <!-- <mat-divider></mat-divider> -->

  <div class="login-form">
    <form name="loginForm" [formGroup]="loginForm" (ngSubmit)="onSubmit(loginForm)">
      <div class="auth-body mat-componets">
        <div class="image-box">
          <img src="assets/images/welcome-back-diamond.png">
        </div>

        <mat-form-field appearance="outline">
          <mat-label i18n>E-mail</mat-label>
          <input matInput placeholder="  " type="text" formControlName="login" autocomplete="username" cdkFocusInitial>
          <img class="img-suffix" src="assets/desktop/mail-icon.svg" matSuffix>
          <mat-hint *ngIf="loginForm.get('login').errors && loginForm.get('login').touched" class="error-hint" i18n><img class="img-hint" src="assets/icons/warning_hint_icon_2.svg">Enter a valid emal address</mat-hint>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label i18n>password</mat-label>
          <input matInput placeholder="  " autocomplete="current-password" [type]="hidePassword ? 'password' : 'text'" formControlName="password">
          <img class="img-suffix" src="assets/desktop/{{hidePassword ? 'eye-off' : 'eye-on'}}.svg" matSuffix (click)="hidePassword = !hidePassword">
          <mat-hint *ngIf="isError" class="error-hint"><img class="img-hint" src="assets/icons/warning_hint_icon_2.svg">{{desktopErrorText}}</mat-hint>
        </mat-form-field>

        <div class="lost-password">
          <p (click)="lostPassword()" i18n>Forgot your password?</p>
        </div>

      </div>

      <div class="auth-footer">
        <button class="confirm-btn" [class.spinner]="formSubmited" style="text-transform: uppercase;" round-button size="large" filled="filled" color="primary" type="submit" id="username" autocomplete="username">
          <span *ngIf="!formSubmited" i18n>log in</span><span *ngIf="formSubmited">&nbsp;</span></button>
        <button round-button size="large" filled="filled" color="red" type='button' style="text-transform: uppercase;" (click)="signup()" i18n>open account</button>
      </div>
    </form>
  </div>
</div>