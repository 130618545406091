import { Injectable } from '@angular/core';
import { take } from 'rxjs';
import { LoginStatusService } from '../auth/login/login-status.service';

@Injectable({
  providedIn: 'root'
})
export class TeaserTopMobileService {

  constructor(
    private loginStatusService: LoginStatusService,
  ) { }

  disableActivePromoTeaser() {
    this.loginStatusService.getUserState().pipe(
      take(1),
    ).subscribe((state) => {
      if (state) {
        state.dbUser['activePromotionDisplay'] = { closedByUser: true };
        this.loginStatusService.udpateUserState("dbUser", state.dbUser, true)
      }
    })
  }
}
