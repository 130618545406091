import { DetectDeviceService } from 'src/app/services/utils/detect-device.service';
import { BasePageComponent } from '../../base-page/base-page.component';
import { Component, Input, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformServer } from '@angular/common';

@Component({
  selector: 'app-front-expansion-panel',
  templateUrl: './front-expansion-panel.component.html',
  styleUrls: ['./front-expansion-panel.component.scss']
})
export class FrontExpansionPanelComponent extends BasePageComponent {
  isDesktop: boolean;
  isServer: boolean;
  constructor(
    private device: DetectDeviceService,
    @Inject(PLATFORM_ID) private platformId) {
    super();
  }

  @Input() isOpenFirst: boolean = false;

  ngOnInit(): void {
    this.isServer = isPlatformServer(this.platformId);
    this.isDesktop = this.device.isDesktop();
  }

}
