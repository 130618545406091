<app-desktop-header *ngIf="detectDeviceService.isDesktop()"></app-desktop-header>
<!-- <app-desktop-category-menu [data]="categories" *ngIf="detectDeviceService.isDesktop()"></app-desktop-category-menu> -->
<div class="page-body">
    <div class="content">
        <!-- <div class="category-name-container" fxLayout="row" fxLayoutAlign="start center">
            <h1 fxLayoutAlign="center center" class="category-title">
                <div class="category-icon"><img width="36px" height="33px" src="{{category?.icon?.url}}" (load)="isCategoryImageLoaded = true" [fxShow]="isCategoryImageLoaded"></div>
                {{categoryName}}
            </h1>
        </div> -->
        <app-mobile-page-back-header iconUrl="{{category?.icon?.url}}">{{categoryName}}</app-mobile-page-back-header>
        <app-inner-loader *ngIf="isLoading"></app-inner-loader>
        <ng-container *ngIf="games && !isLoading">
            <div class="game-card-wrapper" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" (scrolled)="onScroll()">
                <ng-container *ngFor="let item of games">
                    <div class="game-card" *ngIf="isLoggedIn || (!item.hideGame && !isLoggedIn)">
                        <app-game-card (clickEvent)="openDialog($event)" [data]="item"></app-game-card>
                    </div>
                </ng-container>
            </div>
        </ng-container>
        <app-front-expansion-panel *ngIf="expansionPanel" [data]="expansionPanel" [isOpenFirst]="true"></app-front-expansion-panel>
        <router-outlet></router-outlet>
    </div>
</div>