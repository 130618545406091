import { Pipe, PipeTransform } from '@angular/core';
import { TranslationConfig } from '../utils/translate-config';

@Pipe({
  name: 'translateUrl'
})
export class TranslateUrlPipe implements PipeTransform {

  constructor(public translationService: TranslationConfig) {

  }

  transform(value: string): string {
    return this.translationService.translateUrlEntry(value);
  }

}
