import { Component } from '@angular/core';
import { NotificationService } from '../../services/notofications/notification.service';
import { Notificationinfo } from 'src/app/services/notofications/notifications.models';
import { BasePageComponent } from '../base-page/base-page.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { NotificationSheetComponent } from './notification-sheet/notification-sheet.component';
import { DetectDeviceService } from 'src/app/services/utils/detect-device.service';


@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent extends BasePageComponent {

  notificationInfo:Notificationinfo;

  constructor(
    private notificationService: NotificationService,
    public notificationSheet: MatBottomSheet,
    public detectDeviceService: DetectDeviceService,
    ) { super() }

  ngOnInit(): void {
    // this.notificationService.fetchNotifications().pipe(takeUntil(this.unsubscribe)).subscribe((resp)=>{
    //   this.notificationInfo = resp
    // })
  }

  openNotificationSheet(): void {
    const bottomSheetRef = this.notificationSheet.open(NotificationSheetComponent, {
      data: this.notificationInfo.notifications,
    });
    // this.notificationService.setNotificationSeen();
  }


}
