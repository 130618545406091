<ng-container *appShellNoRender>
    <div *loggedRender fxLayoutAlign="center center" fxLayoutGap="8px" fxLayout="column" fxfill>
        <div class="balance" fxLayoutAdivgn="center center" fxLayout="row" *ngIf="balanceObs$ | async as balance">
            <div class="balance-icon"><img src="assets/icons/icon-wallet-small.svg" /></div>
            <div class="info">{{balance.balance}}</div>
            <div class="info">{{balance.currency}}</div>
        </div>
        <div class="profile-deposit-button" fxLayout="row" fxLayoutAlign="center center" (click)="deposit()">
            <div class="button-container round-btn filled" fxLayout="row" fxLayoutAlign="start center">
                <div class="label"><strong i18n>deposit</strong></div>
                <div class="add-icon" fxLayoutAlign="center center" fxLayout="row">
                    <img src="assets/icons/icon-add.svg" />
                </div>
            </div>
        </div>
    </div>
</ng-container>