<div #resumegamedialogwrapper *ngIf="!!data && data!= null else notfound" fxLayoutAlign="start center" fxLayout="column" class="resume-game-dialog-wrapper">
    <div class="top-container" fxLayoutAlign="start center" fxLayout="column">
        <div fxLayoutAlign="center center" fxLayout="column" class="title-right-tile">
            <div class="game-info">
                <h1 class="game-title" mat-dialog-title>{{data.name}}</h1>
                <h2 class="game-provider" mat-dialog-title>{{data.provider}}</h2>
            </div>
        </div>
        <div class="thumbnail">
            <img *ngIf="data.thumbnailDesktopSquare else oldimage" src="{{ data.thumbnailDesktopSquare?.url }}" alt="{{ data.name }}" />
            <ng-template #oldimage>
                <img src="{{ data.thumbnail?.url }}" alt="{{ data.name }}" />
            </ng-template>
        </div>
        <div class="play-button"><button class="button" round-button color="warn" filled="empty" (click)="launchGame()" i18n>resuming game</button></div>
        <div fxLayoutAlign="center center" fxLayout="column" class="message">
            <h2>{{resumeGameMsg}}</h2>
        </div>
    </div>
</div>
<ng-template #notfound>
    <app-mobile-not-found></app-mobile-not-found>
</ng-template>
<app-close-dialog-button customClass="white" (closeEvent)="closeDialog()"></app-close-dialog-button>
