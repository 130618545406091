import { Inject, Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { BaseFirebaseService } from '../http/base-firebase.service';
import { BehaviorSubject, EMPTY, Observable } from 'rxjs';
import { LoginStatusService } from '../auth/login/login-status.service';
import { HttpClient } from '@angular/common/http';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { GameItem} from '../../apollo/models/base-models';
import { HOST_NAME } from 'src/app/app-routing.module';
import {map, switchMap, first, filter} from 'rxjs/operators';
import { PrizeUser } from './edit-profile.models';
import {strategy} from "@angular-devkit/architect/src/jobs";
import reuse = strategy.reuse;

const query = gql`query GameSearchList($searchIds: [String!]){
    games(where: {gameid_in: $searchIds}) {
      id
      name,
      gameid,
      systemName,
      tag,
      thumbnail {
        url
      }
      thumbnailDesktopSquare{
        url(transformation: {
          image: { resize: { width: 206, height: 206, fit: clip } }
          document: { output: { format: webp } }
        })
      }
      thumbnailDesktopRectangular{
        url(transformation: {
          image: { resize: { width: 236, height: 177, fit: clip } }
          document: { output: { format: webp } }
        })
      }
      animation{
        url
        fileName
      }
    }
  }
`


@Injectable({
    providedIn: 'root'
})
export class FavouritesService extends BaseFirebaseService {
  private _store = new BehaviorSubject<GameItem[]>([]);
  private _isRefreshed = false;
  get isRefreshed(): boolean {
    return this._isRefreshed;
  }
  constructor(
    http: HttpClient,
    afAuth: AngularFireAuth,
    afs: AngularFirestore,
    private apollo: Apollo,
    private loginStatus: LoginStatusService,
    @Inject(HOST_NAME) public hostName
  ) {
    super(http, afAuth, afs, hostName);
  }
  public getFavouriteGames(): Observable<GameItem[]> {
    return this._store.asObservable();
  }

  public refreshFavouriteGames() {
    return this.loginStatus.getIfUserLogged().pipe(
      switchMap((status) => {
        return this.getDocumentDB<PrizeUser>(`prize-users/${status.username}`).pipe(
          map((resp: PrizeUser) => resp ? resp.favourites : [])
        );
      }),
      switchMap((favourites) => {
        if (!favourites || favourites.length === 0) {
          return new BehaviorSubject<GameItem[]>([]);
        }
        return this.apollo.watchQuery({
          query: query,
          variables: {
            searchIds: favourites
          }
        }).valueChanges.pipe(
          map((resp: any) => {
            if (resp.data && resp.data.games) {
              return resp.data.games.map((item: GameItem) => item);
            }
            return new BehaviorSubject<GameItem[]>([]);
          })
        );
      })
    ).subscribe((resp: GameItem[]) => {
        if (!resp) {
          resp = [];
        }
        this._isRefreshed = true;
        this._store.next(resp);
      });
  }
  public toggleFavouriteGame(gameId: string) {
    return this.loginStatus.getIfUserLogged().pipe(
      first(),
      switchMap((status) => {
        return this.getDocumentDB<PrizeUser>(`prize-users/${status.username}`).pipe(
          filter(resp => !!resp),
          first(),
          map((resp: PrizeUser) => {
            if (!resp.favourites) {
              resp.favourites = [];
            }
            const isFavourite = resp.favourites.includes(gameId);
            if (isFavourite) {
              resp.favourites = resp.favourites.filter((item) => item !== gameId);
            } else {
              resp.favourites.push(gameId);
            }
            this.setDocument<{ favourites: string[] }>({ favourites: [...resp.favourites] }, `prize-users/${status.username}`, true);
            return resp.favourites;
          })
        );
      })
    ).subscribe((resp: any) => {
      this._store.next(resp);
    });
  }

}
