import { BasePageComponent } from '../base-page/base-page.component';
import { MatDialog } from '@angular/material/dialog';
import { FULL_DIALOG_CONFIG } from '../dialog/dialog.config';
import { GameDialogComponent } from '../dialog/game-dialog/game-dialog.component';
import { Component } from '@angular/core';
import { SelectedGameData } from './game-card/game-card.component';

@Component({
  template: '',
})
export class BaseGamePage extends BasePageComponent {

  constructor(public dialog: MatDialog) { super() }

  openDialog(game: SelectedGameData): void {
    const dialogRef = this.dialog.open(GameDialogComponent, {
      ...FULL_DIALOG_CONFIG,
      panelClass: "game-dialog",
      data: game
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }
}