import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LoginStatusService } from '../login/login-status.service';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserFullInfo } from '../login/login.models';
import { BaseUsermgrService } from '../../http/base-usermgr.service';
import { USER_FULL_DETAILS } from '../../http/base-http.service';
import { share, switchMap } from 'rxjs/operators';
import { HOST_NAME } from 'src/app/app-routing.module';

@Injectable({
  providedIn: 'root'
})
export class UserFullInfoService extends BaseUsermgrService {

  constructor(http: HttpClient, cookie: CookieService, private loginStatusService: LoginStatusService, @Inject(LOCALE_ID) public locale: string, @Inject(HOST_NAME) public hostName) {
    super(http, cookie, locale, hostName);
  }

  private _fullInfo = new BehaviorSubject<UserFullInfo>(null);

  requestFullInfo(force = false): Observable<UserFullInfo> {
    // console.log("full data info", this._fullInfo.getValue(), force, (!!this._fullInfo.getValue() && !force))
    if (!!this._fullInfo.getValue() && !force) return this._fullInfo.asObservable()
    else return this.get<UserFullInfo>(USER_FULL_DETAILS).pipe(
      share(),
      switchMap(resp => {
        this._fullInfo.next(resp);
        return this._fullInfo.asObservable()
      })
    );
  }
}
