import { SafeHtml } from '@angular/platform-browser';

export type PaymentList = PaymentMethod[]

export type PriceValues = {
    "value": number,
    "label": string
}[];

export type PaymentMethod = {
    "id": number | string,
    "name": string,
    "provider"?: string,
    "service"?: string,
    "limits"?: {
        "maximum": number,
        "minimum": number,
        "currency_name"?: string
    },
    "discriminate_withdraw_accept"?: boolean,
    "amounts_select"?: PriceValues,
    "sort_order"?: number,
    "visible_on"?: string[],
    "last_used"?: string,
    "no_form"?: boolean
    require_currency_exchange?: boolean
    deposit_no_amount?: boolean
    disable_decimal_for_currencies?: string[]
    "thumbnail"?: {
        url: string
    }
    "description"?: SafeHtml,
    openNewTab?: boolean,
    paymentMethodProvider?: string,
    paymentIqToken?: string,
    paymentIqAdditionalFields?: {
        name: string,
        type: string,
        selections: {
            name: string,
            value: string
        }[],
        value?: string
    }[],
    paymentIqAccounts?: {
        name: string,
        accountId: string
    }[]
    addPaymentIqHostedFields?: boolean
    allowAddNewAccount?: boolean
}

export type WithdrawalMethod = {
    "pk": number,
    "name": string,
    "fee": { "value": number, "currency": string, "operator_fee?": number },
    "limits": { "maximum": number, "minimum": number, "currency_name": string },
    "visible_on": string[],
    "sort_order?": number,
    "tax?": number,
    "last_used?": string,
    "thumbnail"?: {
        url: string
    }
    "displayName"?: string,
    paymentMethodProvider?: string,
    paymentIqToken?: string,
    description?: SafeHtml,
    paymentIqAdditionalFields?: {
        name: string,
        type: string,
        selections: {
            name: string,
            value: string
        }[],
        value?: string
    }[],
    "provider"?: string,
    "service"?: string,
    paymentIqAccounts?: {
        name: string,
        accountId: string
    }[]
    allowAddNewAccount?: boolean
}

export type PaymentMethodCMS = {
    "name": string,
    "externalId": number,
    externalNameId?: string
    externalServiceId?: string
    "thumbnail": {
        url: string
    }
    "thumbnailNew": {
        url: string
    }
    displayName: string,
    openNewTab: boolean,
    description: {
        html: string
    }
    paymentMethodProvider: string,
    paymentIqToken?: string,
    paymentIqAdditionalFields?: {
        name: string,
        type: string,
        selections: {
            name: string,
            value: string
        }[],
        value?: string
    }[],
    addPaymentIqHostedFields?: boolean,
    allowAddNewAccount?: boolean
}
export type PyamentMethods = {
    name?: string
    paymentMethods: [PaymentMethodCMS]
}
export type WithdrawalMethods = { withdrawalMethods: [PaymentMethodCMS] }

export interface PaymentsCountry {
    paymentsCountry: PyamentMethods[]
}

export enum PAYMENT_IQ_STATUS_CODES {
    WAITING_APPROVAL = 'WAITING_APPROVAL',
    WAITING_INPUT = 'WAITING_INPUT',
    FAILED = 'FAILED'
}
export interface PaymentIqResponse {
    txState: PAYMENT_IQ_STATUS_CODES
    redirectOutput: {
        container: string
        height: number
        html: string
        method: string
        parameters: {
            [key: string]: string
        }
        sizeUnit?: number
        url: string
        width: number
        statusCode: PAYMENT_IQ_STATUS_CODES
    }
    errors?: {
        msg?: string
    }[]
    msg?: string
}
export interface PaymentIqWIthdrawalResponse {
    txState: PAYMENT_IQ_STATUS_CODES
    success: boolean
    errors?: any[]
}

export interface ExternalPaymentResponse {
    isError: boolean
    errorMsg?: string
    response?: PaymentIqResponse
}
