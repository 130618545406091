import { Component, OnInit } from '@angular/core';
import { GameCategoryDialogComponent } from '../game-category-dialog/game-category-dialog.component';
import { SwiperOptions } from 'swiper';
import { GameCategory } from 'src/app/apollo/models/base-models';

@Component({
  selector: 'app-game-category-swiper',
  templateUrl: './game-category-swiper.component.html',
  styleUrls: ['./game-category-swiper.component.scss']
})
export class GameCategorySwiperComponent extends GameCategoryDialogComponent {

  mobileConfig: SwiperOptions = {
    slidesPerView: 3,
    spaceBetween: 8,
    pagination: { clickable: true },
    navigation: true,
    breakpoints: {
      300: {
        slidesPerView: 3,
        width: 252
      },
      350: {
        slidesPerView: 4,
        width: 352
      },
      400: {
        slidesPerView: 5,
        width: 448
      },
      500: {
        slidesPerView: 6,
        width: 545
      },
    }
  };
}
