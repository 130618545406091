import { Inject, Injectable, signal, NgZone, OnDestroy, PLATFORM_ID } from '@angular/core';
import { Observable, Subject, take, timer } from 'rxjs';
import { BaseFirebaseService } from '../http/base-firebase.service';
import { HttpClient } from '@angular/common/http';
import { POPULAR_GAMES } from '../http/base-http.service';
import { HOST_NAME } from 'src/app/app-routing.module';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { distinctUntilChanged, switchMap, takeUntil, tap } from "rxjs/operators";
import { MostPlayedGameStatistic } from './profile.models';
import { environment } from 'src/environments/environment';
import { isPlatformServer } from '@angular/common';

@Injectable({
  providedIn: 'any'
})
export class PopularGamesService extends BaseFirebaseService implements OnDestroy {

  wallet: Observable<any>;
  constructor(
    http: HttpClient,
    afAuth: AngularFireAuth,
    afs: AngularFirestore,
    protected _ngZone: NgZone,
    @Inject(PLATFORM_ID) public platformId,
    @Inject(HOST_NAME) public hostName
  ) {
    super(http, afAuth, afs, hostName);
    if (!isPlatformServer(this.platformId)) {
      this._ngZone.runOutsideAngular(() => {
        timer(0, environment.checkPopularGamesUsersInterval).pipe(
          switchMap(async () => this.fetchPopularGames()),
          takeUntil(this.unsubscribe),
        ).subscribe(() => { })
      })
    }
  }

  protected unsubscribe: Subject<void> = new Subject();
  private _popularGames = signal<MostPlayedGameStatistic[]>([]);
  popularGames = this._popularGames.asReadonly();

  public fetchPopularGames(): void {
    this.get<MostPlayedGameStatistic[]>(POPULAR_GAMES).pipe(
      take(1),
      distinctUntilChanged(),
      tap((resp) => {
        if (resp) {
          this._popularGames.set(resp);
        }
      }),
      takeUntil(this.unsubscribe)
    ).subscribe();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}

