import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { BehaviorSubject, Subject, combineLatest, from, of } from 'rxjs';
import { HOST_NAME } from 'src/app/app-routing.module';
import { BaseFirebaseService } from '../../http/base-firebase.service';
import { LastPlayedService } from '../../profile/last-played.service';
import { LoginStatusService } from '../login/login-status.service';
import { FirestoreUserData } from './firebase-auth.models';
import { SET_FIRST_LOGIN, SET_NICKNAME, SET_RANDOM_NICKNAME } from '../../http/base-http.service';
import { catchError, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { AvatarService } from '../../profile/avatar.service';
import { DepositStateService } from '../../profile/deposit-state.service';
import { FIRST_DEPOSIT_BONUS_WITH_DATE } from 'src/app/components/teaser-top-mobile/teaser-top-mobile.component';
import { FavouritesService } from '../../profile/favourites.service';

@Injectable({
  providedIn: 'root'
})
export class FirebaseAuthService extends BaseFirebaseService {

  private userData = new BehaviorSubject<FirestoreUserData>(null);
  private unsubscribe: Subject<void> = new Subject();
  constructor(
    afAuth: AngularFireAuth,
    http: HttpClient,
    afs: AngularFirestore,
    private lastPlayedService: LastPlayedService,
    private favouriteService: FavouritesService,
    private loginStatusService: LoginStatusService,
    private avatarService: AvatarService,
    private depositStateService: DepositStateService,
    @Inject(HOST_NAME) public hostName
  ) {
    super(http, afAuth, afs, hostName);

    // this.loginStatusService.getLoginStatus().pipe(
    //   filter(status => status.isLogged === LoggedStatus.logged),
    //   take(1),
    //   switchMap(() => {
    //     return this.afAuth.authState
    //   })
    // ).subscribe(user => {
    //   if (user) {
    //     this._getUserData(user.uid);
    //     this.lastPlayedService.refreshLastPlayed();

    //   } else {
    //     this.loginStatusService.updateUserInfo({
    //       isLogged: LoggedStatus.notLogged,
    //     });
    //   }
    // })
  }

  public signWithToken(token) {
    return from(this.afAuth.signInWithCustomToken(token)).pipe(

    )
  }

  public getAuthState() {
    return this.afAuth.authState;
  }

  public updateUserDatabase(username) {
    return !!username ? this.afAuth.authState.pipe(
      map(resp => resp.uid),
      switchMap(username => {
        return combineLatest({
          pirzeUserState: this.getDocumentDB<FirestoreUserData>(`prize-users/${username}`),
          firstDepositBonus: this.getDocumentDB<FirestoreUserData>(`backend-data-users/${username}/bonuses/${FIRST_DEPOSIT_BONUS_WITH_DATE}`),
        }).pipe(
          tap(doc => {
            this._check_data_completeness(doc.pirzeUserState)
          }),
          tap(doc => this._updateUserData(doc)),
          catchError(err => { console.log(err); return of(err) }),
        )
      })
      // switchMap(username => {
      //   return this.getDocumentDB<FirestoreUserData>(`prize-users/${username}`).pipe(
      //     tap(doc => this._check_data_completeness(doc)),
      //     tap(doc => this._updateUserData(doc)),
      //     catchError(err => { console.log(err); return of(err) }),
      //   )
      // })
    ) : of(null)
  }

  private _check_data_completeness(userData: FirestoreUserData) {
    let sequenceObservable = of(1).pipe(
      switchMap(() => {
        if (!userData) {
          return this.setFirstLoginDate()
        } else {
          return of(null)
        }
      }
      ),
      switchMap(() => {
        if (!userData || !userData.nickname) {
          return this.setRandomNickname()
        } else {
          return of(null)
        }
      }
      ),
      // switchMap(() => {
      //   if (!userData || !userData.avatar) {
      //     return this.avatarService.setRandomAvatar()
      //   } else {
      //     return of(null)
      //   }
      // }
      // )
    );
    // if (!userData) {
    //   // console.log("setting nickname");
    //   sequenceObservable.pipe(
    //     switchMap(() => this.setFirstLoginDate())
    //   )
    // }
    // if (!userData || !userData.nickname) {
    //   // console.log("setting nickname");
    //   sequenceObservable.pipe(
    //     switchMap(() => this.setRandomNickname())
    //   )

    // }
    // if (!userData || !userData.avatar) {
    //   // console.log("setting avatar");
    //   sequenceObservable.pipe(
    //     switchMap(() => this.avatarService.setRandomAvatar())
    //   )
    // }
    sequenceObservable.pipe(takeUntil(this.unsubscribe)).subscribe((resp) => {
      console.log("data update finished")
    })
  }

  private _updateUserData(userData) {
    const flattenUserData = { ...userData.pirzeUserState, ...{ firstDepositBonus: userData.firstDepositBonus } };
    this.userData.next(flattenUserData);
    this.loginStatusService.udpateUserState("dbUser", flattenUserData)
    this.lastPlayedService.refreshLastPlayed();
    this.favouriteService.refreshFavouriteGames();
    this.depositStateService.refreshDepositState();
  }

  private setRandomNickname() {
    return this.post<{}, string>(SET_RANDOM_NICKNAME, {}).pipe(take(1))
  }

  public setFirstLoginDate() {
    return this.get<string>(SET_FIRST_LOGIN).pipe(take(1))
  }

  public setNickname(nickname?: string) {
    return this.post<{ nickname?: string }, string>(SET_NICKNAME, { nickname: nickname }).pipe(take(1))
  }

  public getUserData() {
    return this.userData.asObservable();
  }

  public sginOut(): Promise<void> {
    return this.afAuth.signOut();
  }


}
