<div class="category-header" i18n>select your avatar</div>
<mat-grid-list cols="3" gutterSize="20px" rowHeight="139px" appResponsiveColumns>
  <mat-grid-tile class="game-tile" *ngFor="let item of avatars ">
    <div class="card" (click)="selectAvatar(item)">
      <div fxLayoutAlign="center center">
        <img src="{{item.thumbnail.url}}" />
      </div>
    </div>
  </mat-grid-tile>
</mat-grid-list>
<app-close-dialog-button (closeEvent)="closeDialog()"></app-close-dialog-button>