import { environment as common } from './environment.prod';

export const environment = {
  ...common,
  // production: true,
  // apiUrl: 'https://api-acnt.lemon.casino',
  // walletUrl: 'https://api-wlt.lemon.casino',
  // paymentUrl: 'https://api-pay.lemon.casino',
  // uiBackend: 'https://prizeengine2.lemon.casino',
  // fallbackLanguage: "en",
  // baseCurrency: "EUR",
  // enableTournamentsDisplay: true,
  // cmsDraftToken: "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6ImdjbXMtbWFpbi1wcm9kdWN0aW9uIn0.eyJ2ZXJzaW9uIjozLCJpYXQiOjE2NjgxNTcyODEsImF1ZCI6WyJodHRwczovL2FwaS1ldS1jZW50cmFsLTEtbGVtb24tY2FzaW5vLmh5Z3JhcGguY29tL3YyL2NsYWM5cnJnbjR0YjQwMXVtYTQ3ejJuOW4vbWFzdGVyIiwibWFuYWdlbWVudC1uZXh0LmdyYXBoY21zLmNvbSJdLCJpc3MiOiJodHRwczovL21hbmFnZW1lbnQuZ3JhcGhjbXMuY29tLyIsInN1YiI6ImZhNDA0NmZmLTJkZDctNDA0Yi05MDk0LTZhN2JhNDBiOWUyZCIsImp0aSI6ImNrbmlyc2lvcTJpY2IwMXhuYTl4YzFqbjYifQ.Y8rCNSyFBZjXGqhRml9934h38N-bzQ8JKHmMEIqdI3qaDPyhk0aTTcg5PDIz8ngOMf3nP7pIqv7af5Qja897A_tm6kR9enT8A_Rf11T8p3v50_ZFTStD7mkjZbHlPBr-eV-02to3POLXKkp9hG7-A7Hh6-Lb492xh171TIIISTNMgsT5k98PW_mwSo_I1_Z0pF1jBXiScG-pbKWMn50pz7_Blmopi0pRm1Ar0BjyAc9kw_ZZmv6Xiks-5Z2F0Za80SECNGLnrIFlltMwtzbPCrB06PbrxrPKWoLHjyKdP7H342nxNVCR_H9L4YYLZzbJIx15ncfZ04ZP7WLnBS662l0dyVvW23fh_kX-KEfCYDXRe7EpVSGG0U8ZMzn8p_3VN7V83DK5rMQpvRXVkKrHIJfGPNngO9GLW7br3j5VWx-p6BIKop700zdMXR2FgVFlG1v7crTxgMUpXZViqj0uT7T16ybWPjSHS9URiKgcDctumfTk6ZTM6t-JtliS5lSVJYB6g4bsyc_zv-JvjSKtnSZOfpwo6Qr-fsqb_n7nA_zgNqLIt-A43ZTSwn7VlhDvSCggZf12oCmli-cU3tXTHDVOdTAMtDIhkaqTh6J4btbfYOSyf5L9By-1JZ01aG9eeMJsRHwPNYRZGXtEe8YlaZZcs5OvnXsxZcm4gl0gG2o",
  // firebaseConfig: {
  //   apiKey: "AIzaSyCQ38_g0cC7QMkZkX-B02sTDzrUa-3kn0I",
  //   authDomain: "citron-294513.firebaseapp.com",
  //   databaseURL: "https://citron-294513.firebaseio.com",
  //   projectId: "citron-294513",
  //   storageBucket: "citron-294513.appspot.com",
  //   messagingSenderId: "49223945288",
  //   appId: "1:49223945288:web:10ada036ea46a1009edc7d",
  //   measurementId: "G-L32YGC9Y4D"
  // },
  // checkSessionInterval: 5000
  production: true,
  apiUrl: 'https://api.',
  walletUrl: 'https://api.',
  paymentUrl: 'https://api.',
  uiBackend: 'https://prizeengine2.',
  fallbackLanguage: "en-IN",
  baseCurrency: "EUR",
  enableTournamentsDisplay: true,
  enableServiceWorker: true,
  enablePaymentIQ: true,
  cmsApi: 'https://eu-central-1-lemon-casino.cdn.hygraph.com/content/clac9rrgn4tb401uma47z2n9n/master',
  cmsDraftToken: null,
  firebaseConfig: {
    apiKey: "AIzaSyCQ38_g0cC7QMkZkX-B02sTDzrUa-3kn0I",
    authDomain: "citron-294513.firebaseapp.com",
    databaseURL: "https://citron-294513.firebaseio.com",
    projectId: "citron-294513",
    storageBucket: "citron-294513.appspot.com",
    messagingSenderId: "49223945288",
    appId: "1:49223945288:web:10ada036ea46a1009edc7d",
    measurementId: "G-L32YGC9Y4D"
  },
  paymentIqConfig: {
    url: 'https://api.paymentiq.io/paymentiq/api',
    mid: 100539001,
    sessionIdName: 'citrin_ses',
    userPrefix: 'user'
  },
  checkSessionInterval: 5000,
  checkInboxInterval: 30000,
  minBalanceThresholds: {
    USD: 10,
    EUR: 10,
    GBP: 10,
    PLN: 20,
    HUF: 1500,
    INR: 500,
    CAD: 30,
    AUD: 32,
    NZD: 35,
    ZAR: 150,
    NOK: 100
  },
  defaultDepositValues: {
    PLN: [50, 100, 200, 500, 1000],
    USD: [20, 50, 100, 200, 500],
    EUR: [20, 50, 100, 200, 500],
    GBP: [20, 50, 100, 200, 500],
    HUF: [5000, 10000, 20000, 50000, 100000],
    INR: [500, 1000, 2000, 5000, 10000],
    CAD: [20, 50, 100, 200, 500],
    AUD: [20, 50, 100, 200, 500],
    NZD: [20, 50, 100, 200, 500],
    ZAR: [150, 300, 500, 1000, 2000, 5000],
    NOK: [200, 500, 1000, 2000, 5000]
  }
};
