export const LICENCE_CONFIG_MAP = {
    "default": {
        "logo-xmas": "assets/logo/lemon-logo-xmas-mobile.svg",
        "logo-desktop-xmas": "assets/logo/logo-xmas-desktop.svg",
        "logo": "assets/logo/logo-green.svg",
        "logo-desktop": "assets/logo/logo-desktop.svg",
        // "logo" : "assets/logo/2023/logo-xmas-mobile.svg",
        // "logo-desktop": "assets/logo/2023/logo-xmas-desktop.svg",
        "full-logo": "assets/logo/logo-green.svg",
        "title-substitute": "",
        
        "arrow-right": "assets/icons/icon-arrow-right.svg",
        "arrow-button": "assets/desktop/arrow-button.svg",
        "search-in": "assets/desktop/search-in.svg",
        "search-title": "assets/desktop/menu-search-icon.svg",
        "faq-page":"assets/desktop/faq-page.svg",
        "self-exclusion-page":"assets/desktop/self-exclusion-page.svg",
        "support-page":"assets/desktop/support-page.svg",
        "how-it-works":"assets/desktop/how-it-works.svg",
        "leaderboard":"assets/desktop/leaderboard.svg",
        "prizes":"assets/desktop/prizes.svg",
        "scoring":"assets/desktop/scoring.svg",
        "tournament-title": "assets/desktop/tournament/title-icon.svg",
        "operator-title":"assets/icons/icon-operators.svg",
        "icon-deposit":"assets/icons/icon-deposit.svg",
        "icon-edit-profile":"assets/icons/icon-edit-profile.svg",
        "icon-withdrawal":"assets/icons/icon-withdrawal.svg",
        "signup-banner":"assets/desktop/signup/signup-banner.png",
        "signup-step-one": "assets/desktop/signup/icon-signup-one.svg",
        "signup-step-two": "assets/desktop/signup/icon-signup-two.svg",
        "signup-step-three": "assets/desktop/signup/icon-signup-three.svg",
        "icon-profile" : "icon-profile-header.svg",
        "icon-close": "assets/desktop/ham-close-btn.svg",
        "icon-mission-logo": "assets/desktop/missions/tag-incomplete.svg",
        "icon-mission-completed": "assets/desktop/missions/tag-completed.svg",
        "arrow-icon": "assets/desktop/yellow-arrow.svg"
    },
    "orange_curacao": {
        "logo": "assets/logo/orange-logo.svg",
        "logo-desktop": "assets/logo/orange-logo.svg",
        "full-logo": "assets/logo/orange-logo.svg",
        "title-substitute": "Orange Casino",

    },
    "india": {
        "logo-xmas": "assets/logo/lemon-logo-xmas-mobile.svg",
        "logo-desktop-xmas": "assets/logo/logo-xmas-desktop.svg",
        "logo": "assets/logo/logo-green.svg",
        "logo-desktop": "assets/logo/logo-desktop.svg",
        "full-logo": "assets/logo/logo-green.svg",
        "title-substitute": "",

        "arrow-right": "assets/desktop/india/yellow-arrow.svg",
        "arrow-button": "assets/desktop/india/yellow-arrow.svg",
        "search-in": "assets/india/search-in.svg",
        "search-title": "assets/india/menu-search-icon.svg",
        "faq-page":"assets/india/faq-page.svg",
        "self-exclusion-page":"assets/india/self-exclusion-page.svg",
        "support-page":"assets/india/support-page.svg",
        "how-it-works":"assets/india/how-it-works.svg",
        "leaderboard":"assets/india/leaderboard.svg",
        "prizes":"assets/india/prizes.svg",
        "scoring":"assets/india/scoring.svg",
        "tournament-title":"assets/india/tournament-title-icon.svg",
        "operator-title": "assets/india/icon-operators.svg",
        "icon-deposit":"assets/icons/icon-deposit.svg",
        "icon-edit-profile":"assets/icons/icon-edit-profile.svg",
        "icon-withdrawal":"assets/icons/icon-withdrawal.svg",
        "signup-banner":"assets/india/india-signup.png",
        "signup-step-one":"assets/india/signup-step-one.svg",
        "signup-step-two":"assets/india/signup-step-two.svg",
        "signup-step-three":"assets/india/signup-step-three.svg",
        "icon-profile" : "/india/icon-profile-header.svg",
        "icon-close": "assets/desktop/india/close-icon.svg",
        "bonus-box-xs":"assets/india/bonus-box-xs 1.svg",
        "icon-deposit-black":"assets/india/icon-payments/icon-deposit.svg",
        "icon-withdrawal-black":"assets/india/icon-payments/icon-withdrawal.svg",
        "icon-mail-yellow":"assets/india/bottom-toolbar-icon/mail icon.svg",
        "icon-vector-yellow":"assets/india/bottom-toolbar-icon/Vector.svg",
        "icon-categ-yellow":"assets/india/bottom-toolbar-icon/categ.svg",
        "icon-deposit-yellow":"assets/india/bottom-toolbar-icon/WALLET.svg",
        "icon-live-yellow":"assets/india/bottom-toolbar-icon/live casino.svg",
        "icon-search-yellow":"assets/india/bottom-toolbar-icon/search-icon.svg",
        "icon_swicth_on":"assets/india/swicth_on_icon.svg",
    }
}

