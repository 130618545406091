import { Apollo, gql } from 'apollo-angular';
import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, combineLatest, EMPTY, Observable, of } from 'rxjs';
import { BaseFirebaseService } from '../http/base-firebase.service';
import { LoginStatusService } from '../auth/login/login-status.service';
import { catchError, debounceTime, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { PrizeUser } from './edit-profile.models';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireAuth } from '@angular/fire/compat/auth';

import { GameItem } from '../../apollo/models/base-models';
import { LoggedStatus } from '../auth/login/login.models';
import { HOST_NAME } from 'src/app/app-routing.module';

const query = gql`query GameSearchList($searchIds: [String!]){
    games(where: {gameid_in: $searchIds}) {
      id
      name,
      gameid,
      systemName,
      tag,
      thumbnail {
        url
      }
      thumbnailDesktopSquare{
        url(transformation: {
          image: { resize: { width: 206, height: 206, fit: clip } }
          document: { output: { format: webp } }
        })
      }
      thumbnailDesktopRectangular{
        url(transformation: {
          image: { resize: { width: 236, height: 177, fit: clip } }
          document: { output: { format: webp } }
        })
      }
      animation{
        url
        fileName
      }
    }
  }
`

@Injectable({
    providedIn: 'root'
})
export class LastPlayedService extends BaseFirebaseService {

    constructor(
        http: HttpClient,
        afAuth: AngularFireAuth,
        afs: AngularFirestore,
        private apollo: Apollo,
        private loginStatus: LoginStatusService,
        @Inject(HOST_NAME) public hostName
    ) {
        super(http, afAuth, afs, hostName);
    }

    private _store = new BehaviorSubject<GameItem[]>([]);
    private _isRefreshed = false;
    get isRefreshed(): boolean {
      return this._isRefreshed;
    }
    public getLastPlayed(): Observable<GameItem[]> {
        return this._store.asObservable();
    }

    public refreshLastPlayed() {
        return this.loginStatus.getIfUserLogged().pipe(
            switchMap(
                (status) => {
                    return this.getDocumentDB<PrizeUser>(`prize-users/${status.username}`).pipe(
                        map((resp: PrizeUser) => resp ? resp.lastPlayed: [])
                    )
                }
            ),
            // filter(resp => !!resp),
            switchMap(
                (gameNames: string[]) => {
                    if (gameNames) {
                        return this.apollo
                            .watchQuery<{ games: GameItem[] }>({
                                query: query,
                                variables: {
                                    searchIds: gameNames
                                }
                            })
                            .valueChanges.pipe(
                                map(resp => {
                                    return [...resp.data.games].sort(function (a, b) {
                                        return gameNames.indexOf(a.gameid) - gameNames.indexOf(b.gameid);
                                    });
                                })
                            )
                    }
                    else {
                      this._isRefreshed = true;
                      this._store.next([]);
                      return EMPTY
                    }
                }
            ),
            catchError(error => {
                console.log(error)
                return EMPTY;
            })
        ).subscribe((resp: GameItem[]) => {
            this._isRefreshed = true;
            this._store.next(resp);
        })
    }

    public addLastPlayed(name: string) {
        this.loginStatus.getLoginStatus().pipe(
            filter((status) => status.isLogged === LoggedStatus.logged),
            switchMap(
                (status) => {
                    return combineLatest(this.getDocumentDB<PrizeUser>(`prize-users/${status.username}`).pipe(
                        debounceTime(0),
                        map((resp: PrizeUser) => {
                            if (resp) {
                                return resp.lastPlayed
                            }
                            else {
                                const emptyArray: string[] = [];
                                return emptyArray
                            }
                        })
                    ),
                        of(status)
                    )
                }
            ),
            take(1),
            // filter(([gameNames, status]) => !!gameNames),
            tap(([gameNames, status]) => {
                if (gameNames) gameNames = gameNames.filter(item => item !== name);
                else gameNames = [];
                this.setDocument<{ lastPlayed: string[] }>({ lastPlayed: [name, ...gameNames?.slice(0, 9)] }, `prize-users/${status.username}`, true);
            })
        ).subscribe(([gameNames, LoggedStatus]) => {
            this.refreshLastPlayed();
        })
    }



}
