<mat-toolbar color="primary" class="sticky">
  <mat-toolbar-row class="progress-row" fxLayout="row" fxLayoutAlign="space-between center" (click)="redirectToProfile()">
    <div class="thumbnail">
      <img *ngIf="(gamificationIndex$ | async) as index" src="assets/icons/lemons-levels/icon-lemon-level-{{index}}.svg" alt="icon-gamification" />
      <div #pixiAnimation class="animation"></div>
    </div>
    <div style="min-width: 55%;" fxLayout="column" fxLayoutAlign="start stretch" >
      <div fxLayout="row" fxLayoutAlign="space-between">
        <div fxLayout="row" fxLayoutAlign="satrt start">
          <p class="info-text">Lvl</p>
          <p class="info-level">{{(gamificationIndex$ | async)}}</p>
        </div>
        <div  fxLayout="row" fxLayoutAlign="end start">
          <p class="info-text">Lvl</p>
          <p class="info-level">{{(gamificationIndex$ | async) + 1}}</p>
        </div>
      </div>
      <div  fxLayout="row" fxLayoutAlign="center stretch">
        <ng-container *ngIf="(progressPercentage$ | async) as progress">
          <div class="progress-bar-empty" [style.width.%]="100">
            <div class="progress-bar" [style.width.%]="progress"></div>
          </div>
        </ng-container>
      </div>
    </div>
    <ng-container *ngIf="(progressPercentage$ | async) as progress">
      <p class="progress-text">{{progress}}%</p>
    </ng-container>
  </mat-toolbar-row>
</mat-toolbar>

