import { Component } from '@angular/core';
import { PendingWithdrawalService } from 'src/app/services/payment/withdrawal/pending-withdrawal.service';
import { BasePageComponentWithDialogs } from '../../base-page/base-page.component';
import { MatDialog } from '@angular/material/dialog';
import { take, takeUntil } from 'rxjs/operators';
import { PendingWithdrawalList } from 'src/app/services/payment/withdrawal/withdrawal.models';
import { CancelWithdrawalService } from 'src/app/services/payment/withdrawal/cancel-withdrawal.service';
import { UntypedFormBuilder } from '@angular/forms';
import { ToggleMenuBackService } from 'src/app/services/utils/toggle-menu-back.service';
import { DetectDeviceService } from 'src/app/services/utils/detect-device.service';

const confirmationMsg: string = $localize`:@@pendingWithdrawalConfirmationMsg:Are you sure you want to cancel the withdrawal?`;

@Component({
  selector: 'app-pending-withdrawal-list',
  templateUrl: './pending-withdrawal-list.component.html',
  styleUrls: ['./pending-withdrawal-list.component.scss']
})
export class PendingWithdrawalListComponent extends BasePageComponentWithDialogs {

  constructor(private pendingListService: PendingWithdrawalService,
    private cancelWithdrawalService: CancelWithdrawalService,
    dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    private toggleBackService: ToggleMenuBackService,
    private detectDesktopService: DetectDeviceService,

  ) { super(dialog, detectDesktopService) }

  pendingList: PendingWithdrawalList;

  cancelWithdrawalForm = this.formBuilder.group({
    payment_id: ['', []],
  })

  ngOnInit(): void {
    this.generateList();
    this.toggleBackService.showBack();
    this.isDesktop = this.detectDesktopService.isDesktop();
  }

  generateList() {
    this.pendingListService.getList().pipe(take(1)).subscribe({
      next: (resp) => {
        this.pendingList = resp;
      },
      error: (error) => {
        console.log(error)
        // this.openErrorDialog(error)
      }
    }
    );
  }

  checkConfirmCancel(paymentId: number) {
    this.openConfirmDialog(confirmationMsg, paymentId, this.confirm);
  }

  confirm(data: any) {
    console.log("confirmation data", data)
    this.cancelWithdrawalForm.get('payment_id').setValue(data.id);
    this.cancelWithdrawalService.cancelWithdrawal(this.cancelWithdrawalForm).pipe(takeUntil(this.unsubscribe)).subscribe(
      resp => {
        this.generateList()
      },
      error => {
        this.openErrorDialog(error.error?.detail)
        this.generateList()
      }
    );
  }

}
