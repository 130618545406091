import { GamificationLevelService } from 'src/app/services/profile/gamification-level.service';
import {Component, Inject, LOCALE_ID} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { GameCategory, GameItem } from 'src/app/apollo/models/base-models';
import {depositTrx, gameInfoTrx, myGamesTrx, paymentTrx} from 'src/app/router-translation.labels';
import { ComponentComunicationService, GAME_ACTION_QUIT } from 'src/app/services/communication/component-comunication.service';
import { LastPlayedService } from 'src/app/services/profile/last-played.service';
import { DialogClosePreviousUrl } from 'src/app/services/utils/dialog-close-previouse-url.service';
import { MinimizeSiteService } from 'src/app/services/utils/hide-footer.service';
import { BalanceStatusService } from 'src/app/services/wallet/balance-status.service';
import { BalanceInAppInfo } from 'src/app/services/wallet/wallet.models';
import { TranslationConfig } from 'src/app/utils/translate-config';
import { BasePageComponent } from '../../base-page/base-page.component';
import { FULL_DIALOG_CONFIG } from '../../dialog/dialog.config';
import {Apollo} from 'apollo-angular';
import { GameResponse } from 'src/app/apollo/models/base-models';
import {FRONT_PAGE_GAME_DESCRIPTION} from '../../../apollo/front-page-games/front-page-games';
import {FavouritesService} from '../../../services/profile/favourites.service';
import { combineLatest } from 'rxjs';
import { LicenceService } from 'src/app/services/utils/licence.service';

@Component({
  selector: 'app-game-client-area',
  templateUrl: './game-client-area.component.html',
  styleUrls: ['./game-client-area.component.scss']
})
export class GameClientAreaComponent extends BasePageComponent {
  private gameName: string;
  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: { gameName: string, gameId: string },
    private balanceService: BalanceStatusService,
    private apollo: Apollo,
    public dialogRef: MatDialogRef<GameClientAreaComponent>,
    private communicationService: ComponentComunicationService,
    private favoriteService: FavouritesService,
    private lastPlayedService: LastPlayedService,
    protected translationConfig: TranslationConfig,
    private gamificationLevelService: GamificationLevelService,
    private minimizeSiteService: MinimizeSiteService,
    private router: Router,
    private route: ActivatedRoute,
    protected licenceService: LicenceService,
    @Inject(LOCALE_ID) public locale: string
  ) { super() }

  isVisible = true;
  balanceObs$: Observable<BalanceInAppInfo>;
  games: GameItem[];
  currentGame: GameItem;
  gameCategory: GameCategory;
  categoryName: string = $localize`:@@lastPlayedCategory:last played`;
  readonly gameUrl = '/' + this.translationConfig.getTranslation(gameInfoTrx) + '/' + this.translationConfig.getTranslation(gameInfoTrx) + '/';
  readonly depositUrl = this.translationConfig.getTranslation(paymentTrx) + '/' + this.translationConfig.getTranslation(depositTrx);



  ngOnInit(): void {
    this.gameName = this.dialogData.gameName;
    this.apollo.watchQuery({
      query: FRONT_PAGE_GAME_DESCRIPTION,
      variables: {
        gameName: this.gameName,
        locale: this.locale
      }
    }).valueChanges.subscribe((resp: any) => {
      this.currentGame = resp.data.game;
    });

    this.balanceObs$ = this.balanceService.getBalanceInfoStatus();
    // subscribe favourite games and last played games
    const favourites$ = this.favoriteService.getFavouriteGames();
    const lastPlayed$ = this.lastPlayedService.getLastPlayed();
    combineLatest([favourites$ , lastPlayed$]).pipe(takeUntil(this.unsubscribe)).subscribe(
      ([favourites, lastPlayed]) => {
        const games = [...favourites, ...lastPlayed];
        // remove duplicates from games
        this.games = games.filter((item, index) => {
          return games.findIndex((game) => game.gameid === item.gameid) === index;
        }).slice(0, 6);
        this.gameCategory = {
          id: this.categoryName,
          name: this.categoryName,
          displayName: this.categoryName,
          game: this.games,
          displayType: 'square',
        };
      }
    );
  }

  goToDeposit() {
    this.closeDialog();
    window.open(this.depositUrl + ';gameName=' + this.gameName + ';gameDepositTimestamp=' + new Date().getTime(), '_blank');
  }

  closeDialog(isQuit?: boolean) {
    this.dialogRef.close(isQuit);
    // this.router.navigate(["../../"], { skipLocationChange: true }).then(() => {
    // });
  }

  changeGame(gameId) {

  }
  myGames() {
    this.dialogRef.close(false);
    this.minimizeSiteService.restorePage();
    this.router.navigate(['/', myGamesTrx]);
  }
  quit() {
    this.gamificationLevelService.animateGamificationProgress.set(true);
    this.communicationService.postMessage(GAME_ACTION_QUIT);
    this.dialogRef.close(true);
  }

  protected readonly myGamesTrx = myGamesTrx;
}
