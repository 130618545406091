import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { switchMap, take } from 'rxjs';
import { FRONT_PAGE_GAMES_DESKTOP_QUERY, FRONT_PAGE_TOP_BANNER, FRONT_PAGE_TOP_BANNER_DESKTOP } from 'src/app/apollo/front-page-games/front-page-games';
import { PROMOTION_QUERY } from 'src/app/apollo/info-page/promotion-page';
import {
  Banner,
  CardWidgetPageResponse,
  FrontPageGamesResponse,
  GameCategoryResponse,
  GameItem,
  PageResponse
} from 'src/app/apollo/models/base-models';
import { SEARCH_QUERY } from 'src/app/apollo/search-page';
import { STATIC_PAGE_CONTENT_QUERY } from 'src/app/apollo/static-page';
import { LICENCE } from 'src/app/app-routing.module';
import { GAME_CATEGORY_PAGE_QUERY } from 'src/app/components/games/category-page/category-page.component';
import { InitialDataService } from '../page/initial-data.service';
import { countryFromLocale } from 'src/app/utils/country-from-locale';

@Injectable({
  providedIn: 'root'
})
export class DesktopService {

  constructor(
    private apollo: Apollo,
    private initialDataService: InitialDataService,
    @Inject(LOCALE_ID) public locale: string,
    @Inject(LICENCE) public license: string,
  ) { }

  bannerData() {
    return this.apollo
      .watchQuery<Banner>({
        query: FRONT_PAGE_TOP_BANNER_DESKTOP,
        variables: {
          locale: this.locale,
          language: this.locale
        }
      })
  }

  staticData(category: string, pageName: string) {
    return this.apollo.watchQuery<PageResponse>({
      query: STATIC_PAGE_CONTENT_QUERY,
      variables: {
        pageName: pageName,
        categoryName: category,
        locale: this.locale,
        licenseName: this.license
      }
    })
  }

  staticPromotionData(pageName: string) {
    return this.apollo.watchQuery<CardWidgetPageResponse>({
      query: PROMOTION_QUERY,
      variables: {
        pageName: pageName,
        locale: this.locale,
        userCountry: [countryFromLocale(this.locale)],
      }
    }).valueChanges.pipe(take(1))
  }



  searchGame(search: string) {
    return this.apollo
      .watchQuery<{ games: GameItem[] }>({
        query: SEARCH_QUERY,
        variables: {
          searchString: search
        }
      })
  }

  gameCategory() {
    return this.apollo
      .watchQuery<FrontPageGamesResponse>({
        query: FRONT_PAGE_GAMES_DESKTOP_QUERY,
        variables: {
          locale: this.locale,
          licenceName: this.license,
          countryName: [this.locale]
        }
      })
  }

  gameListCategory(CategoryName) {
    return this.apollo.watchQuery<GameCategoryResponse>({
      query: GAME_CATEGORY_PAGE_QUERY,
      variables: {
        categoryName: CategoryName,
        locale: this.locale,
        skipNumber: null,
        licenseName: this.license
      }
    })
  }
}
