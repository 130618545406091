import { Attribute, Directive, ElementRef, HostListener, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Subject } from 'rxjs';
import { DetectDeviceService } from '../services/utils/detect-device.service';
import { OpenDesktopDrawerService } from '../services/utils/open-desktop-drawer.service';


@Directive({ selector: '[topDrawerRouterLink]' })
export class TopDraweRouterLink implements OnChanges {

    private commands: any[] = [];

    constructor(
        private router: Router, private route: ActivatedRoute,
        public openDrawerService: OpenDesktopDrawerService,
        public detectDeviceService: DetectDeviceService,


        @Attribute('tabindex') tabIndex: string,
        private renderer: Renderer2,
        private el: ElementRef) {
        if (tabIndex == null) {
            renderer.setAttribute(el.nativeElement, 'tabindex', '0');
        }

    }

    ngOnInit() {
    }

    onChanges = new Subject<TopDraweRouterLink>();

    ngOnChanges(changes: SimpleChanges) {
        // This is subscribed to by `RouterLinkActive` so that it knows to update when there are changes
        // to the RouterLinks it's tracking.
        this.onChanges.next(this);
    }

    @Input()
    set topDrawerRouterLink(commands: any[] | string | null | undefined) {
        if (commands != null) {
            this.commands = Array.isArray(commands) ? commands : commands.split('/');
            this.renderer.setAttribute(this.el.nativeElement, 'href', Array.isArray(commands) ? commands.join('/') : commands);

        } else {
            this.commands = [];
        }
    }
    @Input() state?: { [k: string]: any };


    @HostListener('click')
    onClick(): boolean {
        if (this.detectDeviceService.isDesktop()) {
            this.openDrawerService.closeInitial()
            this.openDrawerService.open()
            this.router.navigate(['', { outlets: { topdrawer: this.commands } }], { relativeTo: this.route, skipLocationChange: true });
            window.history.replaceState({}, '', this.commands.join('/'));
        }
        else {
            this.router.navigate(this.commands)
        }

        return false;
    }



}
