import { ChangeDetectionStrategy, ChangeDetectorRef, Component, NgZone } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { timer, of } from 'rxjs';
import { take, switchMap, catchError } from 'rxjs/operators';
import { DetectDeviceService } from 'src/app/services/utils/detect-device.service';
import { BonusListItem, BonusQueueItem, BonusStatus } from 'src/app/services/wallet/wallet.models';
import { BonusQueueService, } from '../../../services/wallet/bonus-queue.service';
import { BasePageComponentWithDialogs } from '../../base-page/base-page.component';
import { Router } from '@angular/router';


const cancelConfirmationMsg: string = $localize`:@@cancelBonusConfirmationMsg:Please note that bonus balance will be removed along with all bonus wins. Do you want to proceed?`;
const activateConfirmationMsg: string = $localize`:@@activateBonusConfirmationMsg:By activating this new bonus, your current bonus and any associated winnings will be lost. Are you sure you want to proceed?`;

@Component({
  selector: 'app-bonus-queue',
  templateUrl: './bonus-queue.component.html',
  styleUrls: ['./bonus-queue.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BonusQueueComponent extends BasePageComponentWithDialogs {

  constructor(
    private bonusQueueService: BonusQueueService,
    dialog: MatDialog,
    private detectDesktopService: DetectDeviceService,
    protected _ngZone: NgZone,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
  ) { super(dialog, detectDesktopService) }

  bonusQueue: BonusQueueItem[];
  bonusList: BonusListItem[];
  activeBonusList: BonusListItem[];
  isDesktop: boolean = this.detectDesktopService.isDesktop();

  count: number = 0;
  public displayedColumns: string[] = ['date', 'amount', 'status', 'valid'];
  public displayedColumnsBonusList: string[] = ['exp-date', 'amount', 'status', 'turnover'];
  public displayedColumnsBonusActive: string[] = ['exp-date', 'amount', 'status', 'turnover', 'wageringReq'];

  ngOnInit(): void {
    this.refreshBonusQueue();
    this.refreshBonusList();
  }

  refreshBonusQueue() {
    this.bonusQueueService.getBonusQueue().pipe(take(1)).subscribe(resp => {
      this.bonusQueue = resp
      this.changeDetectorRef.markForCheck();
    })
  }

  getTournoverPercentage(bonus: BonusListItem) {
    let value = (bonus.turnover_sum / bonus.redemption_amount)
    let finalValue = value * 100.;
    if (finalValue > 99. && finalValue < 100.) finalValue = 99;
    return finalValue.toFixed(2);
  }

  refreshBonusList() {
    this.bonusQueueService.getBonusList().pipe(take(1)).subscribe(resp => {
      this.bonusList = resp.slice(0, 10).filter(item => item.raw_status !== BonusStatus.ACTIVE)
      this.activeBonusList = resp.filter(item => item.raw_status === BonusStatus.ACTIVE)
      this.changeDetectorRef.markForCheck();
    })
  }

  bonusTerms(url: string) {
    this.router.navigate([url]);
  }

  confirmCancelation() {
    this.bonusQueueService.cancelActiveBonus().pipe(take(1)).subscribe(() => {
      this._ngZone.runOutsideAngular(() => {
        timer(0, 2000).pipe(take(5)).subscribe(resp => {
          this.refreshBonusQueue();
          this.refreshBonusList();
        })
      });
    })
  }

  cancelBonus() {
    this.openConfirmDialog(cancelConfirmationMsg, {}, () => this.confirm);
  }

  activateBonus(bonusId: number) {
    this.openConfirmDialog(activateConfirmationMsg, bonusId, () => this.confirm)
  }

  confirm(bonusId: number): void {
    if (bonusId > 0) {
      this.confirmActivation(bonusId);
    } else {
      this.confirmCancelation();
    }
  }

  confirmActivation(bonusId: number) {
    this.bonusQueueService.activateBonus(bonusId).pipe(
      take(1),
      switchMap(() => this.bonusQueueService.cancelActiveBonus()),
      switchMap(() => timer(0, 2000).pipe(take(5))),
      catchError((error: any) => {
        this.openErrorDialog(error.message);
        return of(error);
      }),
    ).subscribe(() => {
      this._ngZone.runOutsideAngular(() => {
        this.refreshBonusQueue();
        this.refreshBonusList();
      });
    }, error => {
      this.openErrorDialog(error.message);
    });
  }
}
