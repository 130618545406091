import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { OpenDesktopDrawerService } from './services/utils/open-desktop-drawer.service';

@Injectable()
export class DrawerGuard implements CanActivate {
  constructor(
    private router: Router,
    public openDrawerService: OpenDesktopDrawerService,


  ) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    this.openDrawerService.openInitial();
    return true
  }


}
