import { Component, OnInit, Input } from '@angular/core';
import {FavouritesService} from '../../../services/profile/favourites.service';

@Component({
  selector: 'app-game-favourites-toggle',
  templateUrl: './game-favourites-toggle.component.html',
  styleUrls: ['./game-favourites-toggle.component.scss']
})

export class GameFavouritesToggleComponent implements OnInit {
  favouriteGames: string[] = [];
  @Input() gameId: string;
  constructor(private favoriteService: FavouritesService) { }

  ngOnInit(): void {
    this.favoriteService.getFavouriteGames().pipe().subscribe(  // takeUntil(this.unsubscribe) in pipe had been removed
      resp => {
        this.favouriteGames = resp.map((item) => item.gameid);
      }
    );
  }
  toggleFavouriteGame() {
    this.favoriteService.toggleFavouriteGame(this.gameId);
  }
}
