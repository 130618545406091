import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { BasePageComponent } from 'src/app/components/base-page/base-page.component';
import { BonusLotteryService } from 'src/app/services/profile/bonuslottery.service';
import { UserBonusLottery } from 'src/app/services/profile/profile.models';
import { DetectDeviceService } from 'src/app/services/utils/detect-device.service';
import { LicenceService } from 'src/app/services/utils/licence.service';

@Component({
  selector: 'app-lottery-label',
  templateUrl: './lottery-label.component.html',
  styleUrls: ['./lottery-label.component.scss']
})
export class LotteryLabelComponent extends BasePageComponent {

  constructor(
    private bonusLotteryService: BonusLotteryService,
    private router: Router,
    private detectDesktopService: DetectDeviceService,
    protected licenceService: LicenceService,

  ) { super() }

  resp: UserBonusLottery;
  isClaimed: boolean;

  ngOnInit(): void {
    this.bonusLotteryService.getBonusLottery().pipe(
      takeUntil(this.unsubscribe)
    ).subscribe(resp => {
      this.resp = resp;
    })
    this.isDesktop = this.detectDesktopService.isDesktop();
  }

  expand() {
    this.isClaimed = true;
  }

  openLotteryWheel() {
    this.router.navigate(['', 'client-area', 'lottery-wheel'], { skipLocationChange: true })
  }

}
