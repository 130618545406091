import { Component, Input, NgZone } from '@angular/core';
import { BalanceService } from 'src/app/services/wallet/balance.service';
import { switchMap, takeUntil } from 'rxjs/operators';
import { BasePageComponent } from '../../base-page/base-page.component';
import { Subscription, timer } from 'rxjs';
import { BalanceInAppInfo } from 'src/app/services/wallet/wallet.models';
import { depositTrx, paymentTrx } from 'src/app/router-translation.labels';
import { environment } from 'src/environments/environment';
import { DetectDeviceService } from 'src/app/services/utils/detect-device.service';
import { TranslationConfig } from 'src/app/utils/translate-config';
import { LoginStatusService } from 'src/app/services/auth/login/login-status.service';
import { BalanceStatusService } from 'src/app/services/wallet/balance-status.service';

@Component({
  selector: 'app-balance-user',
  templateUrl: './balance-user.component.html',
  styleUrls: ['./balance-user.component.scss']
})
export class BalanceUserComponent extends BasePageComponent {

  paymentUrl: string[] = [this.translationConfig.getTranslation(paymentTrx), this.translationConfig.getTranslation(depositTrx)];


  balanceInfo: BalanceInAppInfo;
  constructor(private balanceService: BalanceService,
    public detectDeviceService: DetectDeviceService,
    protected translationConfig: TranslationConfig,
    private loginStatusService: LoginStatusService,
    private balanceStatusInfoService: BalanceStatusService,
    protected _ngZone: NgZone,
  ) { super() }

  @Input() isGetFromStore: boolean = false;
  timerSubscription$: Subscription;

  ngOnInit(): void {
    if (!this.isGetFromStore) {
      this.timerSubscription$ = timer(0, environment.checkSessionInterval).pipe(
        switchMap(() => this.balanceService.requestBalance().pipe(takeUntil(this.unsubscribe))),
        takeUntil(this.unsubscribe),
      ).subscribe((balanceResp) => {
        this.balanceInfo = balanceResp;
        // this.state = loginStatus.state;
      })
    } else {
      this.balanceStatusInfoService.getBalanceInfoStatus().pipe(takeUntil(this.unsubscribe)).subscribe((balanceInfo) => {
        this.balanceInfo = balanceInfo;
      })
    }


  }

  override ngOnDestroy(): void {
    this.timerSubscription$?.unsubscribe();
    super.ngOnDestroy();
  }

}
