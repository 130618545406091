import { Inject, Injectable, signal, NgZone, OnDestroy } from '@angular/core';
import { Observable, Subject, take} from 'rxjs';
import { LoginStatusService } from '../auth/login/login-status.service';
import { TopGames } from './profile.models';
import { BaseFirebaseService } from '../http/base-firebase.service';
import { HttpClient } from '@angular/common/http';
import { TOP_USER_GAMES } from '../http/base-http.service';
import { HOST_NAME } from 'src/app/app-routing.module';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { distinctUntilChanged, switchMap, takeUntil, tap } from "rxjs/operators";

@Injectable({
  providedIn: 'any'
})
export class TopGamesService extends BaseFirebaseService implements OnDestroy {

  wallet: Observable<any>;
  constructor(
    http: HttpClient,
    afAuth: AngularFireAuth,
    afs: AngularFirestore,
    private loginStatusService: LoginStatusService,
    protected _ngZone: NgZone,
    @Inject(HOST_NAME) public hostName
  ) {
    super(http, afAuth, afs, hostName);
    this.fetchTopGames();
  }

  protected unsubscribe: Subject<void> = new Subject();
  private _topGames = signal<TopGames>(
    { maxWin: [], totalRounds: [] }
  );
  topGames = this._topGames.asReadonly();

  // private store = new BehaviorSubject<TopGames>(
  //   {maxWin: [], totalRounds: []}
  // );

  // public getStore() {
  //   return this._topGames();
  // }

  public fetchTopGames(): void {
    this.loginStatusService.getIfUserLogged().pipe(
      take(1),
      switchMap((user) => {
        if (user.isLogged) {
          return this.get<TopGames>(TOP_USER_GAMES).pipe(
            distinctUntilChanged()
          );
        }
      }),
      tap((resp) => {
        if (resp) {
          this._topGames.mutate((state) => {
            state.maxWin = resp.maxWin;
            state.totalRounds = resp.totalRounds;
          });
        }
      }),
      takeUntil(this.unsubscribe)
    ).subscribe();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}

