import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileInfoComponent } from '../../components/profile/profile-info/profile-info.component';
import { SharedMaterialModule } from '../shared-material/shared-material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DynamicCategoryComponent } from 'src/app/components/front-page/dynamic-category/dynamic-category.component';
import { FrontPageGamesComponent } from 'src/app/components/front-page/front-page-games/front-page-games.component';
import { GameCardComponent } from '../../components/games/game-card/game-card.component';
import { RouterModule } from '@angular/router';
import { ResponsiveColumnsDirective } from 'src/app/directives/responsive-columns.directive';
import { BasePageComponentWithDialogs } from 'src/app/components/base-page/base-page.component';
import { CloseDialogButtonComponent } from 'src/app/components/dialog/close-dialog-button/close-dialog-button.component';
import { TopDraweRouterLink } from 'src/app/directives/top-drawer-router-link.directive';
import { UserNotificationsComponent } from 'src/app/components/notifications/user-notifications/user-notifications.component';
import { TournamentInfoBoxComponent } from 'src/app/components/tournaments/tournament-info-box/tournament-info-box.component';
import { LoggedHideDirective } from 'src/app/directives/logged-hide';
import { LoggedRenderDirective } from 'src/app/directives/logged-render';
import { EditNicknameComponent } from 'src/app/components/profile/edit-nickname/edit-nickname.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TopBannerComponent } from 'src/app/components/top-banner/top-banner.component';
import { MainAuthComponent } from 'src/app/components/auth/main-auth/main-auth.component';
import { BalanceUserComponent } from 'src/app/components/auth/logged-user/balance-user.component';
import { UserProfileComponent } from 'src/app/components/auth/logged-user-profile/user-profile.component';
import { LoginButtonComponent } from 'src/app/components/auth/login/login-button/login-button.component';
import { LoginDesktopComponent } from 'src/app/components/auth/desktop/login-desktop/login-desktop.component';
import { RoundButton } from 'src/app/directives/round-button';
import { MobilePageBackHeaderComponent } from 'src/app/components/mobile-page-back-header/mobile-page-back-header.component';
import { CommonPageTitleComponent } from 'src/app/components/desktop/common-page-title/common-page-title.component';
import { ProgressBarComponent } from 'src/app/components/utils/progress-bar/progress-bar.component';
import { DesktopGameCardComponent } from 'src/app/components/desktop/game-card/game-card.component';
import { DesktopFrontPageGamesComponent } from 'src/app/components/desktop/front-page-games/front-page-games.component';
import { LoaderInnerComponent } from 'src/app/components/utils/loader-inner/loader-inner.component';
import { SwiperModule } from 'swiper/angular';
import { DesktopHeaderComponent } from '../../components/front-page/desktop-header/desktop-header.component';
import { FrontExpansionPanelComponent } from 'src/app/components/front-page/front-expansion-panel/front-expansion-panel.component';
import { DesktopExpansionPanelComponent } from 'src/app/components/desktop/desktop-expansion-panel/desktop-expansion-panel.component';
import { GameCategoryShortComponent } from 'src/app/components/front-page/game-category-short/game-category-short.component';
import { DesktopGameCategoryShortComponent } from 'src/app/components/desktop/game-category-short/game-category-short.component';
import { LazyLoadDirective } from '../../directives/lazy-load';
import { HeaderPageCompoanentComponent } from 'src/app/components/utils/header-page-compoanent/header-page-compoanent.component';
import { RemoveHtmlPipe } from 'src/app/pipes/remove-html.pipe';
import { SafeHtmlPipe } from 'src/app/pipes/safehtml.pipe';
import { MissionInfoBoxComponent } from 'src/app/components/missions/mission-info-box/mission-info-box.component';
import {
  GameFavouritesToggleComponent
} from 'src/app/components/games/game-favourites-toggle/game-favourites-toggle.component';
import { IconButtonDirective } from 'src/app/directives/icons-button';
import { TrimDirective } from 'src/app/directives/trim.directive';
import { InViewportModule } from 'ng-in-viewport';
import { ReactivationInfoComponent } from 'src/app/components/reactivation/reactivation-info/reactivation-info.component';
import { CpfFormatterDirective } from 'src/app/directives/cpf-formatter.directives';

@NgModule({
  declarations: [
    ProfileInfoComponent,
    DynamicCategoryComponent,
    FrontPageGamesComponent,
    GameCardComponent,
    ResponsiveColumnsDirective,
    BasePageComponentWithDialogs,
    CloseDialogButtonComponent,
    TopDraweRouterLink,
    UserNotificationsComponent,
    TournamentInfoBoxComponent,
    MissionInfoBoxComponent,
    LoggedRenderDirective,
    LoggedHideDirective,
    RoundButton,
    IconButtonDirective,
    CpfFormatterDirective,
    TrimDirective,
    LazyLoadDirective,
    EditNicknameComponent,
    TopBannerComponent,
    MainAuthComponent,
    BalanceUserComponent,
    UserProfileComponent,
    LoginButtonComponent,
    LoginDesktopComponent,
    MobilePageBackHeaderComponent,
    CommonPageTitleComponent,
    ProgressBarComponent,
    DesktopGameCardComponent,
    DesktopFrontPageGamesComponent,
    LoaderInnerComponent,
    DesktopHeaderComponent,
    FrontExpansionPanelComponent,
    DesktopExpansionPanelComponent,
    GameCategoryShortComponent,
    DesktopGameCategoryShortComponent,
    HeaderPageCompoanentComponent,
    ReactivationInfoComponent,
    RemoveHtmlPipe,
    SafeHtmlPipe,
    GameFavouritesToggleComponent
  ],
  imports: [
    CommonModule,
    SharedMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    RouterModule,
    SwiperModule,
    InViewportModule
  ],
  exports: [
    ProfileInfoComponent,
    DynamicCategoryComponent,
    FrontPageGamesComponent,
    GameCardComponent,
    ResponsiveColumnsDirective,
    CloseDialogButtonComponent,
    TopDraweRouterLink,
    UserNotificationsComponent,
    TournamentInfoBoxComponent,
    MissionInfoBoxComponent,
    LoggedRenderDirective,
    LoggedHideDirective,
    RoundButton,
    IconButtonDirective,
    CpfFormatterDirective,
    TrimDirective,
    LazyLoadDirective,
    EditNicknameComponent,
    TopBannerComponent,
    MainAuthComponent,
    BalanceUserComponent,
    UserProfileComponent,
    LoginButtonComponent,
    LoginDesktopComponent,
    MobilePageBackHeaderComponent,
    CommonPageTitleComponent,
    ProgressBarComponent,
    DesktopGameCardComponent,
    DesktopFrontPageGamesComponent,
    LoaderInnerComponent,
    DesktopHeaderComponent,
    FrontExpansionPanelComponent,
    DesktopExpansionPanelComponent,
    GameCategoryShortComponent,
    DesktopGameCategoryShortComponent,
    HeaderPageCompoanentComponent,
    ReactivationInfoComponent,
    RemoveHtmlPipe,
    SafeHtmlPipe,
    GameFavouritesToggleComponent
  ],
  providers: [],
})
export class SharedComponentsModule { }
