import { SafeHtml } from '@angular/platform-browser';
import { GameWithPrividerExclusionTerritories, SEO } from 'src/app/apollo/models/base-models';

export interface BOTournament {
    ref: string
    games: number[]
    name: string
    masterRef: string
    bonusRef: string
    cmsId: string
    startDate: Date
    endDate: Date
    minBet: number
    minBetCurrencies: { [key: string]: number }
    rebuyPriceCurrencies?: { [key: string]: number }
    isPrivate: boolean
    tournamentRebuyAddPoints?: number
}

export interface CMSTournament {
    id: string
    ref: string
    name: string
    licence?: string[]
    streamingChannelName?: string
    games: {
        name
        gameid
        thumbnail: {
            url: string
        }
        gameProvider?: GameWithPrividerExclusionTerritories,
    }[]
    displayBonusName?: string
    tournamentRebuy?: {
        price: number
        maximumRebuys: number
        tournamentRebuyBonusId: number
        rebuyAddPoints: number
    }
    thumbnailMobile?: {
        url: string
    }
    thumbnailDesktop?: {
        url: string
    }

    largeDesktopThumbnail?: {
        url: string
    }
    thumbnailDesktopSquare?: {
        url: string
    }
    thumbnailDesktopRectangular?: {
        url: string
    }
}

export enum TOURNAMENTTYPE {
    ACTIVE,
    UPCOMMING,
    HISTORY
}

export interface PageDisplayTournament {
    startDate: Date
    endDate: Date
    tournamentType: string
    isPrivate: boolean
    minBetCurrencies: { [key: string]: number }
    rebuyPriceCurrencies: { [key: string]: number }
    minBet: number
    bonusRef: string
    ref: string
    tournamentRebuyAddPoints: number
    tournament: CMSTournament
}

export interface TournamentGame {
    name
    gameid
    thumbnail: {
        url: string
    }
    thumbnailDesktopRectangular?: {
        url: string
    }

}

export interface CommonTournament {
    name: string
    startDate: Date
    endDate: Date
    cmsId?: string
    games: TournamentGame[]
    thumbnailMobile?: {
        url: string
    }
    thumbnailDesktop?: {
        url: string
    }
    largeDesktopThumbnail?: {
        url: string
    }
    largeDesktopBanner?: {
        url: string
    }

    isNetworkTournament: boolean
}

export interface Tournament extends CommonTournament {
    isNetworkTournament: false
    ref: string
    streamingChannelName?: string
    isPrivate?: boolean
    games: TournamentGame[]
    tournamentType: TOURNAMENTTYPE
    minBetCurrencies: { [key: string]: number }
    rebuyPriceCurrencies?: { [key: string]: number }
    minBet: number
    displayBonusName?: string
    bonusRef?: string
    tournamentRebuy?: {
        price: number
        maximumRebuys: number
    }
    tournamentMaximumRebuys?: number
    tournamentRebuyPrice?: number
    tournamentRebuyBonusId?: number
    tournamentRebuyAddPoints?: number
    largeDesktopBanner?: {
        url: string
    }
    seo?: SEO
}


export interface NetworkTournament extends CommonTournament {
    isNetworkTournament: boolean
}



export interface PlayerTournamentInfo {
    numberRebuys: number
}

export enum PointsType {
    MULTIPLIER = "multiplier",
    BET = "bet",
    MAX_BET_MULTIPLIER = "max_bet_multiplier"
}

export interface TournamentPoints {
    name: string
    value?: number
    points?: number
    pointsType: PointsType
}

export interface TournamentPrizes {
    name: string
    value: number
    points: number
    pointsType: PointsType
}

export interface CMSTournamentDescription {
    description: {
        html: string
    }
}

export interface TournamentDescription {
    description: SafeHtml
}

export interface TournamentFeed {
    isFinished: boolean
    rank: TournamentRankEntry[]
}

export interface TournamentRankEntry {
    nickname: string;
    tournamentPlayerId: string;
    points: number
}

export interface MappedTournamentRank {
    rank: TournamentRankEntry[]
    myPosition?: number
}

export interface TournamentState {
    isFinished: boolean
}
