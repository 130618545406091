<div class="app-download">
    <div>
        <img (click)="closeInfo()" src="assets/mobile/pwa-close.svg" alt="">
    </div>
    <div class="logo-app">
        <div class="downloaded-app">
            <div>
                <img class="pwa-logo" src="assets/mobile/pwa-lemon-logo.svg" alt="">
            </div>
            <div class="app-name-device">
                <p class="app-head">LEMON CASINO</p>
                <p class="download-mobile">App for Android</p>
            </div>
        </div>
        <button class="app-download-btn" (click)="gotoInstall()">Download</button>
    </div>
</div>